/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useState,
  useContext,
} from 'react';
import { format } from 'date-fns';
import {
  Box, Container, Card, CardHeader, Stack, Table, TableBody, TableCell,
  TableHead, TableRow, Typography, TableContainer, ListItemText, Divider,
  Collapse, ButtonBase, IconButton, Paper,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, isArray } from 'lodash';
import { useTheme, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Unstable_Grid2';
import moment from 'moment';
import LoadingButton from '@mui/lab/LoadingButton';
import FineTuningContext from '../../contexts/FineTuningContext';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import Iconify from '../Iconify';
import { protectedFetch } from '../../utils/network';
import FineTuningDetails from './FineTuningDetails';

const currentFineTuningHeaders = [
  { title: 'Fine Tuning Start Date', colKey: 'timeWhenFineTuningStartedRunning' },
  { title: 'Sinc Filter', colKey: 'doSincFilter' },
  { title: 'Noise Removal', colKey: 'doNoiseRemoval' },
  { title: 'Noise Removal Max Time', colKey: 'noiseRemovalMaxTime' },
  { title: 'Total users', colKey: 'numberOfUsersToRun' },
  { title: 'Min LP Degrees', colKey: 'minLPDegrees' },
  { title: 'Max LP Degrees', colKey: 'maxLPDegrees' },
  { title: 'Min Frame Length', colKey: 'minFrameLength' },
  { title: 'Max Frame Length', colKey: 'maxFrameLength' },
  { title: 'Accuracy', colKey: 'accuracy' },
  { title: 'Phrase', colKey: 'vppText' },
  { title: 'Content Language', colKey: 'contentLanguage' },
  { title: 'Best SIV 1 LP Degrees', colKey: 'bestSiv1LPDegrees' },
  { title: 'Best SIV 1 Frame Length', colKey: 'bestSiv1FrameLength' },
  { title: 'Best SIV 2 LP Degrees', colKey: 'bestSiv2LPDegrees' },
  { title: 'Best SIV 2 Frame Length', colKey: 'bestSiv2FrameLength' },
  { title: 'SIV 1 Threshold', colKey: 'highestValueOfSiv1CurrentSpoofingAttempts' },
  { title: 'SIV 2 Threshold', colKey: 'highestValueOfSiv2CurrentSpoofingAttempts' },
  { title: 'SIV 3 Threshold', colKey: 'highestValueOfSiv3CurrentSpoofingAttempts' },
  { title: 'SIV 4 Threshold', colKey: 'highestValueOfSiv4CurrentSpoofingAttempts' },
  { title: 'Logs', colKey: 'logs' },
  { title: 'Change To These Parameters', colKey: 'Actions' },
];

function updateButtonIcon(state) {
  if (state === 'fail') {
    return <Iconify width={16} icon="carbon:error-filled" />;
  } if (state === 'success') {
    return <Iconify width={16} icon="carbon:checkmark-filled" />;
  }
  return <Iconify width={16} icon="carbon:stop-outline-filled" />;
}

function Row(props) {
  const { row } = props;
  const [updateButtonState, setUpdateButtonState] = useState('default');
  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);
  const navigate = useNavigate();

  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell align="center" component="th" scope="row">
        {moment(get(row, 'timeWhenFineTuningStartedRunning', null), 'YYYY-MM-DDTkk:mm:SSSSSZ').format('MM-DD-YYYY')}
      </TableCell>
      <TableCell align="center">{row.vppText}</TableCell>
      <TableCell align="center">{row.contentLanguage}</TableCell>
      <TableCell align="center">
        <Iconify
          sx={{ fontSize: '18px', color: row.doSincFilter ? '#505050' : '#bc4749' }}
          icon={row.doSincFilter ? 'carbon:checkmark-filled' : 'carbon:error-filled'}
        />
      </TableCell>
      <TableCell align="center">
        <Iconify
          sx={{ fontSize: '18px', color: '#505050' }}
          icon={row.doNoiseRemoval ? 'carbon:checkmark-filled' : 'carbon:error-filled'}
        />
      </TableCell>
      <TableCell align="center">{row.noiseRemovalMaxTime}</TableCell>
      <TableCell align="center">{row.numberOfUsersToRun}</TableCell>
      <TableCell align="center">{row.minLPDegrees}</TableCell>
      <TableCell align="center">{row.maxLPDegrees}</TableCell>
      <TableCell align="center">{row.minFrameLength}</TableCell>
      <TableCell align="center">{row.maxFrameLength}</TableCell>
      <TableCell align="center">{row.accuracy}</TableCell>
      <TableCell align="center">{get(row, 'trainingETA', 'No Data Yet')}</TableCell>
      <TableCell>
        <ButtonBase
          sx={{
            pl: 1,
            py: 0.5,
            pr: 0.5,
            borderRadius: 1,
            typography: 'subtitle2',
            color: '#EFEFEF',
            bgcolor: '#505050',
          }}
          onClick={() => {
            navigate(`/log_details/${row.targetAPIKey}/${row.vsitCustomPhrasesId}/${row.objectId}/current_fine_tuning`);
          }}
        >
          View Logs
          <Iconify
            icon="carbon:result"
            sx={{ mx: 0.5, width: 18, height: 18 }}
          />
        </ButtonBase>
      </TableCell>
      <TableCell>
        <LoadingButton
          size="small"
          // color={updateButtonState}
          onClick={() => {
            setUpdateButtonState('loading');
            const url = (
              `/api/kill_running_fine_tuning/${row.objectId}`
            );
            protectedFetch(url, {
              method: 'post',
              headers: {
                accept: 'application/json',
              },
            })
              .then((response) => {
                handleSetGenericAlertDialogFromGenericResponse(response);
                if (get(response, 'status', false)) {
                  if (response.status === 200) {
                    setUpdateButtonState('success');
                  } else {
                    setUpdateButtonState('fail');
                  }
                } else {
                  setUpdateButtonState('fail');
                }
              })
              .catch((err) => {
                console.log('killRunningFineTuning catch Exception: ', err);
              });
          }}
          loading={updateButtonState === 'loading'}
          loadingPosition="start"
          startIcon={updateButtonIcon(updateButtonState)}
          variant="contained"
        >
          Stop
        </LoadingButton>
      </TableCell>
    </TableRow>
  );
}

function CurrentlyRunningFineTuningInstances(props) {
  const { rows } = props;
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        { rows === null ? <caption>Retrieved data will show here</caption> : '' }
        <TableHead>
          <TableRow>
            <TableCell align="center">Fine Tuning Start Date</TableCell>
            <TableCell align="center">Phrase</TableCell>
            <TableCell align="center">Content Language</TableCell>
            <TableCell align="center">Do Sinc Filter</TableCell>
            <TableCell align="center">Do Noise Removal</TableCell>
            <TableCell align="center">Noise Removal Max Time</TableCell>
            <TableCell align="center">Total Users</TableCell>
            <TableCell align="center">Min LP Degrees</TableCell>
            <TableCell align="center">Max LP Degrees</TableCell>
            <TableCell align="center">Min Frame Length</TableCell>
            <TableCell align="center">Max Frame Length</TableCell>
            <TableCell align="center">Accuracy</TableCell>
            <TableCell align="center">Training Completion ETA</TableCell>
            <TableCell align="center">Logs</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row
              key={get(row, 'supportId', Math.random().toString(36).substring(2, 7))}
              row={row}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function CurrentlyRunning() {
  const [instances, setInstances] = useState([]);
  const {
    selectedAccount,
    findButtonHit,
    handleSetFindButtonHit,
    selectedPhrase,
    accounts,
  } = useContext(FineTuningContext);
  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  const handleFindClick = () => {
    try {
      const formData = new FormData();
      formData.append('apiKey', selectedAccount.key);
      formData.append('vsitCustomPhrasesId', selectedPhrase.vsitCustomPhrasesId);
      const url = (
        '/api/get_running_fine_tuning_instances'
      );
      const instancesFetch = protectedFetch(url, {
        method: 'post',
        headers: {
          accept: 'application/json',
        },
        body: formData,
      });
      instancesFetch.then((instancesInfo) => {
        if (instancesInfo.success && instancesInfo !== null && isArray(instancesInfo.instances)) {
          setInstances(get(instancesInfo, 'instances', []));
          handleSetFindButtonHit(true);
        } else {
          handleSetGenericAlertDialogFromGenericResponse(instancesInfo);
        }
      });
    } catch (err) {
      console.log('handleFindClick catch Exception: ', err);
    }
  };

  useEffect(() => {
    if (findButtonHit) {
      handleFindClick();
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
      >
        <FineTuningDetails
          includeStartDate={false}
          activateButtonText="Find"
          activateButtonFunction={handleFindClick}
          detailsSubHeader="Select API Key and Phrase to display manage currently running Fine Tuning instances"
        />
        {
          findButtonHit && (
            <Grid md={12} sx={{}}>
              <Card>
                <CardHeader
                  title="Currently Running Fine Tuning Instances"
                  subheader="Track or Manage currently running Fine Tuning instances"
                  sx={{ mb: 3 }}
                />
                <CurrentlyRunningFineTuningInstances rows={instances} />
              </Card>
            </Grid>
          )
        }
      </Grid>
    </Container>
  );
}
