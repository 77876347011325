/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  Container, Divider, Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
// import { Icon as IconifyIcon } from '@iconify/react';
import Player from '../../Player/Player';

import accessMenu from './AccountsLeftNav.png';
import firstMenu from './AccountsFirstView.png';
import sortButton from './AccountsSortButton.png';
import search from './AccountsSearch.png';
import groupsAndUsers from './AccountsGroupsUsersTab.png';
import selectUser from './AccountsSelectUser.png';
import userActivity from './AccountsUserActivity.png';
import callDetails from './AccountsCallDetail.png';
import jsonLogExpand from './AccountsJSONLog.png';
import jsonLogInfo from './AccountsSTTF.png';
import breadcrumbs from './AccountsBreadCrumbs.png';
import selectSucc from './AccountsSelectSucc.png';
import previousEnrollments from './AccountsPreviousEnrollments.png';

import videoTutorial from './Accounts.mov';
import CustomBreadcrumbs from '../../CustomBreadCrumbs/CustomBreadCrumbs';
import TutorialStep from '../TutorialStep';

const steps = [
  {
    title: 'Access the Menu',
    stepImg: accessMenu,
    stepInfo: 'To Access the account settings menu, click on the Accounts section in the left hand menu',
  },
  {
    title: 'First Menu',
    stepImg: firstMenu,
    stepInfo: 'Here you can access all of your accounts and sub-accounts',
  },
  {
    title: 'Sort Headers',
    stepImg: sortButton,
    stepInfo: 'You can sort the table by clicking the arrows next to the headers',
  },
  {
    title: 'Search',
    stepImg: search,
    stepInfo: 'You may also search by API key or email',
  },
  {
    title: 'Click On API Key',
    stepImg: firstMenu,
    stepInfo: 'Click on a API Key to proceed to the next menu',
  },
  {
    title: 'Users and Groups',
    stepImg: groupsAndUsers,
    stepInfo: 'In this Menu, you can find your users and groups. Click on the tabs in the user table to select between user IDs and group IDs',
  },
  {
    title: 'Selecting a user',
    stepImg: selectUser,
    stepInfo: 'Click on a user ID to proceed to the next menu',
  },
  {
    title: 'User Activity Overview',
    stepImg: userActivity,
    stepInfo: 'This menu defaults to the most recent activity made by this user. Everything can be sorted by the response code, date of the call, etc.',
  },
  {
    title: 'Call Details Menu',
    stepImg: callDetails,
    stepInfo: 'Clicking on call will lead to the call details menu, which will include all of the available information for that call. For instance if it was a successful voice verification call, it will include the previous voice enrollments as well.',
  },
  {
    title: 'JSON Logs',
    stepImg: jsonLogExpand,
    stepInfo: 'For example, this call failed a voice verification attempt with low speech-to-text confidence (STTF), so it was rejected. You can see that by clicking expanding the JSON Logs response',
  },
  {
    title: 'JSON Logs 2',
    stepImg: jsonLogInfo,
    stepInfo: 'And reading the message section',
  },
  {
    title: 'Breadcrumbs',
    stepImg: breadcrumbs,
    stepInfo: 'In this case, to investigate this user it might be helpful to go back to the previous enrollments and understand what happened. So in order to that, navigate to the previous menu using the bread crumbs on top',
  },
  {
    title: 'JSON Logs',
    stepImg: selectSucc,
    stepInfo: 'Expand the time range of the displayed activity until it includes previous enrollments or successful verifications',
  },
  {
    title: 'JSON Logs',
    stepImg: previousEnrollments,
    stepInfo: 'Listen to the enrollments and the verification in call details to figure out what went wrong with the other recording. In this case, the phrases were completely different',
  },
];

export default function AccountsTutorial() {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
      >
        <Grid md={12} sx={{}}>
          <CustomBreadcrumbs
            heading="Accounts Tutorial"
            links={[
              {
                name: 'Tutorials',
                href: '/tutorials',
              },
              {
                name: 'Accounts',
              },
            ]}
            sx={{
              mb: { xs: 3, md: 3 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Player
            url={videoTutorial}
            width="100%"
            height="100%"
            controls
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 6 },
          pb: { xs: 6, md: 6, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={3}
            justifyContent={{ md: 'center' }}
          >

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                How to access your detailed information on your users
              </Typography>
            </Grid>

            {steps.map((step, index) => (
              <Grid
                item
                xs={12}
                md={8}
                key={step.title}
              >
                <TutorialStep
                  step={step}
                  stepNumber={index + 1}
                />
                <Divider sx={{ pt: 2.5, borderStyle: 'dashed' }} />

              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Container>
  );
}
