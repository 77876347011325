/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  Container, Divider, Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
// import { Icon as IconifyIcon } from '@iconify/react';
import Player from '../../Player/Player';

import accessMenu from './PreviousResults.png';
import rejectionReport from './RejectionReport.png';
import accountsUserActivity from './AccountsUserActivity.png';
import accountsCallDetail from './AccountsCallDetail.png';
import fineTuningToggle from './FineTuningToggle.png';
import clickOnNewSession from './ClickOnNewSession.png';
import december from './December.png';
import fineTuningOverViewUsers from './FineTuningOverViewUsers.png';
import fineTuningOverViewSettings from './FineTuningOverViewSettings.png';
import previousResults from './PreviousResults1.png';
import selectApiKeyIfNot from './SelectApiKeyIfNot.png';
import previousResultsTable from './PreviousResultsTable.png';
import expanded from './Expanded.png';
import sivSettings from './SivSettings.png';
import clickUpdate from './ClickUpdate.png';
import engineParametersHistory from './EngineParametersHistory.png';
import rejectionRateValues from './RejectionRateValues.png';

import videoTutorial from './FineTuning.mov';
import CustomBreadcrumbs from '../../CustomBreadCrumbs/CustomBreadCrumbs';
import TutorialStep from '../TutorialStep';

const steps = [
  {
    title: 'Before We Start',
    stepInfo:
      // eslint-disable-next-line react/jsx-indent
      <>
        <Typography>Before starting Fine Tuning, there are three prerequisites:</Typography>
        <ol>
          <li>A minimum of 26 users</li>
          <li>Each user must use the same phrase and content language</li>
          <li>Each user must have at least one verification</li>
        </ol>
      </>,
  },
  {
    title: 'First Step',
    stepImg: rejectionReport,
    stepInfo: `Once all three prerequisites are met, you can start fine tuning.
    The first step is to mark verification recordings of each user you are trying to use.
    A handy way to find eligible users is to use the Rejection Rate Report.
    It will find all users with the selected phrase that have at least one verification call.
    If you do not know how to generate a rejection rate report, check out the corresponding tutorial in the tutorials section.
    `,
  },
  {
    title: 'Go to the Activity Table',
    stepImg: accountsUserActivity,
    stepInfo: `Click on the user to go the call activity table.
    Find a verification call with response code that is either a "SUCC" or "FAIL".
    Other types of calls will not work.
    `,
  },
  {
    title: 'Using the toggle',
    stepImg: accountsCallDetail,
    stepInfo: `Once within a Call Detail menu of one of the verifications, find the fine tuning toggle.
    `,
  },
  {
    title: 'Marking users prerequisites',
    stepInfo:
      // eslint-disable-next-line react/jsx-indent
      <>
        <Typography>There are three things to remember when marking users for fine tuning:</Typography>
        <ol>
          <li>
            Make sure they are saying the correct phrase, for example if the phrase is &quot;Never forget tomorrow is a new day&quot;,
            they are saying the entire phrase, not swapping words for synonyms, and it is not a completely different phrase like &quot;My voice is my password&quot; instead
          </li>
          <li>They are the same person from their enrollments</li>
          <li>The verification call has limited background noise</li>
        </ol>
      </>,
  },
  {
    title: 'Mark them',
    stepImg: fineTuningToggle,
    stepInfo: `If all the prerequisites are met you can mark them for tuning with the toggle in the middle of the screen.
    Just remember for each user you only mark 1 verification. So for 26 unique users, there will be 26 verifications and no repeats on a single user.  
    `,
  },
  {
    title: 'Once you have 26',
    stepImg: clickOnNewSession,
    stepInfo: `Once you have 26 users, go to the Fine Tuning Section in the left hand menu, and start a new session
    `,
  },
  {
    title: 'Select API Key, Phrase, and Start Date',
    stepImg: december,
    stepInfo: `Select API Key, Phrase, and Start Date. Note that the start date is determined by the earliest verification call that you marked.
    For example, if I marked users ranging for December 1st through April, I would select December 1st as the start date. Once done, click start.
    `,
  },
  {
    title: 'Users Table',
    stepImg: fineTuningOverViewUsers,
    stepInfo: `The server will automatically unmark any duplicate users. And they will all be listed on the right hand of the screen,
    you may also click on the users to go directly to the verification call that you marked.
    `,
  },
  {
    title: 'Engine Settings',
    stepImg: fineTuningOverViewSettings,
    stepInfo: `Engine Settings on the left hand of the screen are important to note before you run fine tuning.
    For best results, run fine tuning multiple times on the same set of users to determine optimal settings.
    If there is a lot of background noise in users recordings, we recommend running Fine tuning twice: with Noise Removal On and with Noise Removal Off.
    In most cases it helps to have Noise Removal On, but that can depend on the quality of the calls and the environment of the recordings. 
    Usually you do not need to alter Noise Removal Max Time, Minimum LP Degrees, Maximum LP Degrees,
    Minimum Frame Length, Maximum Frame Length, and Accuracy. However, you may choose to increase the ranges of these settings to have a bigger
    breadth of data to look at. Note that higher numbers will increase the amount of time it takes to complete Fine Tuning.
    `,
  },
  {
    title: 'Press Run',
    // stepImg: accessMenu,
    stepInfo: `Once you are ready, click run and fine tuning will begin. In about 10 mins,
    look in the currently running section of Fine Tuning, and it should show the estimated time of completion. You may also stop the session if you change your mind
    by clicking on the stop button under the Actions section. 
    `,
  },
  {
    title: 'Previous Results',
    stepImg: previousResults,
    stepInfo: `Once Fine Tuning is finished, the results will show up in the Previous Results section 
    `,
  },
  {
    title: 'Looking at past Results',
    stepImg: selectApiKeyIfNot,
    stepInfo: `Select API key and phrase if not selected already
    `,
  },
  {
    title: 'Click to expand',
    stepImg: previousResultsTable,
    stepInfo: `The results table will include all of the settings used in that particular run in each table entry. 
    Click the down arrow on the left side of any of the table entry to expand and see the results of that particular run. 
    `,
  },
  {
    title: 'Results vs Current',
    stepImg: expanded,
    stepInfo: `When the results table entries are expanded, the top row includes results of that particular fine tuning run. 
    The row below includes settings that are currently used on your account to compare.
    `,
  },
  {
    title: 'Results Table',
    stepImg: sivSettings,
    stepInfo:
      // eslint-disable-next-line react/jsx-indent
      <>
        <Typography>
          VoiceIt uses four biometric engines to determine how similar a given verification is to the enrollments of a particular user.
          Each engine (SIV1,SIV2, SIV3, and SIV4) has input parameters that affect how it determines if the verification is a match:
        </Typography>
        <ul>
          <li>
            LP degrees (SIV1 and SIV2): varies between 8 and 100
          </li>
          <li>
            Frame Length (SIV1 and SIV2): varies between 1 and 300
          </li>
          <li>
            Thresholds (SIV1, SIV2, SIV3, SIV4): Each engine generates a &quot;match&quot; score,
            the values in this column determine how high this &quot;match&quot; score in each engine must go to be
            considered a match. For example, if SIV3 has a threshold of 75, and your verification call receives a score of 5 from SIV3, that call will likely
            be considered a failed verification. (Note that failing one engine does not always mean the verification will be marked as a FAIL,
            if scores of other engines are high enough, it will pass).
          </li>
        </ul>
      </>,
  },
  {
    title: 'Apply Settings',
    stepImg: rejectionRateValues,
    stepInfo: `Another import piece of information in the results table would be the rejection rate values.
    The industry standard is 10%, so ideally you would like to get as close to 10% as possible or lower. If the values from your
    fine tuning are too high, considered rerunning it with a wider range of settings, or with or without noise cancelling.  
    `,
  },
  {
    title: 'Apply Settings',
    stepImg: clickUpdate,
    stepInfo: `If you would like to apply settings of this particular run, press 'update'.
      This will save your current settings for later if you wish to reverse the changes, and apply the newly 
      calculated results to your account.
    `,
  },
  {
    title: 'Engine Parameters History',
    stepImg: engineParametersHistory,
    stepInfo: `Once you change your engine settings, entries will start appearing in Engine Parameters History section of the screen.
    Hovering over the entires in this section will highlight which model tuning run originated that specific change, or it will say default if that was
    the original starting point of your engine settings. 
    `,
  },
];

export default function FineTuningTutorial() {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
      >
        <Grid md={12} sx={{}}>
          <CustomBreadcrumbs
            heading="Fine Tuning Tutorial"
            links={[
              {
                name: 'Tutorials',
                href: '/tutorials',
              },
              {
                name: 'Fine Tuning',
              },
            ]}
            sx={{
              mb: { xs: 3, md: 3 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Player
            url={videoTutorial}
            width="100%"
            height="100%"
            controls
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 6 },
          pb: { xs: 6, md: 6, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={3}
            justifyContent={{ md: 'center' }}
          >

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                How to use Fine Tuning
              </Typography>
            </Grid>

            {steps.map((step, index) => (
              <Grid
                item
                xs={12}
                md={8}
                key={step.title}
              >
                <TutorialStep
                  step={step}
                  stepNumber={index + 1}
                />
                <Divider sx={{ pt: 2.5, borderStyle: 'dashed' }} />

              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Container>
  );
}
