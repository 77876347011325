/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
import React, {
  useEffect,
  useState,
  useContext,
} from 'react';
import { format } from 'date-fns';
import {
  Box, Container, Card, CardHeader, Stack, Table, TableBody, TableCell,
  TableHead, TableRow, Typography, TableContainer, ListItemText, Divider,
  Collapse, Icon, ButtonBase, IconButton, Button, Paper,
} from '@mui/material';
import { useSelector } from 'react-redux';
import jsPDF from 'jspdf';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, isArray } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTheme, alpha } from '@mui/material/styles';

import Grid from '@mui/material/Unstable_Grid2';
import moment from 'moment';
import LoadingButton from '@mui/lab/LoadingButton';
import Iconify from '../Iconify';
import { protectedFetch } from '../../utils/network';
import FineTuningDetails from './FineTuningDetails';
import EngineParametersHistory from './EngineParametersHistory';
import FineTuningContext from '../../contexts/FineTuningContext';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';

function updateButtonIcon(state) {
  if (state === 'fail') {
    return <Iconify sx={{ fontSize: '18px', color: '#bc4749' }} icon="carbon:error-filled" />;
  } if (state === 'success') {
    return <Iconify sx={{ fontSize: '18px', color: '#505050' }} icon="carbon:checkmark-filled" />;
  }
  return <Iconify sx={{ fontSize: '18px', color: '#EFEFEF' }} icon="carbon:save" />;
}

function downloadPDF(previousFineTuningResultsInfo) {
  const companyName = get(previousFineTuningResultsInfo, 'companyName', 'Missing CompanyName');
  const testingResultsLength = get(previousFineTuningResultsInfo, 'results.testingResultsLength', 'Missing');
  const targetTrainingUserIdsLength = get(previousFineTuningResultsInfo, 'results.targetTrainingUserIdsLength', 'Missing');
  const currentPassingSiv1ForDegreeAndFramesize = get(previousFineTuningResultsInfo, 'results.currentPassingSiv1ForDegreeAndFramesize', 'Missing');
  const siv1FRRValue = get(previousFineTuningResultsInfo, 'results.siv1FRRValue', 'Missing');
  const highestValueOfSiv1CurrentSpoofingAttempts = get(previousFineTuningResultsInfo, 'results.highestValueOfSiv1CurrentSpoofingAttempts', 'Missing');
  const siv1ConfidenceDelta = get(previousFineTuningResultsInfo, 'results.siv1ConfidenceDelta', 'Missing');
  const currentPassingSiv2ForDegreeAndFramesize = get(previousFineTuningResultsInfo, 'results.currentPassingSiv2ForDegreeAndFramesize', 'Missing');
  const siv2FRRValue = get(previousFineTuningResultsInfo, 'results.siv2FRRValue', 'Missing');
  const highestValueOfSiv2CurrentSpoofingAttempts = get(previousFineTuningResultsInfo, 'results.highestValueOfSiv2CurrentSpoofingAttempts', 'Missing');
  const siv2ConfidenceDelta = get(previousFineTuningResultsInfo, 'results.siv2ConfidenceDelta', 'Missing');
  const currentPassingSiv3ForDegreeAndFramesize = get(previousFineTuningResultsInfo, 'results.currentPassingSiv3ForDegreeAndFramesize', 'Missing');
  const siv3FRRValue = get(previousFineTuningResultsInfo, 'results.siv3FRRValue', 'Missing');
  const highestValueOfSiv3CurrentSpoofingAttempts = get(previousFineTuningResultsInfo, 'results.highestValueOfSiv3CurrentSpoofingAttempts', 'Missing');
  const currentPassingSiv4ForDegreeAndFramesize = get(previousFineTuningResultsInfo, 'results.currentPassingSiv4ForDegreeAndFramesize', 'Missing');
  const siv4FRRValue = get(previousFineTuningResultsInfo, 'results.siv4FRRValue', 'Missing');
  const highestValueOfSiv4CurrentSpoofingAttempts = get(previousFineTuningResultsInfo, 'results.highestValueOfSiv4CurrentSpoofingAttempts', 'Missing');
  const overallSuccessfulAttempts = get(previousFineTuningResultsInfo, 'results.overallSuccessfulAttempts', 'Missing');
  const overallFailedAttempts = get(previousFineTuningResultsInfo, 'results.overallFailedAttempts', 'Missing');
  const overallBestFRRValue = get(previousFineTuningResultsInfo, 'results.overallBestFRRValue', 'Missing');
  const targetTestingUserIdsLength = get(previousFineTuningResultsInfo, 'results.targetTestingUserIdsLength', 'Missing');
  const bestSiv1LPDegrees = get(previousFineTuningResultsInfo, 'results.bestSiv1LPDegrees', 'Missing');
  const bestSiv1FrameLength = get(previousFineTuningResultsInfo, 'results.bestSiv1FrameLength', 'Missing');
  const bestSiv2LPDegrees = get(previousFineTuningResultsInfo, 'results.bestSiv2LPDegrees', 'Missing');
  const bestSiv2FrameLength = get(previousFineTuningResultsInfo, 'results.bestSiv2FrameLength', 'Missing');
  const doSincFilter = get(previousFineTuningResultsInfo, 'doSincFilter', 'Missing DoSincFilter');
  const doNoiseRemoval = get(previousFineTuningResultsInfo, 'doNoiseRemoval', 'Missing DoNoiseRemoval');
  const noiseRemovalMaxTime = get(previousFineTuningResultsInfo, 'noiseRemovalMaxTime', 'Missing NoiseRemovalMaxTime');
  const siv1Accuracy = get(previousFineTuningResultsInfo, 'accuracy', 'Missing Accuracy');

  const startDate = get(previousFineTuningResultsInfo, 'timeWhenFineTuningStartedRunning', '');

  const fileName = `${companyName}FineTuningReport${startDate}.pdf`;

  // because this pdf api requires manual text coordinates have to keep track of it
  let lastTextYLocation = 20;
  // eslint-disable-next-line new-cap
  const doc = new jsPDF();
  const boldHeaderText = [];
  boldHeaderText.push(`Fine Tuning Report: ${companyName}`);
  boldHeaderText.push(`Date: ${startDate}`);
  boldHeaderText.push(`doSincFilter: ${doSincFilter.toString()}, doNoiseRemoval: ${doNoiseRemoval.toString()}`);
  boldHeaderText.push(`noiseRemovalMaxTime: ${noiseRemovalMaxTime}, SIV1 Accuracy: ${siv1Accuracy}`);

  const regularText = [];

  regularText.push(`Number Of Hyper Parameter Training Subjects : ${targetTrainingUserIdsLength}`);
  regularText.push(`Number Of Testing Subjects On Hyper Parameters : ${targetTestingUserIdsLength}`);
  regularText.push(`Best Hyper Parameters SIV1 LPDegrees = ${bestSiv1LPDegrees} and FrameLength = ${bestSiv1FrameLength}`);
  regularText.push(`Best Hyper Parameters SIV2 LPDegrees = ${bestSiv2LPDegrees} and FrameLength = ${bestSiv2FrameLength}`);
  regularText.push(`SIV1 Non Spoofing Attempts = ${testingResultsLength}`);
  regularText.push(`SIV1 Spoofing Attempts = ${testingResultsLength}`);
  regularText.push(`SIV1 Non Spoofing Attempt Fails = ${parseInt(testingResultsLength, 10) - parseInt(currentPassingSiv1ForDegreeAndFramesize, 10)}`);
  regularText.push('SIV1 Spoofing Attempt Fails = 0');
  regularText.push('----------------------------------------');
  regularText.push(`SIV1 FAR = 0.0% , FRR = ${siv1FRRValue}%`);
  regularText.push(`SIV1 VoiceConfidence Threshold = ${highestValueOfSiv1CurrentSpoofingAttempts}%`);
  regularText.push(`SIV1 Avg Confidence Delta Between NonSpoof/Spoof = ${siv1ConfidenceDelta}%`);
  regularText.push('========================================\n');
  regularText.push(`SIV2 Non Spoofing Attempts = ${testingResultsLength}`);
  regularText.push(`SIV2 Spoofing Attempts = ${testingResultsLength}`);
  regularText.push(`SIV2 Non Spoofing Attempt Fails = ${parseInt(testingResultsLength, 10) - parseInt(currentPassingSiv2ForDegreeAndFramesize, 10)}`);
  regularText.push('SIV2 Spoofing Attempt Fails = 0');
  regularText.push('----------------------------------------');
  regularText.push(`SIV2 FAR = 0.0% , FRR = ${siv2FRRValue}%`);
  regularText.push(`SIV2 VoiceConfidence Threshold = ${highestValueOfSiv2CurrentSpoofingAttempts}%`);
  regularText.push(`SIV2 Avg Confidence Delta Between NonSpoof/Spoof = ${siv2ConfidenceDelta}%`);
  regularText.push('========================================\n');
  regularText.push(`SIV3 Non Spoofing Attempts = ${testingResultsLength}`);
  regularText.push(`SIV3 Spoofing Attempts = ${testingResultsLength}`);
  regularText.push(`SIV3 Non Spoofing Attempt Fails = ${parseInt(testingResultsLength, 10) - parseInt(currentPassingSiv3ForDegreeAndFramesize, 10)}`);
  regularText.push('SIV3 Spoofing Attempt Fails = 0');
  regularText.push('----------------------------------------');
  regularText.push(`SIV3 FAR = 0.0% , FRR = ${siv3FRRValue}%`);
  regularText.push(`SIV3 VoiceConfidence Threshold = ${highestValueOfSiv3CurrentSpoofingAttempts}%`);
  regularText.push('========================================\n');
  regularText.push(`SIV4 Non Spoofing Attempts = ${testingResultsLength}`);
  regularText.push(`SIV4 Spoofing Attempts = ${testingResultsLength}`);
  regularText.push(`SIV4 Non Spoofing Attempt Fails = ${parseInt(testingResultsLength, 10) - parseInt(currentPassingSiv4ForDegreeAndFramesize, 10)}`);
  regularText.push('SIV4 Spoofing Attempt Fails = 0');
  regularText.push('----------------------------------------');
  regularText.push(`SIV4 FAR = 0.0% , FRR = ${siv4FRRValue}%`);
  regularText.push(`SIV4 VoiceConfidence Threshold = ${highestValueOfSiv4CurrentSpoofingAttempts}%`);
  regularText.push('========================================\n');
  regularText.push(`SIV1+SIV2+SIV3+SIV4 Successes =  ${overallSuccessfulAttempts}`);
  regularText.push(`SIV1+SIV2+SIV3+SIV4 Fails =  ${overallFailedAttempts}`);
  regularText.push(`SIV1+SIV2+SIV3+SIV4 False Negatives =  ${overallFailedAttempts}`);
  regularText.push('----------------------------------------');
  regularText.push(`Overall Engines FAR = 0.0% , FRR = ${overallBestFRRValue}%`);

  doc.setFontSize(14);
  const boldHeaderTextStart = lastTextYLocation + 10;
  boldHeaderText.forEach((item, index) => {
    doc.text(item, 20, boldHeaderTextStart + 8 * index);
    lastTextYLocation = boldHeaderTextStart + 8 * index;
  });
  doc.setFontSize(10);
  const regularTextStart = lastTextYLocation + 10;
  regularText.forEach((item, index) => {
    doc.text(item, 20, regularTextStart + 5 * index);
    lastTextYLocation = regularTextStart + 5 * index;
  });
  doc.save(fileName);
}

function FineTuningActionButton(props) {
  const {
    updateButtonState,
    setLoading,
    setNewFineTuningParameters,
    loading,
    row,
    rows,
    setPreviousResults,
    rowIndex,
    setUpdateButtonState,
  } = props;

  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  const deleteKilledFineTuning = (objectId) => {
    try {
      const deleteFineTuningFetch = protectedFetch(`/api/fine_tuning/${objectId}`, {
        method: 'delete',
        headers: {
          accept: 'application/json',
        },
      });
      deleteFineTuningFetch.then((response) => {
        if (response.success) {
          rows.splice(rowIndex, 1);
          setPreviousResults(rows);
        }
        handleSetGenericAlertDialogFromGenericResponse(response);
      });
    } catch (err) {
      console.log('deleteKilledFineTuning catch Exception: ', err);
    }
  };

  if (row.killed) {
    return (
      <LoadingButton
        size="small"
        color={updateButtonState}
        onClick={() => {
          setLoading(true);
          deleteKilledFineTuning(row.objectId);
        }}
        loading={loading}
        loadingPosition="start"
        startIcon={<Iconify sx={{ fontSize: '18px', color: '#bc4749' }} icon="carbon:delete" />}
        variant="contained"
      >
        Delete
      </LoadingButton>
    );
  }

  return (
    <LoadingButton
      size="small"
      color={updateButtonState}
      onClick={() => {
        setLoading(true);
        setNewFineTuningParameters(row);
      }}
      loading={loading}
      loadingPosition="start"
      startIcon={updateButtonIcon(updateButtonState)}
      variant="contained"
    >
      {updateButtonState === 'primary' ? 'Update' : 'Loading'}
    </LoadingButton>
  );
}

function Row(props) {
  const {
    row,
    rows,
    fineTuningEngineParametersHistory,
    frr,
    sx,
    fineTuningRowObjectIdToExpand,
    setFineTuningRowObjectIdToExpand,
    setPreviousResults,
    rowIndex,
  } = props;
  // const [open, setOpen] = useState(false);
  const [updateButtonState, setUpdateButtonState] = useState('primary');
  const [loading, setLoading] = useState(false);
  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);
  const navigate = useNavigate();

  const setNewFineTuningParameters = (arg) => {
    try {
      const setNewFineTuningParametersFetch = protectedFetch(`/api/set_new_fine_tuning_parameters/${arg.objectId}`, {
        method: 'post',
        headers: {
          accept: 'application/json',
        },
      });
      setNewFineTuningParametersFetch.then((response) => {
        handleSetGenericAlertDialogFromGenericResponse(response);
        if (response.success) {
          setUpdateButtonState('success');
        } else {
          setUpdateButtonState('fail');
        }
      });
    } catch (err) {
      console.log('setNewFineTuningParameters catch Exception: ', err);
    }
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: row.killed ? 'black !important' : 'white !important', ...sx }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={
              () => {
                if (fineTuningRowObjectIdToExpand === row.objectId) {
                  setFineTuningRowObjectIdToExpand('');
                } else {
                  setFineTuningRowObjectIdToExpand(row.objectId);
                }
              }
            }
          >
            <Iconify sx={{ fontSize: '18px', color: '#505050' }} icon={fineTuningRowObjectIdToExpand === row.objectId ? 'carbon:arrow-up' : 'carbon:arrow-down'} />
          </IconButton>
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {moment(get(row, 'timeWhenFineTuningStartedRunning', null), 'YYYY-MM-DDTkk:mm:SSSSSZ').format('MM-DD-YYYY')}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          <Iconify sx={{ fontSize: '18px', color: row.killed ? '#bc4749' : '#505050' }} icon={row.killed ? 'carbon:error-filled' : 'carbon:checkmark-filled'} />
        </TableCell>
        <TableCell align="center">{row.vppText}</TableCell>
        <TableCell align="center">{row.contentLanguage}</TableCell>
        <TableCell align="center">
          <Iconify
            sx={{ fontSize: '18px', color: row.doSincFilter ? '#505050' : '#bc4749' }}
            icon={row.doSincFilter ? 'carbon:checkmark-filled' : 'carbon:error-filled'}
          />
        </TableCell>
        <TableCell align="center">
          <Iconify
            sx={{ fontSize: '18px', color: row.doNoiseRemoval ? '#505050' : '#bc4749' }}
            icon={row.doNoiseRemoval ? 'carbon:checkmark-filled' : 'carbon:error-filled'}
          />
        </TableCell>
        <TableCell align="center">{row.noiseRemovalMaxTime}</TableCell>
        <TableCell align="center">{row.numberOfUsersToRun}</TableCell>
        <TableCell align="center">{row.minLPDegrees}</TableCell>
        <TableCell align="center">{row.maxLPDegrees}</TableCell>
        <TableCell align="center">{row.minFrameLength}</TableCell>
        <TableCell align="center">{row.maxFrameLength}</TableCell>
        <TableCell align="center">{row.accuracy}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={fineTuningRowObjectIdToExpand === row.objectId} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Fine Tuning Results
              </Typography>
              <Table size="small" aria-label="results">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" />
                    <TableCell align="center">Best SIV 1 LP Degrees</TableCell>
                    <TableCell align="center">Best SIV 1 Frame Length</TableCell>
                    <TableCell align="center">Best SIV 2 LP Degrees</TableCell>
                    <TableCell align="center">Best SIV 2 Frame Length</TableCell>
                    <TableCell align="center">SIV 1 Threshold</TableCell>
                    <TableCell align="center">SIV 2 Threshold</TableCell>
                    <TableCell align="center">SIV 3 Threshold</TableCell>
                    <TableCell align="center">SIV 4 Threshold</TableCell>
                    <TableCell align="center">Rejection Rate From The Original Calls</TableCell>
                    <TableCell align="center">Rejection Rate If These New Parameters Are Applied</TableCell>
                    <TableCell align="center">Your Current Rejection Rate in the past 24 hours</TableCell>
                    <TableCell align="center">Logs</TableCell>
                    <TableCell align="center">Update Engine Settings</TableCell>
                    <TableCell align="center">Download PDF</TableCell>
                    {/* <TableCell>Actions</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center" />
                    <TableCell component="th" align="center" scope="row">
                      {row.killed ? 'NA' : get(row, 'results.bestSiv1LPDegrees', 'NA')}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {row.killed ? 'NA' : get(row, 'results.bestSiv1FrameLength', 'NA')}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {row.killed ? 'NA' : get(row, 'results.bestSiv2LPDegrees', 'NA')}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {row.killed ? 'NA' : get(row, 'results.bestSiv2FrameLength', 'NA')}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {(row.killed ? 'NA' : (get(row, 'results.highestValueOfSiv1CurrentSpoofingAttempts', 0.0)).toFixed(2))}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {(row.killed ? 'NA' : (get(row, 'results.highestValueOfSiv2CurrentSpoofingAttempts', 0.0)).toFixed(2))}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {(row.killed ? 'NA' : (get(row, 'results.highestValueOfSiv3CurrentSpoofingAttempts', 0.0)).toFixed(2))}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {(row.killed ? 'NA' : (get(row, 'results.highestValueOfSiv4CurrentSpoofingAttempts', 0.0)).toFixed(2))}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {row.killed ? 'Missing ' : `${get(row, 'testingSourceUsersFrr', 'Missing ')}%`}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {row.killed ? 'Missing ' : `${get(row, 'results.overallBestFRRValue', 'Missing ')}%`}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row" />
                    <TableCell>
                      {row.internalLog.data !== null
                        ? (
                          <ButtonBase
                            sx={{
                              pl: 1,
                              py: 0.5,
                              pr: 0.5,
                              borderRadius: 1,
                              typography: 'subtitle2',
                              color: '#EFEFEF',
                              bgcolor: '#505050',
                            }}
                            onClick={() => {
                              navigate(`/log_details/${row.targetAPIKey}/${row.vsitCustomPhrasesId}/${row.objectId}/previous_fine_tuning`);
                            }}
                          >
                            View Logs
                            <Iconify
                              icon="carbon:result"
                              sx={{ mx: 0.5, width: 18, height: 18 }}
                            />
                          </ButtonBase>
                        ) : ''}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      <FineTuningActionButton
                        updateButtonState={updateButtonState}
                        setLoading={setLoading}
                        setNewFineTuningParameters={setNewFineTuningParameters}
                        updateButtonIcon={updateButtonIcon}
                        row={row}
                        setPreviousResults={setPreviousResults}
                        rowIndex={rowIndex}
                        setUpdateButtonState={setUpdateButtonState}
                        rows={rows}
                      />
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      <IconButton
                        aria-label="download"
                        onClick={() => {
                          downloadPDF(row);
                        }}
                      >
                        <Iconify sx={{ fontSize: '18px', color: '#505050' }} icon="carbon:download" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Your Current Settings:</TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {get(fineTuningEngineParametersHistory.engineParametersHistory, '[0].siv1LPDegrees', 'NA')}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {get(fineTuningEngineParametersHistory.engineParametersHistory, '[0].siv1FrameLength', 'NA')}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {get(fineTuningEngineParametersHistory.engineParametersHistory, '[0].siv2LPDegrees', 'NA')}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {get(fineTuningEngineParametersHistory.engineParametersHistory, '[0].siv2FrameLength', 'NA')}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {get(fineTuningEngineParametersHistory.engineParametersHistory, '[0].siv1AvgConfidence', 'NA')}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {get(fineTuningEngineParametersHistory.engineParametersHistory, '[0].siv2AvgConfidence', 'NA')}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {get(fineTuningEngineParametersHistory.engineParametersHistory, '[0].siv3Confidence', 'NA')}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {get(fineTuningEngineParametersHistory.engineParametersHistory, '[0].siv4Confidence', 'NA')}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row" />
                    <TableCell component="th" align="center" scope="row" />
                    <TableCell component="th" align="center" scope="row">
                      {frr.toFixed(2)}
                      %
                    </TableCell>
                    <TableCell component="th" align="center" scope="row" />
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function PreviousFineTuningResultsTable(props) {
  const {
    rows,
    fineTuningRowObjectIdToHighlight,
    fineTuningRowObjectIdToExpand,
    setFineTuningRowObjectIdToExpand,
    setPreviousResults,
    fineTuningEngineParametersHistory,
  } = props;

  const [frr, setFrr] = useState(0.0);
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">Fine Tuning Start Date</TableCell>
            <TableCell align="center">Completed</TableCell>
            <TableCell align="center">Phrase</TableCell>
            <TableCell align="center">Content Language</TableCell>
            <TableCell align="center">Do Sinc Filter</TableCell>
            <TableCell align="center">Do Noise Removal</TableCell>
            <TableCell align="center">Noise Removal Max Time</TableCell>
            <TableCell align="center">Total Users</TableCell>
            <TableCell align="center">Min LP Degrees</TableCell>
            <TableCell align="center">Max LP Degrees</TableCell>
            <TableCell align="center">Min Frame Length</TableCell>
            <TableCell align="center">Max Frame Length</TableCell>
            <TableCell align="center">Accuracy</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <Row
              key={get(row, 'objectId', Math.random().toString(36).substring(2, 7))}
              row={row}
              frr={frr}
              sx={{ backgroundColor: fineTuningRowObjectIdToHighlight === '' ? 'white' : (row.objectId === fineTuningRowObjectIdToHighlight ? 'white' : 'grey') }}
              fineTuningRowObjectIdToExpand={fineTuningRowObjectIdToExpand}
              setFineTuningRowObjectIdToExpand={setFineTuningRowObjectIdToExpand}
              setPreviousResults={setPreviousResults}
              rows={rows}
              rowIndex={index}
              fineTuningEngineParametersHistory={fineTuningEngineParametersHistory}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function PreviousResults() {
  const [previousResults, setPreviousResults] = useState([]);
  const [fineTuningEngineParametersHistory, setFineTuningEngineParametersHistory] = useState({});
  const {
    selectedAccount,
    findButtonHit,
    handleSetFindButtonHit,
    selectedPhrase,
  } = useContext(FineTuningContext);
  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  const handleFindClick = () => {
    try {
      const formData = new FormData();
      formData.append('apiKey', selectedAccount.key);
      formData.append('vsitCustomPhrasesId', selectedPhrase.vsitCustomPhrasesId);
      const url = (
        '/api/get_previous_fine_tuning_results'
      );
      const instancesFetch = protectedFetch(url, {
        method: 'post',
        headers: {
          accept: 'application/json',
        },
        body: formData,
      });
      instancesFetch.then((instancesInfo) => {
        if (instancesInfo.success) {
          setPreviousResults(get(instancesInfo, 'previousResults', false));
          setFineTuningEngineParametersHistory(get(instancesInfo, 'fineTuningEngineParametersHistory', {}));
          handleSetFindButtonHit(true);
        } else {
          handleSetGenericAlertDialogFromGenericResponse(instancesInfo);
        }
      });
    } catch (err) {
      console.log('handleFindClick catch Exception: ', err);
      // handleFindClick({ header: 'Phrase Request Info', text: err.message });
    }
  };

  const [fineTuningRowObjectIdToHighlight, setFineTuningRowObjectIdToHighlight] = useState('');
  const [fineTuningRowObjectIdToExpand, setFineTuningRowObjectIdToExpand] = useState('');

  useEffect(() => {
    if (findButtonHit) {
      handleFindClick();
    }
  }, []);

  const highlightFineTuningRow = (fineTuningObjectId) => {
    setFineTuningRowObjectIdToHighlight(fineTuningObjectId);
  };

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
      >
        <FineTuningDetails
          includeStartDate={false}
          activateButtonText="Find"
          activateButtonFunction={handleFindClick}
          detailsSubHeader="Select API Key and Phrase to display manage Fine Tuning instances that were previously run"
        />
        {
          findButtonHit && (
            <>
              <Grid md={12} sx={{}}>
                <Card>
                  <CardHeader
                    title="Engine Parameters History"
                    subheader="See which Fine Tuning derived engine settings were applied and when"
                  />
                  <EngineParametersHistory
                    highlightFineTuningRow={highlightFineTuningRow}
                    fineTuningEngineParametersHistory={fineTuningEngineParametersHistory}
                    fineTuningRowObjectIdToHighlight={fineTuningRowObjectIdToHighlight}
                    fineTuningRowObjectIdToExpand={fineTuningRowObjectIdToExpand}
                    setFineTuningRowObjectIdToExpand={setFineTuningRowObjectIdToExpand}
                  />
                </Card>
              </Grid>
              <Grid md={12} sx={{}}>
                <Card>
                  <CardHeader
                    title="Previous Fine Tuning Results"
                    subheader="Manage fine tuning results run previously"
                    sx={{ mb: 3 }}
                  />
                  <PreviousFineTuningResultsTable
                    rows={previousResults}
                    setPreviousResults={setPreviousResults}
                    fineTuningRowObjectIdToHighlight={fineTuningRowObjectIdToHighlight}
                    fineTuningRowObjectIdToExpand={fineTuningRowObjectIdToExpand}
                    setFineTuningRowObjectIdToExpand={setFineTuningRowObjectIdToExpand}
                    fineTuningEngineParametersHistory={fineTuningEngineParametersHistory}
                  />
                </Card>
              </Grid>
            </>
          )
        }
      </Grid>
    </Container>
  );
}
