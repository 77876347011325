/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  Container, Divider, Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
// import { Icon as IconifyIcon } from '@iconify/react';
import Player from '../../Player/Player';

import accessMenu from './AccessMenu.png';
import screenOverview from './ScreenOverview.png';
import reviewKeyAndToken from './ReviewKeyAndToken.png';
import renewToken from './RenewToken.png';

import videoTutorial from './DeveloperSetting.mov';
import CustomBreadcrumbs from '../../CustomBreadCrumbs/CustomBreadCrumbs';
import TutorialStep from '../TutorialStep';

const steps = [
  {
    title: 'Access the Menu',
    stepImg: accessMenu,
    stepInfo: 'To access the developer settings, go to the right hand section of your screen, and click the account info by triggering the settings dropdown.',
  },
  {
    title: 'Screen Overview',
    stepImg: screenOverview,
    stepInfo: 'Here you may change your password, and reveal your API key and token. Remember that you can always access documentation on voiceIt\'s API on api.voiceit.io.',
  },
  {
    title: 'Reveal API Key and Token',
    stepImg: reviewKeyAndToken,
    stepInfo: 'To reveal your API key and token and click here',
  },
  {
    title: 'Renew Token',
    stepImg: renewToken,
    stepInfo: 'If you wish to change your token, you may do so by pressing this button; however, keep in mind that any calls made with the old token will now be invalidated.',
  },
];

export default function DeveloperSettingsTutorial() {
  // const [loading, setLoading] = useState(true);

  // const onReady = () => {
  //   setLoading(false);
  // };

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
      >
        <Grid md={12} sx={{}}>
          <CustomBreadcrumbs
            heading="Developer Settings"
            links={[
              {
                name: 'Tutorials',
                href: '/tutorials',
              },
              {
                name: 'Developer Settings',
              },
            ]}
            sx={{
              mb: { xs: 3, md: 3 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Player
            url={videoTutorial}
            width="100%"
            height="100%"
            controls
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 6 },
          pb: { xs: 6, md: 6, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={3}
            justifyContent={{ md: 'center' }}
          >

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                How to access your developer settings to view your
                API key and token, to change your password, or to regenerate your API Token.
              </Typography>
            </Grid>

            {steps.map((step, index) => (
              <Grid
                item
                xs={12}
                md={8}
                key={step.title}
              >
                <TutorialStep
                  step={step}
                  stepNumber={index + 1}
                />
                <Divider sx={{ pt: 2.5, borderStyle: 'dashed' }} />

              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Container>
  );
}
