import { alpha } from '@mui/material/styles';
import { listClasses } from '@mui/material/List';
import { buttonClasses } from '@mui/material/Button';
import { listItemIconClasses } from '@mui/material/ListItemIcon';
import { tablePaginationClasses } from '@mui/material/TablePagination';
// ----------------------------------------------------------------------

export default function DataGrid(theme) {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          borderWidth: 0,
          [`& .${tablePaginationClasses.root}`]: {
            borderTop: 0,
          },
          [`& .${tablePaginationClasses.toolbar}`]: {
            height: 'auto',
          },
          '& .MuiDataGrid-filterIcon': {
            color: '#EFEFEF',
          },
          "& .MuiDataGrid-sortIcon": {
            color: "#EFEFEF",
          },
        },
        cell: {
          paddingLeft: theme.spacing(3),
          borderBottom: `1px dashed ${theme.palette.divider}`,
        },
        selectedRowCount: {
          whiteSpace: 'nowrap',
        },
        columnSeparator: {
          color: theme.palette.divider,
        },
        toolbarContainer: {
          padding: theme.spacing(4),
          borderBottom: `1px dashed ${theme.palette.divider}`,
          backgroundColor: 'red',
          color: '#EFEFEF',
        },
        menu: {
          [`& .${listClasses.root}`]: {
            padding: 0,
            [`& .${listItemIconClasses.root}`]: {
              minWidth: 0,
              color: '#EFEFEF',
              marginRight: theme.spacing(2),
            },
          },
        },
        columnHeaderTitleContainer: {
          paddingLeft: theme.spacing(1.75),
        },
        columnHeaders: {
          borderRadius: 0,
          backgroundColor: '#FCBC3D',
          color: '#000000',
          fontWeight: theme.typography.fontWeightSemiBold,
        },
        panelHeader: {
          padding: theme.spacing(2),
        },
        panelFooter: {
          padding: theme.spacing(2),
          justifyContent: 'flex-end',
          borderTop: `dashed 1px ${theme.palette.divider}`,
          [`& .${buttonClasses.root}`]: {
            '&:first-of-type': {
              border: `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
            },
            '&:last-of-type': {
              marginLeft: theme.spacing(1.5),
              color: '#EFEFEF',
              backgroundColor: '#EFEFEF',
            },
          },
        },
        filterForm: {
          padding: theme.spacing(2),
        },
        filterFormValueInput: {
          marginLeft: theme.spacing(2),
        },
        filterFormColumnInput: {
          marginLeft: theme.spacing(2),
        },
        filterFormOperatorInput: {
          marginLeft: theme.spacing(2),
        },
      },
    },
    MuiGridMenu: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-gridMenuList': {
            boxShadow: theme.customShadows.z20,
            borderRadius: theme.shape.borderRadius,
          },
          '& .MuiMenuItem-root': {
            ...theme.typography.body2,
          },
        },
      },
    },
    MuiGridFilterForm: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1.5, 0),
          '& .MuiFormControl-root': {
            margin: theme.spacing(0, 0.5),
          },
          '& .MuiInput-root': {
            marginTop: theme.spacing(3),
            '&::before, &::after': {
              display: 'none',
            },
            '& .MuiNativeSelect-select, .MuiInput-input': {
              ...theme.typography.body2,
              padding: theme.spacing(0.75, 1),
              borderRadius: theme.shape.borderRadius,
              backgroundColor: 'theme.palette.background.neutral',
            },
            '& .MuiSvgIcon-root': {
              right: 4,
            },
          },
        },
      },
    },
    MuiGridPanelFooter: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2),
          justifyContent: 'flex-end',
          '& .MuiButton-root': {
            '&:first-of-type': {
              marginRight: theme.spacing(1.5),
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            },
            '&:last-of-type': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            },
          },
        },
      },
    },
  };
}
