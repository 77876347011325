import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import thunk from 'redux-thunk';
import loginReducer from './reducers/loginReducer';
import errorReducer from './reducers/errorReducer';

const loginPersistConfig = {
  key: 'loginReducer',
  storage,
};

export default configureStore({
  reducer: {
    loginReducer: persistReducer(loginPersistConfig, loginReducer),
    errorReducer,
  },
});
