import { CLEAR_CACHE, EXPIRED_TOKEN } from '../actions/types';

const INITIAL_STATE = {
  expiredToken: false,
};

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EXPIRED_TOKEN:
      return {
        ...state,
        expiredToken: action.payload,
      };
    case CLEAR_CACHE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
