import React, { useEffect, useState, useContext } from 'react';
import {
  Box, Card, CardHeader, Stack,
  Divider, Autocomplete, TextField, Button,
} from '@mui/material';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';

import { useSelector } from 'react-redux';
import { FormProvider } from 'react-hook-form';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, isArray } from 'lodash';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';

import Grid from '@mui/material/Unstable_Grid2';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import { protectedFetch } from '../../utils/network';
import ActivityGraph from './ActivityGraph';

const colorMap = {
  SUCC: '#21e35d',
  MISP: 'rgb(209, 210, 44)',
  MISU: '#cdb923',
  FAIL: '#f94144',
  UNFD: '#503fa9',
  GNFD: '#8a2aa7',
  ENFD: '#c83964',
  FNFD: '#d33264',
  DDNE: '#CDFCD1',
  FTMF: '#ffa600',
  IFAD: '#aec63b',
  IFVD: '#069A6B',
  SRNR: '#bb7515',
  SSTQ: '#1d3e0e',
  SSTL: '#ffe708',
  NEHSD: '#00B1ED',
  STTF: '#0067AA',
  RWPU: '#003471',
  PNTE: '#D2FCF4',
  TVER: '#e6c693',
  NFEF: '#fcfe7a',
  GERR: '#cfa3d0',
  DAID: '#063963',
  UNAC: '#674c0a',
  INCP: '#924206',
  INPP: '#cf8a8d',
  PDNM: '#696374',
  NSPE: '#d7967e',
};

const columns = [
  {
    field: 'uniqueId',
    headerName: 'User ID',
    flex: 3,
  },
  {
    field: 'count',
    headerName: 'Call Count',
    flex: 1,
  },
];

export default function ActivityGraphWithSelector() {
  const defaultApiKey = useSelector((state) => state.loginReducer.apiKey);
  const isSuperUser = useSelector((state) => state.loginReducer.isSuperUser);
  // eslint-disable-next-line no-unused-vars
  const [selectedResponseCodeAndCall, setSelectedResponseCodeAndCall] = useState({ responseCode: '', call: '' });
  const [tableRows, setTableRows] = useState([]);
  const [apiKeys, setApiKeys] = useState([]);
  const [endDate, setEndDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(true);
  const [tableIsLoading, setIsTableLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('day'));
  const [selectedAPIKey, setSelectedAPIKey] = useState('');
  // kind of useless for our purposes but keeps the alerts away
  const [selectedAPIKeyInputValue, setSelectedAPIKeyInputValue] = React.useState('');
  const [series, setSeries] = useState([]);
  const [xAxisTimes, setXAxisTimes] = useState([]);

  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  useEffect(() => {
    const url = (
      '/api/graphable_developers'
    );
    const apiKeysFetch = protectedFetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    apiKeysFetch.then((apiKeysInfo) => {
      if (apiKeysInfo !== null) {
        if (!apiKeysInfo.success) {
          handleSetGenericAlertDialogFromGenericResponse(apiKeysInfo);
          return;
        }
        if (isArray(apiKeysInfo.developers)) {
          setApiKeys(apiKeysInfo.developers);
        }
        const st = startDate.clone().utc().format('YYYY-MM-DD HH:mm:ss');
        const et = endDate.clone().utc().format('YYYY-MM-DD HH:mm:ss');
        const graphUrl = (
          // eslint-disable-next-line quotes
          `/api/two/${isSuperUser ? 'all' : defaultApiKey}/activity_graph`
        );
        const graphFetch = protectedFetch(graphUrl, {
          method: 'post',
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ startTime: st, endTime: et, apiKey: 'all' }),
        });
        graphFetch.then((graphJson) => {
          if (graphJson !== null) {
            if (!graphJson.success) {
              handleSetGenericAlertDialogFromGenericResponse(graphJson);
              return;
            }
            // console.log(graphJson);
            const callCategories = get(graphJson, 'callCategories', []);
            const responseCodeCategories = get(graphJson, 'responseCodeCategories', []);
            const rawData = get(graphJson, 'data', {});
            const graphDataToDisplay = [];
            if (responseCodeCategories !== null) {
              responseCodeCategories.forEach((responseCode) => {
                const data = [];
                // eslint-disable-next-line no-unused-vars
                callCategories.forEach((call, index) => {
                  data.push(get(rawData, `${call}.${responseCode}`, 0));
                });
                graphDataToDisplay.push({ data, name: responseCode, color: get(colorMap, responseCode, '#276FBF') });
              });
            }
            if (callCategories !== null) {
              setXAxisTimes(callCategories);
            }
            if (graphDataToDisplay !== null) {
              setSeries(graphDataToDisplay);
            }
            setIsLoading(false);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    if (selectedResponseCodeAndCall.responseCode === '' || selectedResponseCodeAndCall.call === '') {
      return;
    }
    setIsTableLoading(true);
    let apiKeyToPass = isSuperUser ? 'all' : defaultApiKey;
    if (get(selectedAPIKey, 'value', '') !== '') {
      apiKeyToPass = get(selectedAPIKey, 'value', '');
    }

    const url = (
      `/api/two/${apiKeyToPass}/activity_graph_click`
    );
    const graphClickFetch = protectedFetch(url, {
      method: 'post',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        startTime: startDate.clone().utc().format('YYYY-MM-DD HH:mm:ss'),
        endTime: endDate.clone().utc().format('YYYY-MM-DD HH:mm:ss'),
        apiKey: apiKeyToPass,
        // responseCode: 'SUCC',
        // callType: 'createUser',
        responseCode: selectedResponseCodeAndCall.responseCode,
        callType: selectedResponseCodeAndCall.call,
      }),
    });
    graphClickFetch.then((graphClickFetchInfo) => {
      // add error handling here
      setTableRows(get(graphClickFetchInfo, 'items', []));
      setIsTableLoading(false);
    });
  }, [selectedResponseCodeAndCall]);

  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      const st = startDate.clone().utc().format('YYYY-MM-DD HH:mm:ss');
      const et = endDate.clone().utc().format('YYYY-MM-DD HH:mm:ss');
      const graphUrl = (
        // eslint-disable-next-line quotes
        `/api/two/${get(selectedAPIKey, 'value', '')}/activity_graph`
      );
      // console.log(graphUrl);
      // console.log(st, et);
      const graphJson = await protectedFetch(graphUrl, {
        method: 'post',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ startTime: st, endTime: et, apiKey: get(selectedAPIKey, 'value', '') }),
      });
      if (graphJson !== null) {
        if (!graphJson.success) {
          handleSetGenericAlertDialogFromGenericResponse(graphJson);
          return;
        }
        const callCategories = get(graphJson, 'callCategories', []);
        const responseCodeCategories = get(graphJson, 'responseCodeCategories', []);
        const rawData = get(graphJson, 'data', {});
        const graphDataToDisplay = [];
        responseCodeCategories.forEach((responseCode) => {
          const data = [];
          // eslint-disable-next-line no-unused-vars
          callCategories.forEach((call, index) => {
            data.push(get(rawData, `${call}.${responseCode}`, 0));
          });
          graphDataToDisplay.push({ data, name: responseCode });
        });
        // console.log('refresh data: ', graphDataToDisplay);
        setXAxisTimes(callCategories);
        setSeries(graphDataToDisplay);
        // handleSubmitPhrase({ header: 'Phrase Request Info', text: result });
      }
    } catch (err) {
      // console.error(err);
      // handleSubmitPhrase({ header: 'Phrase Request Info', text: err.message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Grid xs={12} md={6} lg={12}>
        <ActivityGraph
          isLoading={isLoading}
          title={`API 2.0 Activity (${get(selectedAPIKey, 'value', '') || (isSuperUser ? 'All Accounts' : defaultApiKey)})`}
          subheader="Sum of each response type by call type"
          series={series}
          categories={xAxisTimes}
          onClickTable={setSelectedResponseCodeAndCall}
        />
      </Grid>
      <Grid xs={12} md={6} lg={4}>
        <FormProvider>
          <Card
            data-aos="fade-in"
          >
            <CardHeader
              title="API 2.0 Activity Data Selection"
              subheader="Enter API Key and Date Ranges"
            // action={<CarouselArrows onNext={carousel.onNext} onPrev={carousel.onPrev} />}
            />
            <Divider sx={{ borderStyle: 'dashed' }} />

            <Stack direction="column" sx={{ mx: 3, mt: 2 }} spacing={2}>
              <Autocomplete
                fullWidth
                value={selectedAPIKey}
                onChange={(event, newValue) => {
                  setSelectedAPIKey(newValue);
                  // handleApiKeySelection(get(newValue, 'value', ''));
                }}
                inputValue={selectedAPIKeyInputValue}
                onInputChange={(event, newInputValue) => {
                  setSelectedAPIKeyInputValue(newInputValue);
                }}
                options={apiKeys}
                autoHighlight
                getOptionLabel={(option) => (option ? `${option.key} ${option.email}` : '')}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ color: '#000000' }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                  >
                    {option.key}
                    <span style={{ marginLeft: '16px', color: '#505050' }}>
                      {` ${get(option, 'email', '')}`}
                    </span>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label="API Key"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <DesktopDateTimePicker
                sx={{ width: '100%' }}
                label="Start date"
                ampm={false}
                disableFuture
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
              />
              <DesktopDateTimePicker
                sx={{ width: '100%' }}
                label="End date"
                ampm={false}
                disableFuture
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              />
            </Stack>

            <Stack
              spacing={2}
              sx={{
                p: 3,
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={() => handleRefresh()}
              >
                Refresh
              </Button>
            </Stack>
          </Card>
        </FormProvider>
      </Grid>
      <Grid xs={12} md={6} lg={8}>
        <Card
          data-aos="fade-in"
        >
          <CardHeader
            title={`Selected Activity Graph ResponseCode: ${get(selectedResponseCodeAndCall, 'responseCode', 'Not Selected')}, Call Type: ${get(selectedResponseCodeAndCall, 'call', 'Not Selected')}`}
            subheader="(Choose by clicking on bars in activity table)"
            sx={{ mb: 3 }}
          />
          <DataGrid
            rows={tableRows}
            autoHeight
            pageSizeOptions={[5, 50, 100]}
            columns={columns}
            loading={tableIsLoading}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            onRowClick={(event) => window.open(`/accounts/${event.row.apiKey}/${event.row.uniqueId}`, '_blank')}
            getRowId={(row) => row.uniqueId}
            getRowHeight={() => 'auto'}
            rowSelection={false}
          />
        </Card>
      </Grid>
    </>

  );
}
