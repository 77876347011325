/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Collapse,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
// import { Icon as IconifyIcon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { get, isString } from 'lodash';
import { StyledDotIcon, StyledIcon, StyledItem } from './NavStyles';
import Iconify from '../Iconify';

export default function NavItem({
  navItemInfo,
  primaryLocation,
  secondaryLocation,
}) {
  const {
    path, icon, title, children,
  } = navItemInfo;
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const isActive = primaryLocation === path;
  return (
    <>
      <StyledItem
        active={isActive}
        onClick={() => {
          if (children) {
            setIsOpen(!isOpen);
          } else {
            navigate(path);
          }
        }}
        depth={1}
      >
        <ListItemIcon>
          <Iconify sx={{ color: '#FFFFFF', fontSize: '30px' }} icon={icon} />
        </ListItemIcon>
        <ListItemText>
          <Typography sx={{ color: '#FFFFFF', fontFamily: 'branding', fontWeight: isActive ? 'bold' : 'regular' }}>
            {title}
          </Typography>
        </ListItemText>
        {children
          && (
            <ListItemIcon>
              <Iconify
                sx={{
                  ml: 1, flexShrink: 0, fontSize: '16px', color: '#FFFFFF',
                }}
                icon={isOpen ? 'carbon:chevron-down' : 'carbon:chevron-right'}
              />
            </ListItemIcon>
          )}
      </StyledItem>
      {children
        && (
          <Collapse in={isOpen} unmountOnExit>
            {children.map((child) => {
              let childLocationKeywords = [];
              let childPrimaryLocation = '';
              let childSecondaryLocation = '';
              let isActiveChild = false;
              if (isString(child.path)) {
                childLocationKeywords = child.path.split('/');
                childPrimaryLocation = get(childLocationKeywords, '[0]', '');
                childSecondaryLocation = get(childLocationKeywords, '[1]', '');
                if (childSecondaryLocation === secondaryLocation
                  && primaryLocation === childPrimaryLocation) {
                  isActiveChild = true;
                }
              }

              return (
                <StyledItem
                  key={child.title}
                  onClick={() => {
                    navigate(child.path);
                  }}
                  depth={2}
                  disabled={get(child, 'disabled', false)}
                >
                  <StyledIcon size={30}>
                    <StyledDotIcon active={isActiveChild} />
                  </StyledIcon>
                  <ListItemText>
                    <Typography sx={{
                      color: '#FFFFFF',
                      fontFamily: 'branding',
                      fontSize: '15px',
                      fontWeight: isActiveChild ? 'bold' : 'regular',
                    }}
                    >
                      {child.title}
                    </Typography>
                  </ListItemText>
                </StyledItem>
              );
            })}
          </Collapse>
        )}

    </>
  );
}
