/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { format } from 'date-fns';
import moment from 'moment';
import {
  Container, Card, Stack,
  ListItemText, TextField, InputAdornment,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, isArray } from 'lodash';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { useNavigate, useParams } from 'react-router-dom';

import Grid from '@mui/material/Unstable_Grid2';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import { protectedFetch } from '../../utils/network';
import Label from '../Label/Label';
import Iconify from '../Iconify';
import CustomBreadcrumbs from '../CustomBreadCrumbs/CustomBreadCrumbs';
import ResponseCodeLabel from '../ResponseCodeLabel/ResponseCodeLabel';
import { BLUR_KEYS_AND_TOKENS } from '../../utils/cssStyles';

const columns = [
  { field: 'supportId', headerName: 'Support ID', width: 250 },
  {
    field: 'apiCallId',
    headerName: 'API Call ID',
    flex: 3,
    valueGetter: (params) => get(params, 'row.apiResponse.apiCallId', ''),
  },
  { field: 'callType', headerName: 'Call Type', width: 250 },
  {
    field: 'responseCode',
    headerName: 'ResponseCode',
    width: 150,
    valueGetter: (params) => get(params, 'row.apiResponse.responseCode', ''),
    renderCell: (param) => <ResponseCodeLabel responseCode={get(param, 'row.apiResponse.responseCode', '')} />,
  },
  {
    field: 'message',
    headerName: 'Message',
    flex: 3,
    valueGetter: (params) => get(params, 'row.apiResponse.message', ''),
  },
  {
    field: 'createdAt',
    headerName: 'Time and Date',
    flex: 1,
    renderCell: (param) => (
      <ListItemText
        primary={format(new Date(get(param, 'value', '')), 'dd MMM yyyy')}
        secondary={format(new Date(get(param, 'value', '')), 'HH:mm:ss')}
        primaryTypographyProps={{ typography: 'body2', noWrap: true }}
        secondaryTypographyProps={{
          mt: 0.5,
          component: 'span',
          typography: 'caption',
        }}
      />
    ),
  },
  {
    field: 'searchString',
    headerName: 'Misc (keep hidden)',
    valueGetter: (params) => get(params, 'row.searchString', ''),
  },
];

export default function Calls() {
  const {
    apiKey, userId,
    // TODO: add ability to pass parameters
    // incomingStartDate, incomingEndDate,
  } = useParams();
  const [startDate, setStartDate] = useState(moment().subtract(1, 'day'));
  const [lastActive, setLastActive] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const navigate = useNavigate();
  const [calls, setCalls] = useState([]);
  const [filter, setFilter] = useState('');
  const [filt, setFilt] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  useEffect(() => {
    const url = (
      `/api/users/${apiKey}/${userId}/lastUpdated`
    );
    const lastActiveFetch = protectedFetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    lastActiveFetch.then((lastActiveInfo) => {
      if (lastActiveInfo !== null) {
        if (!lastActiveInfo.success) {
          handleSetGenericAlertDialogFromGenericResponse(lastActiveInfo, 'lastActiveFetch');
          return;
        }
        const formData = new FormData();
        const updatedAt = moment(get(lastActiveInfo, 'updatedAt', moment()));
        // const parsedIncomingEndDate = moment(incomingEndDate);
        // const parsedIncomingStartDate = moment(incomingStartDate);
        // TODO: bullet proof this, might be very easy to break with bad parameters
        // TODO: make absolutely sure that the format is utc
        // const incomingDatesAreValid = incomingEndDate && parsedIncomingEndDate.isValid()
        //   && incomingStartDate && parsedIncomingStartDate.isValid();
        // if (incomingDatesAreValid) {
        // formData.append('startTime',
        // parsedIncomingStartDate.clone().utc().format('YYYY-MM-DD HH:mm:ss')
        // );
        // formData.append('endTime',
        //  parsedIncomingEndDate.clone().utc().format('YYYY-MM-DD HH:mm:ss')
        // );
        // } else {
        formData.append('startTime', updatedAt.clone().subtract(24, 'hour').utc().format('YYYY-MM-DD HH:mm:ss'));
        formData.append('endTime', updatedAt.clone().utc().format('YYYY-MM-DD HH:mm:ss'));
        // }
        setLastActive(updatedAt);
        setStartDate(updatedAt.clone().subtract(24, 'hour').utc());
        setEndDate(updatedAt.clone().utc());

        const callsAndInfoFetch = protectedFetch(`/api/support_items/${apiKey}/${userId}/all`, {
          method: 'post',
          headers: {
            accept: 'application/json',
          },
          body: formData,
        });
        callsAndInfoFetch.then((response) => {
          setIsLoading(false);
          if (response !== null) {
            if (!response.success) {
              handleSetGenericAlertDialogFromGenericResponse(response, 'callsAndInfoFetch');
              return;
            }
            if (response.supportItems.length > 0) {
              setCalls(response.supportItems);
            }
          }
        });
      }
    });
  }, []);

  const handleChangeDate = async (newValue, dateKind) => {
    setIsLoading(true);
    let startDateToPass = startDate.clone().utc().format('YYYY-MM-DD HH:mm:ss');
    let endDateToPass = endDate.clone().utc().format('YYYY-MM-DD HH:mm:ss');
    if (dateKind === 'start') {
      setStartDate(newValue);
      startDateToPass = newValue.clone().utc().format('YYYY-MM-DD HH:mm:ss');
    } else {
      setEndDate(newValue);
      endDateToPass = newValue.clone().utc().format('YYYY-MM-DD HH:mm:ss');
    }
    try {
      const formData = new FormData();
      formData.append('startTime', startDateToPass);
      formData.append('endTime', endDateToPass);
      const response = await protectedFetch(`/api/support_items/${apiKey}/${userId}/all`, {
        method: 'post',
        headers: {
          accept: 'application/json',
        },
        body: formData,
      });
      if (!response.success) {
        handleSetGenericAlertDialogFromGenericResponse(response, 'handleChangeDate');
        return;
      }
      if (isArray(response.supportItems)) {
        setCalls(response.supportItems);
      } else {
        console.log('unexpected response', response);
      }
      // setUsersForTuning(get(response, 'Users', []));
      // handleSubmitPhrase({ header: 'Phrase Request Info', text: result });
    } catch (err) {
      console.log('handleChangeDate Exception: ', err);
      // handleSubmitPhrase({ header: 'Phrase Request Info', text: err.message });
    } finally {
      setIsLoading(false);
      // setShowFirstSection(false);
      // setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
      >
        <Grid md={12}>
          <CustomBreadcrumbs
            heading="User Activity"
            links={[
              {
                name: 'Accounts',
                href: '/accounts',
              },
              {
                name: `${apiKey}`,
                href: `/accounts/${apiKey}`,
                blur: BLUR_KEYS_AND_TOKENS,
              },
              { name: `${userId}` },
            ]}
            sx={{
              mb: { xs: 3, md: 3 },
            }}
          />
          <Card>
            <Stack
              spacing={2}
              alignItems={{ xs: 'flex-end', md: 'center' }}
              direction={{
                xs: 'column',
                md: 'row',
              }}
              sx={{
                p: 2,
              }}
            >
              <DesktopDateTimePicker
                sx={{ width: '100%' }}
                label="Start date"
                ampm={false}
                value={startDate}
                onChange={(newValue) => {
                  handleChangeDate(newValue, 'start');
                }}
              />

              <DesktopDateTimePicker
                sx={{ width: '100%' }}
                label="End date"
                ampm={false}
                value={endDate}
                onChange={(newValue) => {
                  handleChangeDate(newValue, 'end');
                }}
              />

              <TextField
                fullWidth
                value={filter}
                onChange={(event) => {
                  setFilter(event.target.value);
                  setFilt([
                    {
                      field: 'searchString',
                      operator: 'contains',
                      id: 95962,
                      value: event.target.value,
                      fromInput: ':r1j:',
                    },
                  ]);
                }}
                label="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  ),
                }}
              />

            </Stack>
            <DataGrid
              data-aos="fade-in"
              rows={calls}
              autoHeight
              loading={isLoading}
              columns={columns}
              getRowId={(row) => row.supportId}
              onRowClick={(event) => navigate(`/accounts/${apiKey}/${userId}/${event.id}`)}
              filterModel={{
                items: filt,
                logicOperator: 'and',
                quickFilterValues: [],
                quickFilterLogicOperator: 'and',
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    apiCallId: false,
                    searchString: false,
                  },
                },
              }}
            />

          </Card>
        </Grid>
      </Grid>
    </Container>

  );
}
