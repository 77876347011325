/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-uses-react */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import { createContext, useState } from 'react';

const GenericAlertDialogContext = createContext();

export const GenericAlertDialogProvider = ({ children }) => {
  const [showGenericAlertDialog, setShowGenericAlertDialog] = useState(false);
  const [genericAlertDialogTitle, setGenericAlertDialogTitle] = useState('');
  const [genericAlertDialogContent, setGenericAlertDialogContent] = useState('');
  const [genericAlertDialogAccent, setGenericAlertDialogAccent] = useState('');

  const handleSetShowGenericAlertDialog = (arg = null) => {
    if (arg != null) {
      setShowGenericAlertDialog(arg);
    }
  };

  const handleSetGenericAlertDialogTitle = (arg = null) => {
    if (arg != null) {
      setGenericAlertDialogTitle(arg);
    }
  };

  const handleSetGenericAlertDialogContent = (arg = null) => {
    if (arg != null) {
      setGenericAlertDialogContent(arg);
    }
  };

  const handleSetGenericAlertDialogAccent = (arg = null) => {
    if (arg != null) {
      setGenericAlertDialogAccent(arg);
    }
  };

  const handleSetGenericAlertDialogFromGenericResponse = (response = null, source = '') => {
    if (response != null) {
      setShowGenericAlertDialog(true);
      if (source !== '') {
        setGenericAlertDialogTitle(response.success ? `Success (${source})` : `Fail (${source})`);
      } else {
        setGenericAlertDialogTitle(response.success ? 'Success' : 'Fail');
      }
      setGenericAlertDialogContent(response.message);
      setGenericAlertDialogAccent(response.success ? 'primary' : 'error');
    }
  };

  return (
    <GenericAlertDialogContext.Provider value={{
      showGenericAlertDialog,
      genericAlertDialogTitle,
      genericAlertDialogContent,
      genericAlertDialogAccent,
      handleSetShowGenericAlertDialog,
      handleSetGenericAlertDialogTitle,
      handleSetGenericAlertDialogContent,
      handleSetGenericAlertDialogAccent,
      handleSetGenericAlertDialogFromGenericResponse,
    }}
    >
      {children}
    </GenericAlertDialogContext.Provider>
  );
};

export default GenericAlertDialogContext;
