/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  Container, Paper, Stack, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

// import { Icon as IconifyIcon } from '@iconify/react';
import Grid from '@mui/material/Unstable_Grid2';
import CustomBreadcrumbs from '../CustomBreadCrumbs/CustomBreadCrumbs';
import { responsiveFontSizes } from '../../utils/getFontValue';
import settingsCover from '../../assets/images/SettingsCover.png';
import accountsCover from '../../assets/images/AccountsCover.png';
import rejectionCover from '../../assets/images/FrrCover.png';
import fineTuningCover from '../../assets/images/FineTuningCover.png';
import phraseCover from '../../assets/images/PhraseManagerCover.png';
import Image from '../Image';

const TutorialInfoCards = [
  {
    title: 'Accessing Dev Settings',
    duration: '1 min',
    coverImg: settingsCover,
    description: 'Learn how to view your API key and token, change your password, or regenerate your API Token',
    internalLink: '/developer_settings_tutorial',
  },
  {
    title: 'Navigating Accounts',
    duration: '2 mins',
    coverImg: accountsCover,
    description: 'Learn how to go through your users, groups, and review detailed activity on your account',
    internalLink: '/accounts_tutorial',
  },
  {
    title: 'Rejection Report',
    duration: '2 mins',
    coverImg: rejectionCover,
    description: 'Learn how to generate a report on successful vs unsuccessful verification attempts of your users',
    internalLink: '/rejection_rate_report_tutorial',
  },
  {
    title: 'Fine Tuning',
    duration: '7 mins',
    coverImg: fineTuningCover,
    description: 'Learn how to mark users for fine tuning, and to calibrate voice engine performance',
    internalLink: '/fine_tuning_tutorial',
  },
  {
    title: 'Phrase Manager',
    duration: '1 min',
    coverImg: phraseCover,
    description: 'Learn how to add phrases, delete phrases, request new phrases',
    internalLink: '/phrase_manager_tutorial',
  },
];

export function TutorialItem({ post, index }) {
  const {
    title, duration, coverImg,
    description, targetLink,
    internalLink,
  } = post;
  const navigate = useNavigate();

  return (
    <Paper
      data-aos="fade-right"
      data-aos-delay={index * 100}
      variant="outlined"
      sx={{
        borderRadius: 2,
        overflow: 'hidden',
        ':hover': {
          boxShadow: 20, // theme.shadows[20]
        },
      }}
      onClick={() => {
        if (internalLink) {
          navigate(internalLink);
        } else {
          window.open(targetLink, '_blank');
        }
      }}
    >
      <Image
        src={coverImg}
        alt={title}
        ratio="16/9"
      />
      <Stack direction="row" spacing={3} sx={{ p: 3, height: '140px' }}>

        <Stack spacing={1} justifyContent="space-between" flexGrow={1}>
          <Typography
            variant="h6"
            sx={{
              ...responsiveFontSizes({
                xs: 26, sm: 26, md: 26, lg: 30,
              }),
              color: '#000000',
            }}
          >
            {title}
          </Typography>

          <Typography
            variant="body2"
            persistent
          >
            {description}
          </Typography>
        </Stack>

      </Stack>
      <Stack
        direction="row"
        spacing={3}
        sx={{ px: 3, pb: 3, pt: 0 }}
      >
        <Stack spacing={1.5} direction="row" alignItems="center">
          <Stack>
            <Typography variant="caption" sx={{ color: '#054A91' }}>
              {duration}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

    </Paper>
  );
}

export default function Tutorials() {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
      >
        <Grid md={12} sx={{}}>
          <CustomBreadcrumbs
            data-aos="fade-right"
            heading="Tutorials"
            links={[
              {
                name: 'How to use Dashboard tools in detail',
              },
            ]}
            sx={{
              mb: { xs: 3, md: 3 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              display: 'grid',
              rowGap: { xs: 4, md: 5 },
              columnGap: 4,
              pr: { xs: 0, lg: 1.5 },
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(3, 1fr)',
              },
            }}
          >
            {TutorialInfoCards.map((card, index) => (
              <TutorialItem
                post={card}
                index={index}
                key={card.title}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
