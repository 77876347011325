import { get } from 'lodash';
import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function getDirection(value = 'bottom') {
  return {
    top: 'to top',
    right: 'to right',
    bottom: 'to bottom',
    left: 'to left',
  }[value];
}

// for screen recordings and demos to hide sensitive info
export const BLUR_KEYS_AND_TOKENS = false;

export const blurSettingsForTables = {
  '& .blur-info': {
    '& .MuiDataGrid-cellContent': {
      filter: 'blur(3px)',
    },
  },
};

export default function cssStyles(theme) {
  return {
    bgBlur: (props) => {
      const color = get(props, 'color', false) || get(theme, 'palette.background.default', false) || '#000000';

      const blur = get(props, 'blur', false) || 6;
      const opacity = get(props, 'opacity', false) || 0.8;

      return {
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: `blur(${blur}px)`, // Fix on Mobile
        backgroundColor: alpha(color, opacity),
      };
    },
    bgGradient: (props) => {
      const direction = getDirection(get(props, 'direction', false));
      const startColor = get(props, 'startColor', false) || `${alpha('#000000', 0)} 0%`;
      const endColor = get(props, 'endColor', false) || '#000000 75%';

      return {
        background: `linear-gradient(${direction}, ${startColor}, ${endColor});`,
      };
    },
    bgImage: (props) => {
      const url = get(props, 'url', false) || 'https://zone-assets-api.vercel.app/assets/bg_gradient.jpg';
      const direction = getDirection(get(props, 'direction', false));
      const startColor = get(props, 'startColor', false) || alpha('#000000', 0.88);
      const endColor = get(props, 'endColor', false) || alpha('#000000', 0.88);

      return {
        background: `linear-gradient(${direction}, ${startColor}, ${endColor}), url(${url})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      };
    },
  };
}
