/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';
import { format } from 'date-fns';
import {
  Container,
  Card,
  Stack,
  Typography,
  CardHeader,
  Slider,
  CardActions,
  ButtonBase,
} from '@mui/material';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, isArray } from 'lodash';

import Grid from '@mui/material/Unstable_Grid2';
import moment from 'moment';
import { protectedFetch } from '../../utils/network';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import CustomBreadcrumbs from '../CustomBreadCrumbs/CustomBreadCrumbs';
import Iconify from '../Iconify';

export default function SupportAccess() {
  const [hours, setHours] = React.useState(18);
  const {
    handleSetGenericAlertDialogFromGenericResponse,
    handleSetShowGenericAlertDialog,
    handleSetGenericAlertDialogTitle,
    handleSetGenericAlertDialogContent,
    handleSetGenericAlertDialogAccent,
  } = useContext(GenericAlertDialogContext);

  const createSupportDeveloper = async (apiKey) => {
    try {
      const supportAccessFetch = await protectedFetch(`/api/support_access/${hours}`, {
        method: 'post',
        headers: {
          accept: 'application/json',
        },
      });
      if (!supportAccessFetch.success) {
        handleSetGenericAlertDialogFromGenericResponse(supportAccessFetch);
      } else {
        console.log('supportAccessFetch.expiration: ', supportAccessFetch.expiration);
        handleSetGenericAlertDialogTitle(supportAccessFetch.message);
        handleSetGenericAlertDialogContent(
          <Typography variant="body2">
            Temporary support admin account created
            with username:
            {' '}
            <code>{supportAccessFetch.username}</code>
            {' '}
            and password:
            {' '}
            <code>{supportAccessFetch.password}</code>
            {' '}
            which will be deleted at
            {' '}
            {moment.unix(supportAccessFetch.expiration).format('h:mm:ss a')}
            {' on '}
            {moment.unix(supportAccessFetch.expiration).format('MMMM Do YYYY')}
            {'. '}
            Please pass these credentials to VoiceIt Support via email or
            Slack along with a publically accessible
            endpoint to this Dashboard.
          </Typography>,
        );
        handleSetGenericAlertDialogAccent('primary');
        handleSetShowGenericAlertDialog(true);
      }
    } catch (err) {
      console.log('createSupportDeveloper catch Exception: ', err);
      // handleSubmitPhrase({ header: 'Phrase Request Info', text: err.message });
    }
  };

  const handleSliderChange = (event, newValue) => {
    setHours(newValue);
  };

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
      >
        <Grid xs={12}>
          <CustomBreadcrumbs
            heading="VoiceIt Support Remote Access"
            links={[
              {
                name: 'Grant temporary access to a tech to your system',
              },
            ]}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <Card sx={{ p: 3 }}>
            <CardHeader title="Grant Remote Access" sx={{ p: 0, mb: 2 }} />

            <Stack>
              <Stack direction="column" justifyContent="space-between">
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Please use this feature to grant a VoiceIt employee temporary access to
                  your system. This can be useful when there are issues that a require a trained
                  set of eyes to look over your activity or settings, such as unusually high
                  rejection rates. This developer account will
                  be deleted after the specified period.
                </Typography>

                <Typography variant="body2">By clicking &quot;Agree,&quot; you consent to VoiceIt Support Services gaining temporary system access to facilitate support. During this temporary access, VoiceIt will not retain copies of any of your confidential data, including Personal Identification Information (PII). Additionally, any data or information collected or used on VoiceIt&apos;s systems will be destroyed upon the expiration of access.</Typography>
              </Stack>

            </Stack>
            <Typography variant="h6" sx={{ mt: 2, color: '#000000' }}>Access Length (hours): </Typography>
            <Slider
              aria-label="Hours"
              defaultValue={18}
              sx={{ mt: 4 }}
              // getAriaValueText={valuetext}
              value={typeof hours === 'number' ? hours : 0}
              onChange={handleSliderChange}
              valueLabelDisplay="on"
              step={1}
              marks
              min={12}
              max={24}
            />
            <CardActions sx={{ p: 0, justifyContent: 'flex-end' }}>
              <ButtonBase
                sx={{
                  pl: 1,
                  py: 0.5,
                  pr: 0.5,
                  borderRadius: 1,
                  typography: 'subtitle2',
                  color: '#EFEFEF',
                  bgcolor: '#505050',
                }}
                onClick={createSupportDeveloper}
              >
                Agree
                <Iconify
                  icon="carbon:checkmark-outline"
                  sx={{ mx: 0.5, width: 20, height: 20 }}
                />
              </ButtonBase>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>

  );
}
