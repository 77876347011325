/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  Button, Typography, Stack,
} from '@mui/material';
// import { useSelector } from 'react-redux';
import { responsiveFontSizes } from '../../utils/getFontValue';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';

export default function GenericAlertsModal() {
  // const expiredToken = useSelector((state) => state.errorReducer.expiredToken);

  const {
    showGenericAlertDialog,
    genericAlertDialogTitle,
    genericAlertDialogContent,
    genericAlertDialogAccent,
    handleSetShowGenericAlertDialog,
  } = useContext(GenericAlertDialogContext);

  return (
    <Dialog
      open={showGenericAlertDialog}
      PaperProps={{ variant: 'outlined', elevation: 0, sx: { border: 5, borderColor: `${genericAlertDialogAccent}.light` } }}
    >
      <DialogTitle
        sx={{
          color: `${genericAlertDialogAccent}.main`,
          textAlign: 'center',
          fontSize: responsiveFontSizes({ md: 24 }),
          borderBottom: 1,
          borderColor: `${genericAlertDialogAccent}.light`,
          paddingBottom: 3,
        }}
      >
        {genericAlertDialogTitle}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2 }}>
          <Typography variant="body2">
            {genericAlertDialogContent}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            handleSetShowGenericAlertDialog(false);
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
