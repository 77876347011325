/* eslint-disable no-empty */
import React, { useEffect, useState, useContext } from 'react';
import {
  Container, Card, CardHeader, ButtonBase,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, isArray } from 'lodash';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Unstable_Grid2';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import { protectedFetch } from '../../utils/network';
import formatBytes from '../../utils/math';
import Iconify from '../Iconify';

// Ideally the name of the file should be inserted on the onclick event in the datagrid,
// but its not, so this is an inefficient solution that works for now
// TODO: find a better way to find the name of the file, currently its O(N), where N is
// the length of the file array
function getFileNameFromLink(fileArray, link) {
  let fileName = 'undefinedName';
  fileArray.some((file) => {
    if (get(file, 'link', '') === link) {
      fileName = file.filename;
      return true;
    }
    return false;
  });
  return fileName;
}

// TODO lul fix this too Roo
function getDownloadsRemainingFromLink(fileArray, link) {
  let downloadsRemaining = 0;
  fileArray.some((file) => {
    if (get(file, 'link', '') === link) {
      downloadsRemaining = file.downloadsremaining;
      return true;
    }
    return false;
  });
  return downloadsRemaining;
}

export default function Files() {
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [
    isSuperUser,
  ] = useState(useSelector((state) => state.loginReducer.isSuperUser));

  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  useEffect(() => {
    const url = (
      '/api/get_files'
    );
    const filesFetch = protectedFetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    filesFetch.then((filesInfo) => {
      if (filesInfo !== null) {
        if (!filesInfo.success) {
          handleSetGenericAlertDialogFromGenericResponse(filesInfo);
          return;
        }
        if (isArray(filesInfo.files)) {
          setFiles(filesInfo.files);
        }
      }
    });
  }, []);

  const columns = [
    { field: 'filename', headerName: 'File Name', flex: 2 },
    { field: 'description', headerName: 'Description', flex: 2 },
    {
      field: 'filesize',
      headerName: 'File Size',
      flex: 2,
      valueFormatter: (params) => {
        const valueExists = get(params, 'value', '');
        if (!valueExists) {
          return '';
        }
        return formatBytes(valueExists);
      },
    },
    { field: 'downloadsremaining', headerName: 'Downloads Remaining', flex: 1 },
  ];

  const handleDownload = () => {
    const timestamp = new Date().getTime();
    const anchorElements = [];
    // Create anchor elements and trigger download for each file
    const newFiles = files;
    selectedFiles.forEach((fileLink, index) => {
      if (getDownloadsRemainingFromLink(files, fileLink) === 0) {
        handleSetGenericAlertDialogFromGenericResponse({ success: false, message: 'No more downloads remaining for one of the files you are trying to download' });
        return;
      }

      if (!isSuperUser) {
        newFiles.forEach((file, newFilesIndex) => {
          if (file.link === fileLink) {
            newFiles[newFilesIndex].downloadsremaining -= 1;
          }
        });
      }

      const link = document.createElement('a');
      link.href = fileLink;
      link.download = getFileNameFromLink(files, fileLink);
      link.target = '_blank'; // Open in a new tab
      // Remove the created anchor element from the DOM
      document.body.appendChild(link);
      // Trigger the download
      while (new Date().getTime() < timestamp + index * 1000) { }
      link.click();

      // Push the anchor element to the array for later removal
      anchorElements.push(link);
    });

    setTimeout(() => {
      setFiles(newFiles);
      anchorElements.forEach((element) => {
        document.body.removeChild(element);
      });
    }, 3000);
  };

  return (
    <Container maxWidth="xl">
      <Grid
        data-aos="fade-right"
        container
        spacing={3}
      >
        <Grid md={12} sx={{}}>
          <Card>
            <CardHeader
              title="Files"
              subheader="Files that were shared with you to download"
              sx={{ mb: 3 }}
              action={(
                <ButtonBase
                  sx={{
                    pl: 1,
                    py: 0.5,
                    pr: 1,
                    borderRadius: 1,
                    // fontFamily: 'Branding',
                    typography: 'body2',
                    color: '#EFEFEF',
                    bgcolor: '#000000',
                  }}
                  onClick={handleDownload}
                >
                  Download (
                  {selectedFiles.length}
                  {' '}
                  files selected)
                  <Iconify icon="carbon:download" sx={{ ml: 0.5 }} />
                </ButtonBase>
              )}
            />
            <DataGrid
              rows={files}
              columns={columns}
              getRowId={(row) => row.link}
              checkboxSelection
              onRowSelectionModelChange={(selectedInfo) => {
                setSelectedFiles(selectedInfo);
              }}
            />

          </Card>
        </Grid>
      </Grid>
    </Container>

  );
}
