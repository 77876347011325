/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from 'react';
import {
  Card,
  Container, ListItemText,
} from '@mui/material';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get } from 'lodash';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
// import { useSelector } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2';
import { protectedFetch } from '../../utils/network';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import CustomBreadcrumbs from '../CustomBreadCrumbs/CustomBreadCrumbs';

const columns = [
  {
    field: 'Time',
    headerName: 'Time and Date',
    flex: 1,
    renderCell: (param) => (
      <ListItemText
        primary={format(new Date(get(param, 'value', '')), 'dd MMM yyyy')}
        secondary={format(new Date(get(param, 'value', '')), 'HH:mm:ss:SSS')}
        primaryTypographyProps={{ typography: 'body2', noWrap: true }}
        secondaryTypographyProps={{
          mt: 0.5,
          component: 'span',
          typography: 'caption',
        }}
      />
    ),
  },
  {
    field: 'Message',
    headerName: 'Message',
    flex: 3,
  },
];

export default function LogDetails() {
  const {
    apiKey, userId, supportId, logType,
  } = useParams();
  // const isSuperUser = useSelector((state) => state.loginReducer.isSuperUser);
  const [data, setData] = useState([]);
  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);
  useEffect(() => {
    if (logType === 'api2') {
      const url = (
        `/api/api2_log_details/${supportId}`
      );
      const logDetailFetch = protectedFetch(url, {
        method: 'get',
        headers: {
          accept: 'application/json',
        },
      });
      logDetailFetch.then((supportLog) => {
        if (supportLog !== null) {
          if (!supportLog.success) {
            handleSetGenericAlertDialogFromGenericResponse(supportLog, 'supportId');
            return;
          }
          setData(get(supportLog, 'internalLog.data', []));
        }
      });
    } else if (logType === 'current_fine_tuning' || logType === 'previous_fine_tuning') {
      const url = (
        `/api/fine_tuning_log_details/${supportId}`
      );
      const logDetailFetch = protectedFetch(url, {
        method: 'get',
        headers: {
          accept: 'application/json',
        },
      });
      logDetailFetch.then((supportLog) => {
        if (supportLog !== null) {
          if (!supportLog.success) {
            handleSetGenericAlertDialogFromGenericResponse(supportLog, 'supportId');
            return;
          }
          setData(get(supportLog, 'internalLog.data', []));
        }
      });
    } else {
      console.log(`invalid logType (${logType})`);
    }
  }, []);

  let links = {};
  if (logType === 'api2') {
    links = [
      {
        name: 'Accounts',
        href: '/accounts',
      },
      {
        name: `${apiKey}`,
        href: `/accounts/${apiKey}`,
      },
      {
        name: `${userId}`,
        href: `/accounts/${apiKey}/${userId}`,
      },
      {
        name: `${supportId}`,
        href: `/accounts/${apiKey}/${userId}/${supportId}`,
      },
      {
        name: 'Log Details',
      },
    ];
  } else if (logType === 'current_fine_tuning') {
    links = [
      {
        name: 'Current Fine Tuning Runs',
        href: '/fine_tuning/currently_running', // userId in the case of fine tuning routing will be vppId
      },
      {
        name: 'Log Details',
      },
    ];
  } else if (logType === 'previous_fine_tuning') {
    links = [
      {
        name: 'Previous Fine Tuning Results',
        href: '/fine_tuning/previous_results', // userId in the case of fine tuning routing will be vppId
      },
      {
        name: 'Log Details',
      },
    ];
  }

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
      >

        <Grid xs={12}>
          <CustomBreadcrumbs
            heading="Log Details"
            links={links}
            sx={{
              mb: { xs: 3, md: 3 },
            }}
          />

          <Card>
            <DataGrid
              rows={data}
              autoHeight
              columns={columns}
              getRowId={(row) => row.Id}
              getRowHeight={() => 'auto'}
              rowSelection={false}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>

  );
}
