/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
// @mui
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
// components
import Chart, { useChart } from '../chart';

// ----------------------------------------------------------------------

// export function fNumber(number) {
//   return numeral(number).format();
// }

export default function ActivityGraph({
  title, isLoading, categories, series, subheader, chart, onClickTable, ...other
}) {
  const chartOptions = useChart({
    chart: {
      stacked: true,
      events: {
        dataPointSelection(event, chartContext, config) {
          const responseCode = get(series, `[${get(config, 'seriesIndex', 0)}].name`, '');
          const call = get(categories, `[${get(config, 'dataPointIndex', 0)}]`, '');
          onClickTable({ responseCode, call });
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      // marker: { show: false },
      y: {
        formatter: (value) => (value),
        title: {
          // eslint-disable-next-line arrow-body-style
          formatter: (value) => {
            return value;
          },
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        barHeight: '28%',
        borderRadius: 2,
      },
    },
    xaxis: {
      categories,
    },
  });
  let boxStyles = {
    mt: 3,
    mx: 3,
  };
  if (isLoading) {
    boxStyles = {
      ...boxStyles,
      height: 500,
      display: 'flex',
      justifyContent: 'center',
    };
  }

  return (
    <Card
      data-aos="fade-in"
      {...other}
    >
      <CardHeader title={title} subheader={subheader} />

      <Box sx={boxStyles}>
        {isLoading
          ? (
            <CircularProgress
              size={100}
              sx={{ alignSelf: 'center' }}
            />
          )
          : (
            <Chart
              type="bar"
              dir="ltr"
              series={series}
              options={chartOptions}
              height={500}
            />
          )}
      </Box>
    </Card>
  );
}

ActivityGraph.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string,
};
