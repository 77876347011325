/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import {
  Container, Card, CardHeader, Stack,
  Divider, Button,
  TextField,
  Box,
  ButtonBase,
  Paper,
  Typography,
  Autocomplete,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, isArray } from 'lodash';

import Grid from '@mui/material/Unstable_Grid2';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import { protectedFetch } from '../../utils/network';
import Iconify from '../Iconify';
import { responsiveFontSizes } from '../../utils/getFontValue';
import { BLUR_KEYS_AND_TOKENS } from '../../utils/cssStyles';

export default function NewSession() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [apiKeys, setAPIKeys] = useState([]);
  const [selectedAPIKey, setSelectedAPIKey] = useState('');
  const [selectedPhrase, setSelectedPhrase] = useState('');
  const [phrasesAndLanguages, setPhrasesAndLanguages] = useState([]);
  const [selectedAPIKeyInputValue, setSelectedAPIKeyInputValue] = React.useState('');
  const [startDate, setStartDate] = useState(moment().subtract(24, 'hour'));
  const [endDate, setEndDate] = useState(moment());
  const [includeAudioContent, setIncludeAudioContent] = useState(true);
  const [includeVisualContent, setIncludeVisualContent] = useState(false);
  const [selectedPhraseInputValue, setSelectedPhraseInputValue] = useState('');
  const phraseDropDownLabelText = selectedAPIKey ? '' : 'Please select API Key first to be able to select phrase';

  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  const onSubmitHandler = (data) => {
    console.log({ data });
  };

  const handleAPIKeySelection = async (apiKey) => {
    try {
      const phrasesFetch = await protectedFetch(`/api/get_phrases_for_rejection_rate_report/${apiKey}`, {
        method: 'get',
        headers: {
          accept: 'application/json',
        },
      });
      if (!phrasesFetch.success) {
        handleSetGenericAlertDialogFromGenericResponse(phrasesFetch);
      } else {
        setPhrasesAndLanguages(phrasesFetch.availableContentLanguageAndPhrases);
      }
      // handleSubmitPhrase({ header: 'Phrase Request Info', text: result });
    } catch (err) {
      console.log('handleAPIKeySelection catch Exception: ', err);
      // handleSubmitPhrase({ header: 'Phrase Request Info', text: err.message });
    }
  };

  const handleSetSelectedPhrase = (phraseAndLanguage) => {
    setSelectedPhrase(phraseAndLanguage);
  };

  useEffect(() => {
    // TODO: check that all is not just for super users
    const url = (
      '/api/graphable_developers'
    );
    const apiKeysFetch = protectedFetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    apiKeysFetch.then((apiKeysInfo) => {
      if (apiKeysInfo !== null) {
        if (!apiKeysInfo.success) {
          handleSetGenericAlertDialogFromGenericResponse(apiKeysInfo);
          return;
        }
        if (isArray(apiKeysInfo.developers)) {
          setAPIKeys(apiKeysInfo.developers);
        }
      }
    });
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  return (
    <FormProvider onSubmit={onSubmitHandler}>

      <Container maxWidth="xl">
        <Grid
          container
          spacing={3}
        >
          <Grid md={7}>
            <Card
              data-aos="fade-right"
            >
              <CardHeader
                title="Rejection Rate Account Details"
                subheader="Enter account criteria for the report"
              // action={<CarouselArrows onNext={carousel.onNext} onPrev={carousel.onPrev} />}
              />
              <Divider sx={{ borderStyle: 'dashed' }} />

              <Stack direction="column" sx={{ mx: 3, mt: 2 }} spacing={2}>
                <Typography variant="h6" sx={{ color: '#000000' }}>Query Details</Typography>
                <Autocomplete
                  fullWidth
                  onChange={(event, newValue) => {
                    setSelectedAPIKey(newValue);
                    handleAPIKeySelection(get(newValue, 'value', ''));
                  }}
                  inputValue={selectedAPIKeyInputValue}
                  onInputChange={(event, newInputValue) => {
                    setSelectedAPIKeyInputValue(newInputValue);
                  }}
                  options={apiKeys}
                  autoHighlight
                  getOptionLabel={(option) => `${option.key} ${option.email}`}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{
                        color: '#000000',
                        filter: BLUR_KEYS_AND_TOKENS ? 'blur(3px)' : 'none',
                      }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                    >
                      {option.key}
                      <span style={{ marginLeft: '16px', color: '#505050' }}>
                        {` ${get(option, 'email', '')}`}
                      </span>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      label="API Key"
                      sx={{
                        filter: BLUR_KEYS_AND_TOKENS ? 'blur(3px)' : 'none',
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                <Stack direction="column" sx={{ mx: 3, mt: 2 }} spacing={2}>
                  <Autocomplete
                    fullWidth
                    disabled={!selectedAPIKey}
                    value={selectedPhrase}
                    onChange={(event, newValue) => {
                      handleSetSelectedPhrase(newValue);
                    }}
                    inputValue={selectedPhraseInputValue}
                    onInputChange={(event, newInputValue) => {
                      setSelectedPhraseInputValue(newInputValue);
                    }}
                    options={phrasesAndLanguages}
                    autoHighlight
                    getOptionLabel={(option) => (option ? `${option.vppText} ${option.contentLanguage}` : phraseDropDownLabelText)}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ color: '#000000' }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                      >
                        {option.vppText}
                        <span style={{ marginLeft: '16px', color: '#505050' }}>
                          {` ${get(option, 'contentLanguage', '')}`}
                        </span>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Phrase and Content Language"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <DateTimePicker
                    sx={{ width: '100%' }}
                    label="Start date"
                    disableFuture
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                  />
                  <DateTimePicker
                    sx={{ width: '100%' }}
                    label="End date"
                    disableFuture
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                  />
                </Stack>
                <Divider sx={{ borderStyle: 'dashed' }} />
                <Stack spacing={2}>
                  <Typography variant="h6" sx={{ color: '#000000' }}>Data Type</Typography>
                  <Box gap={2} display="grid" gridTemplateColumns="repeat(2, 1fr)">
                    <Paper
                      fullWidth
                      component={ButtonBase}
                      variant="outlined"
                      sx={{
                        p: 2.5,
                        borderRadius: 1,
                        typography: 'subtitle2',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        ...(includeAudioContent ? {
                          color: '#000000',
                          // borderWidth: 2,
                          borderColor: '#000000',
                        } : {
                          color: '#919EAB',
                          borderColor: '#919EAB',
                        }),
                      }}
                      onClick={() => setIncludeAudioContent(!includeAudioContent)}
                    >
                      <Stack
                        flexGrow={1}
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}
                        sx={{ mb: 1 }}
                      >
                        <Iconify
                          icon={includeAudioContent ? 'carbon:checkmark-filled' : 'carbon:checkmark-outline'}
                          sx={{ fontSize: '16px', justifySelf: 'end' }}
                        />
                        <Iconify icon="carbon:volume-up-filled" sx={{ fontSize: '16px' }} />
                        <Typography variant="h6" sx={{ ...responsiveFontSizes({ xs: 16 }) }}>Audio Content</Typography>
                      </Stack>
                      <Typography variant="body2" sx={{ ml: 3, textAlign: 'left', ...responsiveFontSizes({ xs: 14 }) }}>
                        Includes VIDEO and VOICE calls, identification and verification
                      </Typography>
                    </Paper>
                    <Paper
                      fullWidth
                      component={ButtonBase}
                      variant="outlined"
                      sx={{
                        p: 2.5,
                        borderRadius: 1,
                        typography: 'subtitle2',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        ...(includeVisualContent ? {
                          color: '#000000',
                          // borderWidth: 2,
                          borderColor: '#000000',
                        } : {
                          color: '#919EAB',
                          borderColor: '#919EAB',
                        }),
                      }}
                      onClick={() => setIncludeVisualContent(!includeVisualContent)}
                    >
                      <Stack
                        flexGrow={1}
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}
                        sx={{ mb: 1 }}
                      >
                        <Iconify
                          icon={includeVisualContent ? 'carbon:checkmark-filled' : 'carbon:checkmark-outline'}
                          sx={{ fontSize: '16px', justifySelf: 'end' }}
                        />
                        <Iconify icon="carbon:camera" sx={{ fontSize: '16px' }} />
                        <Typography variant="h6" sx={{ ...responsiveFontSizes({ xs: 16 }) }}>Visual Content</Typography>
                      </Stack>
                      <Typography variant="body2" sx={{ ml: 3, textAlign: 'left', ...responsiveFontSizes({ xs: 14 }) }}>
                        Includes VIDEO and FACE calls, identification and verification
                      </Typography>
                    </Paper>
                  </Box>
                </Stack>
              </Stack>

              <Stack
                spacing={2}
                sx={{
                  p: 3,
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    const startDateConvertedToSQLFormat = moment(startDate).toISOString();
                    const endDateConvertedToSQLFormat = moment(endDate).toISOString();
                    // TODO: just pass all of these in the state argument instead
                    navigate(
                      `/rejection_report/report/${selectedAPIKey.value}/${startDateConvertedToSQLFormat}/${endDateConvertedToSQLFormat}/${includeAudioContent}/${includeVisualContent}`,
                      {
                        state: {
                          contentLanguage: selectedPhrase.contentLanguage,
                          phrase: selectedPhrase.vppText,
                        },
                      },
                    );
                  }}
                >
                  Generate
                </Button>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </FormProvider>
  );
}
