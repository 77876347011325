/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Card, CardHeader, Stack, Typography,
} from '@mui/material';
import { get } from 'lodash';

import Grid from '@mui/material/Unstable_Grid2';
import { protectedFetch } from '../../utils/network';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';

// eslint-disable-next-line react/prop-types
export default function FacePreview({
  apiKey,
  supportId,
  dataType,
  shouldDisplay,
}) {
  if (!shouldDisplay) {
    return null;
  }
  const [isLoading, setIsLoading] = useState(true);
  const [base64ImageString, setBase64ImageString] = useState('');
  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);
  // TODO: add error handling
  useEffect(() => {
    const url = (
      `/api/support_photo/${apiKey}/${supportId}/${dataType}`
    );
    const supportFileFetch = protectedFetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    supportFileFetch.then((supportFile) => {
      if (supportFile !== null) {
        if (!supportFile.success) {
          handleSetGenericAlertDialogFromGenericResponse(supportFile, 'supportFileFetch');
          return;
        }
        setBase64ImageString(get(supportFile, 'data', ''));
        setIsLoading(false);
      }
    });
  }, []);
  return (
    <Grid md={6}>
      <Card sx={{ p: 3 }}>
        <CardHeader title={`Photo type: ${dataType}`} sx={{ p: 0, mb: 3 }} />
        <Box
          component="img"
          alt="User's Face"
          src={`data:image/jpeg;base64, ${base64ImageString}`}
          sx={{ width: '100%' }}
        />
      </Card>
    </Grid>
  );
}
