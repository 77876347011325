/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Avatar,
  Paper, Stack, Typography,
} from '@mui/material';
import Image from '../Image';

export default function TutorialStep({ step, stepNumber }) {
  const {
    title, stepInfo, stepImg,
  } = step;

  return (
    <Paper
      data-aos="fade-up"
      variant="outlined"
      sx={{
        borderRadius: 2,
        borderColor: '#FFF',
        overflow: 'hidden',
        ':hover': {
          boxShadow: 20, // theme.shadows[20]
        },
        p: 2,
      }}
    >
      <Stack direction="row" spacing={3} sx={{ pb: 2 }}>
        <Avatar
          sx={{
            bgcolor: '#75B09C',
            color: '#FFFFFF',
          }}
        >
          {stepNumber}
        </Avatar>
        <Typography>
          {stepInfo}
        </Typography>

      </Stack>
      {stepImg && (
        <Image
          src={stepImg}
          alt={title}
          ratio="16/9"
          sx={{
            borderRadius: 2,
          }}
        />
      )}

    </Paper>
  );
}
