/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  Container, Divider, Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
// import { Icon as IconifyIcon } from '@iconify/react';
import Player from '../../Player/Player';

import accessMenu from './AccessMenu.png';
import phraseList from './PhraseList.png';
import selectContentLanguage from './SelectContentLanguage.png';
import phaseListTable from './PhaseListTable.png';
import enterPhrase from './EnterPhrase.png';

import videoTutorial from './PhraseManager.mov';
import CustomBreadcrumbs from '../../CustomBreadCrumbs/CustomBreadCrumbs';
import TutorialStep from '../TutorialStep';

const steps = [
  {
    title: 'Access the Menu',
    stepImg: accessMenu,
    stepInfo: 'To Access the Phrase Manager Menu, click the corresponding section in the left hand menu',
  },
  {
    title: 'Request Phrase',
    stepImg: selectContentLanguage,
    stepInfo: ' To request a new phrase, go to phrase management, enter a phrase you would like to use and select the content language. Note that custom phrases that work well are hard to construct, so rather than coming up with a new one, look through the ones that are already available (Step 4)',
  },
  {
    title: 'Good Phrase',
    stepInfo:
      // eslint-disable-next-line react/jsx-indent
      <>
        <Typography>For a custom phrase to be good it has to:</Typography>
        <ol>
          <li>Be easy to memorize. A lot of users struggle with saying the correct phrase and often change words to synonyms</li>
          <li>Have a larger number of phonemes. The more unique sounds in the phrase the better</li>
          <li>No repeating</li>
          <li>And lastly, “my voice is my password” is a common choice but not a great phrase because it has been modeled too much and is therefore insecure</li>
        </ol>
      </>,
  },
  {
    title: 'Phrase List',
    stepImg: phaseListTable,
    stepInfo: 'To see the phrases already available to you, click on phrase list on the left hand menu. This list will show all the phrases that are already under your account',
  },
];

export default function PhraseManagerTutorial() {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
      >
        <Grid md={12} sx={{}}>
          <CustomBreadcrumbs
            heading="Phrase Manager Tutorial"
            links={[
              {
                name: 'Tutorials',
                href: '/tutorials',
              },
              {
                name: 'Phrase Manager',
              },
            ]}
            sx={{
              mb: { xs: 3, md: 3 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Player
            url={videoTutorial}
            width="100%"
            height="100%"
            controls
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 6 },
          pb: { xs: 6, md: 6, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={3}
            justifyContent={{ md: 'center' }}
          >

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                How to manage phrases under your account
              </Typography>
            </Grid>

            {steps.map((step, index) => (
              <Grid
                item
                xs={12}
                md={8}
                key={step.title}
              >
                <TutorialStep
                  step={step}
                  stepNumber={index + 1}
                />
                <Divider sx={{ pt: 2.5, borderStyle: 'dashed' }} />

              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Container>
  );
}
