/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  Button, Typography, Stack, TextField, InputAdornment,
  IconButton,
} from '@mui/material';
import viewIcon from '@iconify/icons-carbon/view';
import viewOff from '@iconify/icons-carbon/view-off';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { LoadingButton } from '@mui/lab';
import Iconify from '../Iconify';
import { protectedFetch } from '../../utils/network';
import { responsiveFontSizes } from '../../utils/getFontValue';
import { setExpiredToken } from '../../reduxStore/actions/errorActions';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';

export default function ReducerTriggeredAlertDialogs() {
  const expiredToken = useSelector((state) => state.errorReducer.expiredToken);
  const [
    passwordResetNeeded, setPasswordResetNeeded,
  ] = useState(useSelector((state) => state.loginReducer.passwordResetNeeded));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoadingUpdatePassword, setIsLoadingUpdatePassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const {
    handleSetShowGenericAlertDialog,
    handleSetGenericAlertDialogTitle,
    handleSetGenericAlertDialogContent,
  } = useContext(GenericAlertDialogContext);

  const {
    reset,
    control,
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      newPassword: '',
      newPasswordConfirmation: '',
    },
  });

  const onNewPasswordSubmitHandler = async (data) => {
    const response = await protectedFetch('/api/set_new_password', {
      method: 'post',
      headers: {
        accept: 'application/json',
      },
      body: data.newPassword,
    });

    handleSetShowGenericAlertDialog(true);

    if (response.success) {
      setPasswordResetNeeded(false);
      handleSetGenericAlertDialogTitle('Success');
      handleSetGenericAlertDialogContent(`${response.message}. Logging out in 3 seconds.`);
      setTimeout(() => {
        fetch('/api/logout', {
          method: 'post',
          mode: 'cors',
          credentials: 'include',
        });
        window.localStorage.clear();
        navigate('login');
      }, 3000);
    } else {
      handleSetGenericAlertDialogTitle('Fail');
      handleSetGenericAlertDialogContent(`${response.message}.`);
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowPasswordConfirmation = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };

  return (
    <>

      <Dialog open={expiredToken}>
        <DialogTitle
          sx={{ color: '#000000', textAlign: 'center', fontSize: responsiveFontSizes({ md: 24 }) }}
        >
          Unauthorized
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <Typography variant="body2">
              You are either not logged in or your session expired
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            endIcon={<Iconify icon="carbon:login" />}
            variant="contained"
            onClick={() => {
              dispatch(setExpiredToken(false));
              navigate('/login');
            }}
          >
            Go to Login
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={passwordResetNeeded && !expiredToken}>
        <DialogTitle
          sx={{ color: '#000000', textAlign: 'center', fontSize: responsiveFontSizes({ md: 24 }) }}
        >
          Password Reset Required
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <Typography variant="body2">
              You need to set a new secure password.
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>

          <form
            onSubmit={handleSubmit(onNewPasswordSubmitHandler)}
          >
            <Stack spacing={2.5} sx={{ alignItems: 'center' }}>
              <Controller
                name="newPassword"
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}":;'<>?,./])(?=.{32,})/,
                    message: 'Please choose strong password that is at least 32 characters long, contains at least one upper case letter, contains one lower case letter, contains one number, and contains one special character',
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Iconify icon={showPassword ? viewIcon : viewOff} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(error)}
                    helperText={get(error, 'message', undefined)}
                  />
                )}
              />

              <Controller
                name="newPasswordConfirmation"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => value === watch('newPassword') || 'Passwords do not match',
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Confirm New Password"
                    type={showPasswordConfirmation ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPasswordConfirmation} edge="end">
                            <Iconify icon={showPasswordConfirmation ? viewIcon : viewOff} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(error)}
                    helperText={get(error, 'message', undefined)}
                  />
                )}
              />

              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                endIcon={<Iconify icon="carbon:save" />}
                loading={isLoadingUpdatePassword}
                sx={{
                  width: '100%',
                  mx: { xs: 'auto !important', md: 'unset !important' },
                  backgroundColor: '#000000',
                }}
              >
                Save
              </LoadingButton>
            </Stack>
          </form>
        </DialogActions>
      </Dialog>
    </>
  );
}
