/* eslint-disable no-unused-vars */
/* eslint-disable no-trailing-spaces */
import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  Box, Card, CardHeader, Stack,
  Typography,
} from '@mui/material';
import { get } from 'lodash';

import Grid from '@mui/material/Unstable_Grid2';
import { protectedFetch } from '../../utils/network';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';

import Iconify from '../Iconify';
import { BLUR_KEYS_AND_TOKENS } from '../../utils/cssStyles';

// eslint-disable-next-line react/prop-types
export default function DeveloperInfo({ apiKey }) {
  const isSuperUser = useSelector((state) => state.loginReducer.isSuperUser);
  const pcmEnabled = useSelector((state) => state.loginReducer.pcmEnabled);
  if (!isSuperUser) {
    return null;
  }
  const [data, setData] = useState({});
  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  useEffect(() => {
    const url = (
      `/api/developers/info/${apiKey}`
    );
    const devInfoFetch = protectedFetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    devInfoFetch.then((res) => {
      if (res !== null) {
        if (!res.success) {
          handleSetGenericAlertDialogFromGenericResponse(res, 'devInfoFetch');
          return;
        }
        setData(res.developer);
      }
    });
  }, []);
  return (
    <Grid
      md={4}
    >
      <Card sx={{ p: 3 }}>
        <CardHeader title="Account Info" sx={{ p: 0, mb: 3 }} />
        <Box>
          <Typography variant="h6" gutterBottom sx={{ color: '#505050' }}>
            {`${get(data, 'FirstName', '')} ${get(data, 'LastName', '')}`}
          </Typography>
        </Box>

        <Stack>
          {!pcmEnabled
            && (
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Email
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ filter: BLUR_KEYS_AND_TOKENS ? 'blur(3px)' : 'none' }}
                >
                  {get(data, 'Email', 'NA')}
                </Typography>
              </Stack>
            )}
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Email Verified
            </Typography>
            <Iconify
              width={16}
              icon={get(data, 'EmailVerified', false) ? 'carbon:checkmark-filled' : 'carbon:error-filled'}
              sx={{ color: get(data, 'EmailVerified', false) ? '#75B09C' : '#F17300' }}
            />
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Account Enabled
            </Typography>
            <Iconify
              width={16}
              icon={get(data, 'Enabled', false) ? 'carbon:checkmark-filled' : 'carbon:error-filled'}
              sx={{ color: get(data, 'Enabled', false) ? '#75B09C' : '#F17300' }}
            />
          </Stack>

        </Stack>
      </Card>
    </Grid>

  );
}
