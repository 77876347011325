import React from 'react';

import {
  Outlet, useNavigate,
} from 'react-router-dom';
import { useTheme, alpha } from '@mui/material/styles';
import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
} from '@mui/material';
import NavBar from '../NavBar/NavBar';
import SettingsButton from '../SettingsButton/SettingsButton';
import ReducerTriggeredAlertDialogs from './ReducerTriggeredAlertDialogs';
import GenericAlertsDialog from './GenericAlertsDialog';
import Iconify from '../Iconify';

export default function LayoutWithNavigation() {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <>
      <AppBar
        sx={{
          height: 80,
          width: `calc(100% - ${280 + 1}px)`,
          zIndex: 1,
          backdropFilter: 'blur(6px)',
          WebkitBackdropFilter: 'blur(6px)',
          backgroundColor: alpha('#FFFFFF', 0.8),
          transition: theme.transitions.create(['height'], {
            duration: theme.transitions.duration.shorter,
          }),
        }}
      >
        <Toolbar
          sx={{
            height: 1,
            px: { lg: 5 },
          }}
        >
          <Stack
            flexGrow={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={{ xs: 0.5, sm: 1 }}
          >
            <IconButton
              // component={m.button}
              whileTap="tap"
              whileHover="hover"
              // variants={varHover(1.05)}
              // aria-label="settings"
              // aria-describedby={id}
              // onClick={handleClick}
              onClick={() => {
                navigate('/tutorials');
              }}
              sx={{
                width: 40,
                height: 40,
              }}
            >
              <Iconify icon="carbon:help" width={24} sx={{ color: '#000000' }} />
            </IconButton>
            <SettingsButton />
          </Stack>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
        }}
      >
        <NavBar />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            minHeight: 1,
            display: 'flex',
            flexDirection: 'column',
            py: `${80 + 32}px`,
            px: 3,
            width: 'calc(100% - 280px)',
            backgroundColor: '#EFEFEF',
            height: '100%',

          }}
        >
          <Outlet />
        </Box>
      </Box>
      <ReducerTriggeredAlertDialogs />
      <GenericAlertsDialog />
    </>
  );
}
