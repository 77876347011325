/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-uses-react */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import { createContext, useState } from 'react';
import moment from 'moment';

const FineTuningContext = createContext();

export const FineTuningProvider = ({ children }) => {
  const [selectedAccount, setSelectedAccount] = useState('');
  // const [selectedAccountInputValue, setSelectedAccountInputValue] = useState('');

  const [selectedPhrase, setSelectedPhrase] = useState({
    vsitCustomPhrasesId: 0,
    contentLanguage: '',
    vppText: '',
    enrollmentCount: 0,
  });

  // const [selectedPhraseInputValue, setSelectedPhraseInputValue] = React.useState('');
  const [accounts, setAccounts] = useState([]);
  const [accountsAlreadyRetrieved, setAccountsAlreadyRetrieved] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month'));
  const [findButtonHit, setFindButtonHit] = useState(false);

  const handleSetSelectedAccount = (arg = null) => {
    if (arg != null) {
      setSelectedAccount(arg);
    }
  };

  /*
  const handleSetSelectedAccountInputValue = (arg = null) => {
    if (arg != null) {
      setSelectedAccountInputValue(arg);
    }
  };
  */

  const handleSetSelectedPhrase = (arg = null) => {
    if (arg != null) {
      setSelectedPhrase(arg);
    }
  };

  /*
  const handleSetSelectedPhraseInputValue = (arg = null) => {
    if (arg != null) {
      setSelectedPhraseInputValue(arg);
    }
  };
  */

  const handleSetAccounts = (arg = null) => {
    if (arg != null) {
      setAccounts(arg);
    }
  };

  const handleSetAccountsAlreadyRetrieved = (arg = null) => {
    if (arg != null) {
      setAccountsAlreadyRetrieved(arg);
    }
  };

  const handleSetStartDate = (arg = null) => {
    if (arg != null) {
      setStartDate(arg);
    }
  };

  const handleSetFindButtonHit = (arg = null) => {
    if (arg != null) {
      setFindButtonHit(arg);
    }
  };

  return (
    <FineTuningContext.Provider value={{
      selectedAccount,
      // selectedAccountInputValue,
      selectedPhrase,
      accounts,
      accountsAlreadyRetrieved,
      startDate,
      findButtonHit,
      handleSetSelectedAccount,
      // handleSetSelectedAccountInputValue,
      handleSetSelectedPhrase,
      handleSetAccounts,
      handleSetAccountsAlreadyRetrieved,
      handleSetStartDate,
      handleSetFindButtonHit,
    }}
    >
      { children }
    </FineTuningContext.Provider>
  );
};

export default FineTuningContext;
