/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState, useContext } from 'react';
import {
  Box,
  Button,
  ButtonBase,
  Card, CardActions, CardHeader, CircularProgress, Stack,
  Typography,
  Paper,
  MobileStepper,
  IconButton,
} from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { get, isArray } from 'lodash';
import Grid from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';
import Wave from './Wave';
import Iconify from '../Iconify';
import { protectedFetch } from '../../utils/network';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';

export default function Enrollments({
  apiKey,
  supportId,
  userId,
  shouldDisplay,
}) {
  // console.log(shouldDisplay);
  if (!shouldDisplay) {
    return null;
  }
  const [isLoading, setIsLoading] = useState(true);
  const [enrollments, setEnrollments] = useState([]);
  const maxSteps = enrollments.length;
  const [selectedEnrollmentIndex, setSelectedEnrollmentIndex] = useState(0);
  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);
  useEffect(() => {
    const url = (
      `/api/support/enrollments_used/${apiKey}/${userId}/${supportId}`
    );
    const enrollmentsInfoFetch = protectedFetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    enrollmentsInfoFetch.then((enrollmentsInfo) => {
      if (enrollmentsInfo !== null) {
        if (!enrollmentsInfo.success) {
          handleSetGenericAlertDialogFromGenericResponse(enrollmentsInfo, 'enrollmentsInfoFetch');
          return;
        }
        if (isArray(get(enrollmentsInfo, 'enrollmentsForUser', false))) {
          setEnrollments(get(enrollmentsInfo, 'enrollmentsForUser', []));
        }
        setIsLoading(false);
      }
    });
  }, []);

  const handleNext = () => {
    setSelectedEnrollmentIndex((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setSelectedEnrollmentIndex((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setSelectedEnrollmentIndex(step);
  };
  return (
    <Grid md={6}>
      <Card sx={{ p: 3 }}>
        <CardHeader title="Enrollment Data" sx={{ p: 0, mb: 3 }} />
        {isLoading ? (
          <Stack justifyContent="center">
            <CircularProgress
              size={100}
              sx={{ alignSelf: 'center' }}
            />
          </Stack>
        ) : (
          <Stack>
            <Box sx={{ flexGrow: 1 }}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Enrollment ID
                </Typography>
                <Typography variant="body2">{get(enrollments, `[${selectedEnrollmentIndex}].enrollmentId`, 'Missing')}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Enrollment Call Info Link
                </Typography>
                <IconButton
                  component={Link}
                  to={`/accounts/${apiKey}/${userId}/${get(enrollments, `[${selectedEnrollmentIndex}].supportId`, 'Missing')}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Iconify
                    icon="carbon:arrow-up-right"
                    sx={{ mx: 0.5, width: 20, height: 20 }}
                  />
                </IconButton>
              </Stack>
              <SwipeableViews
                axis="x-reverse"
                index={selectedEnrollmentIndex}
                onChangeIndex={handleStepChange}
              // enableMouseEvents
              >
                {enrollments.map((step, index) => {
                  if (get(step, 'supportId', '') === '') {
                    return null;
                  }
                  return (
                    <div key={step.enrollmentId}>
                      {Math.abs(selectedEnrollmentIndex - index) <= 2 ? (
                        <Wave type="processed_audio_data" fileType="enrollment" apiKey={apiKey} playOnStart={false} supportId={step.supportId} />
                      ) : null}
                    </div>
                  );
                })}
              </SwipeableViews>
              <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={selectedEnrollmentIndex}
                sx={{
                  borderRadius: 2,
                  backgroundColor: '#EFEFEF',
                }}
                backButton={(
                  <Button
                    size="small"
                    onClick={handleNext}
                    disabled={selectedEnrollmentIndex === maxSteps - 1}
                  >
                    <Iconify
                      icon="carbon:arrow-left"
                      sx={{ mx: 0.5, width: 20, height: 20 }}
                    />
                    Back
                  </Button>
                )}
                nextButton={(
                  <Button size="small" onClick={handleBack} disabled={selectedEnrollmentIndex === 0}>
                    Next
                    <Iconify
                      icon="carbon:arrow-right"
                      sx={{ mx: 0.5, width: 20, height: 20 }}
                    />
                  </Button>
                )}
              />
            </Box>
          </Stack>
        )}
      </Card>
    </Grid>
  );
}
