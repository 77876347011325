/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  ButtonBase,
  Card, CardHeader, Stack,
  Typography,
} from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import Iconify from '../Iconify';

export default function ReportSummary({
  regularUserCount,
  regularUserRejectionRateSum,
  susUserCount,
  susUserRejectionRateSum,
  handlePdfDownload,
}) {
  const totalUserCount = regularUserCount + susUserCount;
  const regRejectionRate = `${(regularUserRejectionRateSum
    / ((regularUserCount) || 1.0)).toFixed(2)}%`;
  const susRejectionRate = `${(susUserRejectionRateSum
    / ((susUserCount) || 1.0)).toFixed(2)}%`;
  const totalRejectionRate = `${((regularUserRejectionRateSum + susUserRejectionRateSum)
    / ((susUserCount + regularUserCount) || 1.0)).toFixed(2)}%`;
  return (
    <Grid
      md={7}
      data-aos="fade-left"
    >
      <Card sx={{ p: 3 }}>
        <CardHeader
          title="Report Summary"
          sx={{ p: 0, mb: 3 }}
          action={(
            <ButtonBase
              sx={{
                pl: 1,
                py: 0.5,
                pr: 0.5,
                borderRadius: 1,
                typography: 'subtitle2',
                color: '#EFEFEF',
                bgcolor: '#505050',
              }}
              onClick={() => handlePdfDownload()}
            >
              Download PDF
              <Iconify
                icon="carbon:download"
                sx={{
                  mx: 0.5, width: 18, height: 18, ml: 1,
                }}
              />
            </ButtonBase>
          )}
        />
        <Stack>
          <Typography variant="h6" sx={{ mt: 3, color: '#000000' }}>Regular Users: </Typography>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              User Count
            </Typography>
            <Typography variant="body2">{regularUserCount}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Average Rejection Rate
            </Typography>
            <Typography variant="body2">{regRejectionRate}</Typography>
          </Stack>
          <Typography variant="h6" sx={{ mt: 3, color: '#000000' }}>Suspicious Users: </Typography>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              User Count
            </Typography>
            <Typography variant="body2">{susUserCount}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Average Rejection Rate
            </Typography>
            <Typography variant="body2">{susRejectionRate}</Typography>
          </Stack>
          <Typography variant="h6" sx={{ mt: 3, color: '#000000' }}>All Users: </Typography>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              User Count
            </Typography>
            <Typography variant="body2">{totalUserCount}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Average Rejection Rate
            </Typography>
            <Typography variant="body2">{totalRejectionRate}</Typography>
          </Stack>
        </Stack>
      </Card>
    </Grid>

  );
}
