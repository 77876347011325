/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';
import { format } from 'date-fns';
import {
  Container,
  Button,
  Card,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  CardHeader,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import viewIcon from '@iconify/icons-carbon/view';
import viewOff from '@iconify/icons-carbon/view-off';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, isArray } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormProvider, useForm, Controller } from 'react-hook-form';

import Grid from '@mui/material/Unstable_Grid2';
import { protectedFetch } from '../../utils/network';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import CustomBreadcrumbs from '../CustomBreadCrumbs/CustomBreadCrumbs';
import Iconify from '../Iconify';

export default function DeveloperSettings() {
  const navigate = useNavigate();

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] = useState(false);
  const [isLoadingPasswordUpdate, setIsLoadingPasswordUpdate] = useState(false);

  const {
    handleSetShowGenericAlertDialog,
    handleSetGenericAlertDialogTitle,
    handleSetGenericAlertDialogContent,
    handleSetGenericAlertDialogAccent,
    handleSetGenericAlertDialogFromGenericResponse,
  } = useContext(GenericAlertDialogContext);

  const {
    reset,
    control,
    watch,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
  });

  const onUpdatePasswordSubmitHandler = async (arg) => {
    const response = await protectedFetch('/api/update_password', {
      method: 'post',
      headers: {
        accept: 'application/json',
      },
      body: JSON.stringify(arg),
    });

    handleSetShowGenericAlertDialog(true);

    if (response.success) {
      response.message += '. Logging out in 3 seconds.';
    }

    handleSetGenericAlertDialogFromGenericResponse(response);

    if (response.success) {
      setTimeout(() => {
        fetch('/api/logout', {
          method: 'post',
          mode: 'cors',
          credentials: 'include',
        });
        window.localStorage.clear();
        navigate('login');
      }, 3000);
    }
  };

  const handleShowAPIKeyAndToken = async () => {
    const response = await fetch('/api/claims');
    const claims = await response.json();
    const url = (
      `/api/developers/info/${claims.apiKey}`
    );
    const devInfoFetch = await protectedFetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (devInfoFetch !== null) {
      if (!devInfoFetch.success) {
        handleSetGenericAlertDialogFromGenericResponse(devInfoFetch);
        return;
      }
      handleSetShowGenericAlertDialog(true);
      handleSetGenericAlertDialogTitle('API Key and Token');
      handleSetGenericAlertDialogAccent('primary');
      handleSetGenericAlertDialogContent(
        <Typography variant="body2">
          Key:
          {' '}
          {get(devInfoFetch, 'developer.APITwoKey', 'Server did not provide key')}
          {' '}
          <br />
          Token:
          {' '}
          {get(devInfoFetch, 'developer.APITwoToken', 'Server did not provide token')}
        </Typography>,
      );
    }
  };

  const handleRegenerateAPIToken = async () => {
    const url = (
      '/api/regenerate_api_token'
    );
    const response = await protectedFetch(url, {
      method: 'post',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    handleSetGenericAlertDialogFromGenericResponse(response);
  };

  const handleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleShowNewPasswordConfirmation = () => {
    setShowNewPasswordConfirmation(!showNewPasswordConfirmation);
  };

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
      >
        <Grid xs={12}>
          <CustomBreadcrumbs
            heading="Developer Settings"
            links={[
              {
                name: 'Change settings for your account',
              },
            ]}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <Card sx={{ p: 3 }}>
            <CardHeader title="API Credentials" sx={{ p: 0, mb: 3 }} />

            <Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  API Key
                </Typography>
                <Typography variant="body2">****************************</Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  API Token
                </Typography>
                <Typography variant="body2">****************************</Typography>
              </Stack>
            </Stack>
            <Typography variant="h6" sx={{ mt: 3, color: '#000000' }}>Actions: </Typography>
            <Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Show API Key and Token
                </Typography>
                <IconButton
                  variant="outlined"
                  onClick={handleShowAPIKeyAndToken}
                >
                  <Iconify
                    icon="carbon:view"
                    sx={{ color: '#000000', height: 20, width: 20 }}
                  />
                </IconButton>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Regenerate API Token
                </Typography>
                <IconButton
                  variant="outlined"
                  onClick={handleRegenerateAPIToken}
                >
                  <Iconify
                    icon="carbon:restart"
                    sx={{ color: '#000000', height: 20, width: 20 }}
                  />
                </IconButton>
              </Stack>
            </Stack>
          </Card>
        </Grid>
        <Grid xs={12} md={6}>
          <Card sx={{ p: 3 }}>
            <CardHeader title="Change Password" sx={{ p: 0, mb: 3 }} />
            <Stack
              spacing={4}
              alignItems={{ xs: 'flex-end', md: 'center' }}
              direction="column"
            >

              <form
                onSubmit={handleSubmit(onUpdatePasswordSubmitHandler)}
                style={{ width: '100%' }}
              >
                <Stack spacing={2.5} sx={{ alignItems: 'center' }}>

                  <Controller
                    name="oldPassword"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Old Password"
                        type={showOldPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowOldPassword} edge="end">
                                <Iconify icon={showOldPassword ? viewIcon : viewOff} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(error)}
                        helperText={get(error, 'message', undefined)}
                      />
                    )}
                  />

                  <Controller
                    name="newPassword"
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}":;'<>?,./])(?=.{32,})/,
                        message: 'Please choose strong password that is at least 32 characters long, contains at least one upper case letter, contains one lower case letter, contains one number, and contains one special character',
                      },
                      validate: (value) => value !== watch('oldPassword') || 'Please choose different password',
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="New Password"
                        type={showNewPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowNewPassword} edge="end">
                                <Iconify icon={showNewPassword ? viewIcon : viewOff} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(error)}
                        helperText={get(error, 'message', undefined)}
                      />
                    )}
                  />

                  <Controller
                    name="newPasswordConfirmation"
                    control={control}
                    rules={{
                      required: true,
                      validate: (value) => value === watch('newPassword') || 'Passwords do not match',
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Confirm New Password"
                        type={showNewPasswordConfirmation ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowNewPasswordConfirmation} edge="end">
                                <Iconify icon={showNewPasswordConfirmation ? viewIcon : viewOff} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(error)}
                        helperText={get(error, 'message', undefined)}
                      />
                    )}
                  />

                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    endIcon={<Iconify icon="carbon:save" />}
                    loading={isLoadingPasswordUpdate}
                    sx={{
                      width: '100%',
                      mx: { xs: 'auto !important', md: 'unset !important' },
                      backgroundColor: '#000000',
                    }}
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </form>

            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Container>

  );
}
