/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Card, CardHeader, Stack, Typography,
} from '@mui/material';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get } from 'lodash';

import Grid from '@mui/material/Unstable_Grid2';
import Iconify from '../Iconify';

// eslint-disable-next-line react/prop-types
export default function SttSettings({
  shouldDisplay,
  microsoftSTTEnabled,
  googleSTTEnabled,
}) {
  if (!shouldDisplay) {
    return null;
  }
  return (
    <Grid md={4}>
      <Card sx={{ p: 3 }}>
        <CardHeader title="Speech to Text Settings (STT)" sx={{ p: 0, mb: 3 }} />

        <Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Microsoft STT
            </Typography>
            <Iconify
              width={16}
              icon={microsoftSTTEnabled ? 'carbon:checkmark-filled' : 'carbon:error-filled'}
              sx={{ color: microsoftSTTEnabled ? '#75B09C' : '#F17300' }}
            />
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Google STT
            </Typography>
            <Iconify
              width={16}
              icon={googleSTTEnabled ? 'carbon:checkmark-filled' : 'carbon:error-filled'}
              sx={{ color: googleSTTEnabled ? '#75B09C' : '#F17300' }}
            />
          </Stack>

        </Stack>
      </Card>
    </Grid>
  );
}
