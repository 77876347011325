import {
  EXPIRED_TOKEN,
  CLEAR_CACHE,
} from './types';

export const setExpiredToken = (data) => ({
  type: EXPIRED_TOKEN,
  payload: data,
});

export const clearCache = () => ({
  type: CLEAR_CACHE,
});
