/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from 'react';
import ReactJson from 'react-json-view';
import moment from 'moment';
import {
  Container, Card, CardHeader, Stack,
  Typography, ButtonBase,
} from '@mui/material';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, has } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2';
import { protectedFetch } from '../../utils/network';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import {
  faceCalls,
  identificationAndVerificationCallTypesWithVoice,
  identificationCallTypes, verificationCallTypesWithFace,
} from './constants';
import DeveloperInfo from './DeveloperInfo';
import SttSettings from './SttSettings';
import Confidences from './Confidences';
import ResponseCodeLabel from '../ResponseCodeLabel/ResponseCodeLabel';
import Iconify from '../Iconify';
import AudioVideoData from './AudioVideoData';
import Enrollments from './Enrollments';
import CustomBreadcrumbs from '../CustomBreadCrumbs/CustomBreadCrumbs';
import FacePreview from './FacePreview';
import { BLUR_KEYS_AND_TOKENS } from '../../utils/cssStyles';

const reactJsonTheme = {
  base00: '#EEF1EE',
  base01: '#000000',
  base02: '#000000',
  base03: '#000000',
  base04: '#000000',
  base05: '#000000',
  base06: '#000000',
  base07: '#000000',
  base08: '#000000',
  base09: '000000',
  base0A: '000000',
  base0B: '000000',
  base0C: '000000',
  base0D: '000000',
  base0E: '000000',
  base0F: '000000',
};

function APICallDetails({
  callType,
  supportId,
  timeStampOfTheAPICall,
  responseCode,
  platformName,
  platformVersion,
  responseType,
  phrase,
  contentLanguage,
}) {
  return (
    <Grid md={4}>
      <Card sx={{ p: 3 }}>
        <CardHeader title="API Request Details" sx={{ p: 0, mb: 3 }} />
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Call Type
            </Typography>
            <Typography variant="body2">{callType}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Support ID
            </Typography>
            <Typography variant="body2">{supportId}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Time Stamp
            </Typography>
            <Typography variant="body2">{timeStampOfTheAPICall.format('YYYY-MM-DD hh:mm:ss')}</Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Response Code
            </Typography>
            <ResponseCodeLabel responseCode={responseCode} />
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Platform Name
            </Typography>
            <Typography variant="body2">{platformName}</Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Platform Version
            </Typography>
            <Typography variant="body2">{platformVersion}</Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Response Type
            </Typography>
            <Typography variant="body2">{responseType}</Typography>
          </Stack>

          {
            phrase
            && (
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Phrase
                </Typography>
                <Typography variant="body2">{phrase}</Typography>
              </Stack>
            )
          }

          {
            contentLanguage
            && (
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Content Language
                </Typography>
                <Typography variant="body2">{contentLanguage}</Typography>
              </Stack>
            )
          }
        </Stack>
      </Card>
    </Grid>
  );
}

// TODO: get rid of otherConfig, not helpful and it complicates logic
// TODO: ask about showview logs logic, it makes a requestr to isTrustedWithIP
// which doesnt make sense

export default function SupportId() {
  const { apiKey, userId, supportId } = useParams();
  const navigate = useNavigate();
  const isSuperUser = useSelector((state) => state.loginReducer.isSuperUser);
  const [data, setData] = useState({});
  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);
  useEffect(() => {
    const url = (
      `/api/support/detail/${apiKey}/${supportId}`
    );
    const supportIdFetch = protectedFetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    supportIdFetch.then((supportIdInfo) => {
      if (supportIdInfo !== null) {
        if (!supportIdInfo.success) {
          handleSetGenericAlertDialogFromGenericResponse(supportIdInfo, 'supportIdFetch');
          return;
        }
        setData(get(supportIdInfo, 'supportDetail', {}));
      }
    });
  }, []);
  const callType = get(data, 'callType', 'missing');
  const responseCode = get(data, 'apiResponse.responseCode', 'missing');
  const responseCodeIsFailOrSucc = responseCode === 'SUCC' || responseCode === 'FAIL';
  // eslint-disable-next-line max-len
  const canBeUsedForVoiceFineTuning = identificationAndVerificationCallTypesWithVoice.includes(callType)
    && responseCodeIsFailOrSucc;
  const canBeUsedForFineTuning = verificationCallTypesWithFace.includes(callType)
    && responseCodeIsFailOrSucc;
  const shouldHidePreviousEnrollmment = identificationCallTypes.includes(callType) && responseCode !== 'SUCC';
  // const loggingDataWasDeleted = get(data, 'loggingDataWasDeleted', false);
  // eslint-disable-next-line max-len
  // const hasLivenessData = isArray(get(data, 'livenessServerLog.lcoResults', false)) && !loggingDataWasDeleted;
  const timeStampOfTheAPICall = moment.utc(get(data, 'internalLog', ''));
  const apiResponse = get(data, 'apiResponse', {});
  // const internalLog = get(data, 'internalLog', {});
  const hasVoiceConfidence = (canBeUsedForVoiceFineTuning && get(data, 'apiResponse.confidence', false))
    || (canBeUsedForFineTuning && get(data, 'apiResponse.voiceConfidence', false));
  const hasFaceConfidence = get(data, 'apiResponse.faceConfidence', false);
  // const hasConfidences = get(data, 'apiResponse.confidence', false)
  //   || get(data, 'apiResponse.faceConfidence', false);
  // const showVideoFile = videoFileCalls.includes(callType);
  const isFaceCall = faceCalls.includes(callType);
  const showAudioVideoView = (get(data, 'hasProcessedAudioData', false) || get(data, 'hasOriginalData', false)) || (get(data, 'usingFullVideo', false) || get(data, 'hasOriginalData', false));
  const showPhotoView = get(data, 'hasPhoto', false);
  // const livenessServerLog = get(data, 'livenessServerLog', {});
  // const platformName = get(data, 'platformName', '');
  // const platformVersion = get(data, 'platformVersion', '');
  const voicePrintPhraseConfig = get(data, 'voicePrintPhraseConfig', {});
  const otherConfig = get(data, 'otherConfig', {});
  // TODO: clean this up, this is a mess
  const settingsUsedJson = { ...voicePrintPhraseConfig, ...otherConfig };
  const passedPhrase = get(data, 'passedVPPText', '');
  const passedContentLanguage = get(data, 'passedContentLanguage', '');
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
      >
        <Grid
          xs={12}
        >
          <CustomBreadcrumbs
            heading="Call Details"
            links={[
              {
                name: 'Accounts',
                href: '/accounts',
              },
              {
                name: `${apiKey}`,
                href: `/accounts/${apiKey}`,
                blur: BLUR_KEYS_AND_TOKENS,
              },
              {
                name: `${userId}`,
                href: `/accounts/${apiKey}/${userId}`,
              },
              {
                name: `${supportId}`,
              },
            ]}
            sx={{
              mb: { xs: 0, md: 0 },
            }}
          />
        </Grid>
        <DeveloperInfo
          apiKey={apiKey}
        />
        <SttSettings
          shouldDisplay={has(data, 'sttConfig')}
          microsoftSTTEnabled={get(data, 'sttConfig.microsoftSTTEnabled', false)}
          googleSTTEnabled={get(data, 'sttConfig.googleSTTEnabled', false)}
        />
        <Grid md={4}>
          <Card>
            <CardHeader title="Settings Used" sx={{ mb: 3 }} />
            <ReactJson
              name="Settings Used"
              theme={reactJsonTheme}
              src={settingsUsedJson}
              enableClipboard={false}
              iconStyle=""
              style={{
                fontSize: '14px',
                fontFamily: 'helveticaNow',
                paddingLeft: '24px',
                paddingBottom: '24px',
                color: '#505050',
              }}
              collapsed
              displayDataTypes={false}
            />
          </Card>
        </Grid>
        <APICallDetails
          callType={callType}
          supportId={supportId}
          timeStampOfTheAPICall={timeStampOfTheAPICall}
          responseCode={responseCode}
          platformName={get(data, 'platformName', 'NA')}
          platformVersion={get(data, 'platformVersion', 'NA')}
          responseType={get(data, 'responseType', 'NA')}
          phrase={passedPhrase}
          contentLanguage={passedContentLanguage}
        />
        <Confidences
          shouldDisplay={hasVoiceConfidence && isSuperUser}
          vi1Confidence={get(data, 'vi1Confidence', 'NA')}
          vi2Confidence={get(data, 'vi2Confidence', 'NA')}
          vi3Confidence={get(data, 'vi3Confidence', 'NA')}
          vi4Confidence={get(data, 'vi4Confidence', 'NA')}
          hasFaceConfidence={hasFaceConfidence}
          canBeUsedForFineTuning={canBeUsedForVoiceFineTuning}
          apiResponse={get(data, 'apiResponse', {})}
          useDataInFineTuningPulledFromDataBase={get(data, 'useDataInFineTuning', false)}
          supportId={supportId}
        />
        <Grid md={4}>
          <Card>
            <CardHeader
              title="Logs"
              sx={{ mb: 3 }}
              action={(
                <ButtonBase
                  sx={{
                    pl: 1,
                    py: 0.5,
                    pr: 0.5,
                    borderRadius: 1,
                    typography: 'subtitle2',
                    color: '#EFEFEF',
                    bgcolor: '#505050',
                  }}
                  onClick={() => {
                    navigate(`/log_details/${apiKey}/${userId}/${supportId}/api2`);
                  }}
                >
                  View Logs
                  <Iconify
                    icon="carbon:result"
                    sx={{ mx: 0.5, width: 18, height: 18 }}
                  />
                </ButtonBase>
              )}
            />

            <ReactJson
              name="JSON Response"
              theme={reactJsonTheme}
              src={apiResponse}
              enableClipboard={false}
              iconStyle=""
              style={{
                fontSize: '14px',
                fontFamily: 'helveticaNow',
                paddingLeft: '24px',
                paddingBottom: '24px',
                color: '#505050',
              }}
              collapsed
              displayDataTypes={false}
            />
            {
              (get(data, 'faceJsonResponseString', '') === '') ? null
                : (
                  <ReactJson
                    name="faceJsonResponse"
                    theme={reactJsonTheme}
                    src={JSON.parse(get(data, 'faceJsonResponseString', ''))}
                    enableClipboard={false}
                    iconStyle=""
                    style={{
                      fontSize: '14px',
                      fontFamily: 'helveticaNow',
                      paddingLeft: '24px',
                      paddingBottom: '24px',
                      color: '#505050',
                    }}
                    collapsed
                    displayDataTypes={false}
                  />
                )
            }
          </Card>
        </Grid>
        <AudioVideoData
          shouldDisplay={showAudioVideoView && !isFaceCall}
          apiKey={apiKey}
          supportId={supportId}
          hasProcessedAudioData={get(data, 'hasProcessedAudioData', false)}
          hasOriginalData={get(data, 'hasOriginalData', false)}
          usingFullVideo={get(data, 'usingFullVideo', false)}
          verificationConverted={get(data, 'verificationConverted', false)}
        />
        <Enrollments
          shouldDisplay={get(data, 'hasProcessedAudioData', false)}
          userId={userId}
          apiKey={apiKey}
          supportId={supportId}
        />
        <FacePreview
          shouldDisplay={showPhotoView}
          apiKey={apiKey}
          supportId={supportId}
          dataType="current"
        />
        <FacePreview
          shouldDisplay={showPhotoView && !shouldHidePreviousEnrollmment}
          apiKey={apiKey}
          supportId={supportId}
          dataType="previous"
        />
      </Grid>
    </Container>

  );
}
