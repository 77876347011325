/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react';
import {
  Card, CardHeader, FormControlLabel, Stack,
  Switch,
  Typography,
} from '@mui/material';
import { get } from 'lodash';
import Grid from '@mui/material/Unstable_Grid2';
import { protectedFetch } from '../../utils/network';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';

export default function Confidences({
  vi1Confidence,
  vi2Confidence,
  vi3Confidence,
  vi4Confidence,
  hasFaceConfidence,
  shouldDisplay,
  canBeUsedForFineTuning,
  apiResponse,
  useDataInFineTuningPulledFromDataBase,
  supportId,
}) {
  if (!shouldDisplay) {
    return null;
  }
  const [
    useDataInFineTuning, setUseDataInFineTuning] = useState(useDataInFineTuningPulledFromDataBase);

  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  const handleUseDataInFineTuningClick = (oldValue) => {
    const response = protectedFetch(`/api/use_data_in_fine_tuning/${!oldValue}/${supportId}`, {
      method: 'post',
      headers: {
        accept: 'application/json',
      },
    });
    response.then((res) => {
      if (res !== null) {
        if (!res.success) {
          handleSetGenericAlertDialogFromGenericResponse(res, 'handleUseDataInFineTuningClick');
          return;
        }
        setUseDataInFineTuning(!oldValue);
      }
    });
  };
  return (
    <Grid md={4}>
      <Card sx={{ p: 3 }}>
        <CardHeader title="Confidences" sx={{ p: 0, mb: 3 }} />
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Engine 1 Confidence
            </Typography>
            <Typography variant="body2">{vi1Confidence}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Engine 2 Confidence
            </Typography>
            <Typography variant="body2">{vi2Confidence}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Engine 3 Confidence
            </Typography>
            <Typography variant="body2">{vi3Confidence}</Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Engine 4 Confidence
            </Typography>
            <Typography variant="body2">{vi4Confidence}</Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Final Voice Confidence
            </Typography>
            <Typography variant="body2">
              {hasFaceConfidence ? get(apiResponse, 'voiceConfidence', 'NA') : get(apiResponse, 'confidence', 'NA')}
            </Typography>
          </Stack>
          {
            hasFaceConfidence && (
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Face Confidence
                </Typography>
                <Typography variant="body2">
                  {get(apiResponse, 'faceConfidence', 'NA')}
                </Typography>
              </Stack>
            )
          }

          {
            canBeUsedForFineTuning && (
              <>
                <Typography variant="h6" sx={{ mt: 3, color: '#000000' }}>Actions: </Typography>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Use Data in Fine Tuning
                  </Typography>
                  {/* TODO: add loading animation */}
                  <Switch
                    checked={useDataInFineTuning}
                    onChange={() => { handleUseDataInFineTuningClick(useDataInFineTuning); }}
                  />
                </Stack>
              </>
            )
          }
        </Stack>
      </Card>
    </Grid>
  );
}
