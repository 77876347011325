/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import {
  Container, Card, CardHeader, Stack,
  Divider, Button,
  TextField,
  Box,
  ButtonBase,
  Paper,
  Typography,
  Autocomplete,
  CircularProgress,
  Fade,
  ListItemText,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { format } from 'date-fns';
import moment from 'moment';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';

// import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, isArray, size } from 'lodash';

import Grid from '@mui/material/Unstable_Grid2';
import FineTuningContext from '../../contexts/FineTuningContext';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import { protectedFetch } from '../../utils/network';
import Iconify from '../Iconify';
import { responsiveFontSizes } from '../../utils/getFontValue';
import Label from '../Label/Label';
import FineTuningDetails from './FineTuningDetails';
// import { mockDataForUsersForFineTuning } from './mockData';

// TODO: make mock data for this

const defaultNoiseRemovalMax = 100;
const defaultMinLinearPredictionDegrees = 8;
const defaultMaxLinearPredictionDegrees = 20;
const defaultMinFrameLength = 10;
const defaultMaxFrameLength = 60;
const defaultAccuracy = 100;
const defaultTrainingTestingRatio = 0.5;
const defaultDoSincFilter = false;
const defaultDoNoiseRemoval = false;

const noiseRemovalMaxLimit = 500;
const minLinearPredictionDegreesLimit = 8;
const maxLinearPredictionDegreesLimit = 100;
const minTrainingTestingRatioLimit = 0.5;
const maxTraingTestingRatioLimit = 0.8;
const minFrameLengthLimit = 1;
const maxFrameLengthLimit = 300;
const minNumberOfUsers = 26;

export default function NewFineTuningSession() {
  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);
  const {
    selectedAccount,
    selectedPhrase,
    startDate,
    findButtonHit,
    handleSetFindButtonHit,
  } = useContext(FineTuningContext);
  // kind of useless for our purposes but keeps the alerts away
  const [usersForTuning, setUsersForTuning] = useState([]);
  const [doNoiseRemoval, setDoNoiseRemoval] = useState(true);

  const [noiseRemovalMaxTimeValue, setNoiseRemovalMaxTimeValue] = useState(defaultNoiseRemovalMax);
  const displayErrorForNoiseRemovalInput = noiseRemovalMaxTimeValue > noiseRemovalMaxLimit
    || noiseRemovalMaxTimeValue < 0;

  const [minLPDegreesValue, setMinLPDegreesValue] = useState(defaultMinLinearPredictionDegrees);
  const [maxLPDegreesValue, setMaxLPDegreesValue] = useState(defaultMaxLinearPredictionDegrees);
  const displayErrorForMinLPDegreesInput = minLPDegreesValue > maxLPDegreesValue
    || minLPDegreesValue < minLinearPredictionDegreesLimit;
  const displayErrorForMaxLPDegreesInput = maxLPDegreesValue > maxLinearPredictionDegreesLimit
    || maxLPDegreesValue < minLPDegreesValue;

  const [minFrameLengthValue, setMinFrameLengthValue] = useState(defaultMinFrameLength);
  const [maxFrameLengthValue, setMaxFrameLengthValue] = useState(defaultMaxFrameLength);
  const displayErrorForMinFrameLengthInput = minFrameLengthValue > maxFrameLengthValue
    || minFrameLengthValue < minFrameLengthLimit;
  const displayErrorForMaxFrameLengthInput = maxFrameLengthValue > maxFrameLengthLimit
    || maxFrameLengthValue < minFrameLengthValue;

  const [accuracyValue, setAccuracyValue] = useState(defaultAccuracy);

  const hasEnoughUsersSelected = size(usersForTuning) >= minNumberOfUsers;

  const preventFromRunningFineTuning = displayErrorForNoiseRemovalInput
    || displayErrorForMinLPDegreesInput
    || displayErrorForMaxLPDegreesInput
    || displayErrorForMinFrameLengthInput
    || displayErrorForMaxFrameLengthInput
    || !hasEnoughUsersSelected;

  // console.log(preventFromRunningFineTuning);
  const sendDataToFineTuning = async () => {
    try {
      const now = moment();
      const dataToPass = {
        startTime: moment(startDate).toISOString(),
        timeWhenFineTuningStartedRunning: moment(now).toISOString(),
        targetAPIKey: selectedAccount.key,
        vsitCustomPhrasesId: selectedPhrase.vsitCustomPhrasesId,
        numberOfUsersToRun: size(usersForTuning),
        noiseRemovalMaxTime: noiseRemovalMaxTimeValue,
        minLPDegrees: minLPDegreesValue,
        maxLPDegrees: maxLPDegreesValue,
        minFrameLength: minFrameLengthValue,
        maxFrameLength: maxFrameLengthValue,
        accuracy: accuracyValue,
        doSincFilter: false,
        doNoiseRemoval,
      };
      const url = (
        '/api/send_data_to_fine_tuning'
      );
      const fineTuningFetch = await protectedFetch(url, {
        method: 'post',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToPass),
      });
      handleSetGenericAlertDialogFromGenericResponse(fineTuningFetch);
      // handleSubmitPhrase({ header: 'Phrase Request Info', text: result });
    } catch (err) {
      console.log('sendDataToFineTuning catch Exception: ', err);
      // handleSetGenericAlertDialogFromGenericResponse(fineTuningFetch);
      // handleSubmitPhrase({ header: 'Phrase Request Info', text: err.message });
    }
  };

  const handleFindClick = async () => {
    try {
      const formData = new FormData();
      const endDateConvertedToSQLFormat = moment().toISOString();
      const startDateConvertedToSQLFormat = moment(startDate).toISOString();
      formData.append('startDate', startDateConvertedToSQLFormat);
      formData.append('endDate', endDateConvertedToSQLFormat);
      formData.append('apiKey', selectedAccount.key);
      formData.append('contentLanguage', selectedPhrase.contentLanguage);
      formData.append('vppText', selectedPhrase.vppText);
      const url = (
        '/api/get_users_for_fine_tuning'
      );
      const usersFetch = await protectedFetch(url, {
        method: 'post',
        headers: {
          accept: 'application/json',
        },
        body: formData,
      });
      // const usersFetch = mockDataForUsersForFineTuning();
      // console.log(usersFetch);
      setUsersForTuning(get(usersFetch, 'users', []));
      handleSetFindButtonHit(true);
      // handleSubmitPhrase({ header: 'Phrase Request Info', text: result });
    } catch (err) {
      console.log('handleFindClick catch Exception: ', err);
      // handleSubmitPhrase({ header: 'Phrase Request Info', text: err.message });
    }
  };

  useEffect(() => {
    if (findButtonHit) {
      handleFindClick();
    }
  }, []);

  const columns = [
    { field: 'uniqueId', headerName: 'User ID', flex: 2 },
    {
      field: 'responseCode',
      headerName: 'ResponseCode',
      flex: 1,
      valueGetter: (params) => get(params, 'row.apiResponse.responseCode', ''),
      renderCell: (param) => (
        <Label
          variant="soft"
        >
          {get(param, 'row.apiResponse.responseCode', '')}
        </Label>
        // <ListItemText
        //   primary={get(param, 'row.apiResponse.message', '')}
        // secondary={format(new Date(get(param, 'value', '')), 'HH:mm:ss')}
        // primaryTypographyProps={{ typography: 'body2', noWrap: true }}
        // secondaryTypographyProps={{
        //   mt: 0.5,
        //   component: 'span',
        //   typography: 'caption',
        // }}
        // />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Time and Date',
      flex: 1,
      renderCell: (param) => (
        <ListItemText
          primary={format(new Date(get(param, 'value', '')), 'dd MMM yyyy')}
          secondary={format(new Date(get(param, 'value', '')), 'HH:mm:ss')}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      ),
    },
  ];

  return (

    <FormProvider>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={3}
        >

          <FineTuningDetails
            includeStartDate
            activateButtonText="Find"
            activateButtonFunction={handleFindClick}
            detailsSubHeader="Enter criteria to find the marked users to start a new Fine Tuning Session"
          />

          {
            findButtonHit && (
              <Fade in={false} style={{ transformOrigin: '0 0 0' }}>
                <>
                  <Grid md={12} lg={6}>
                    <Card>
                      <CardHeader
                        title="Fine Tuning Settings"
                        subheader="Enter configuration settings for the fine tuning session"
                      />
                      <Divider sx={{ borderStyle: 'dashed' }} />

                      <Stack direction="column" sx={{ mx: 3, mt: 2 }} spacing={2}>
                        <Stack spacing={2}>
                          <Typography variant="h6" sx={{ color: '#000000' }}>Noise Removal</Typography>
                          <Box gap={2} display="grid" gridTemplateColumns="repeat(2, 1fr)">
                            <Paper
                              fullWidth
                              component={ButtonBase}
                              variant="outlined"
                              sx={{
                                p: 2.5,
                                borderRadius: 1,
                                typography: 'subtitle2',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                ...(doNoiseRemoval ? {
                                  color: '#000000',
                                  // borderWidth: 2,
                                  borderColor: '#000000',
                                } : {
                                  color: '#919EAB',
                                  borderColor: '#919EAB',
                                }),
                              }}
                              onClick={() => setDoNoiseRemoval(true)}
                            >
                              <Stack
                                flexGrow={1}
                                direction="row"
                                justifyContent="flex-end"
                                spacing={1}
                                sx={{ mb: 1 }}
                              >
                                <Iconify icon="carbon:volume-mute-filled" sx={{ fontSize: '16px' }} />
                                <Typography variant="h6" sx={{ ...responsiveFontSizes({ xs: 16 }) }}>On</Typography>
                              </Stack>
                              <Typography variant="body2" sx={{ ml: 3, textAlign: 'left', ...responsiveFontSizes({ xs: 14 }) }}>
                                Removes background noise based on
                                he maximum noise removal time setting
                              </Typography>
                            </Paper>
                            <Paper
                              fullWidth
                              component={ButtonBase}
                              variant="outlined"
                              sx={{
                                p: 2.5,
                                borderRadius: 1,
                                typography: 'subtitle2',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                ...(!doNoiseRemoval ? {
                                  color: '#000000',
                                  // borderWidth: 2,
                                  borderColor: '#000000',
                                } : {
                                  color: '#919EAB',
                                  borderColor: '#919EAB',
                                }),
                              }}
                              onClick={() => setDoNoiseRemoval(false)}
                            >
                              <Stack
                                flexGrow={1}
                                direction="row"
                                justifyContent="flex-end"
                                spacing={1}
                                sx={{ mb: 1 }}
                              >
                                <Iconify icon="carbon:volume-up-filled" sx={{ fontSize: '16px' }} />
                                <Typography variant="h6" sx={{ ...responsiveFontSizes({ xs: 16 }) }}>Off</Typography>
                              </Stack>
                              <Typography variant="body2" sx={{ ml: 3, textAlign: 'left', ...responsiveFontSizes({ xs: 14 }) }}>
                                Does not remove background noise in recordings (default)
                              </Typography>
                            </Paper>
                          </Box>
                          <TextField
                            fullWidth
                            // defaultValue={defaultNoiseRemovalMax}
                            labelId="phrase-disabled-label"
                            id="phrase-disabled"
                            label="Number of Users Selected"
                            type="number"
                            error={!hasEnoughUsersSelected}
                            value={size(usersForTuning)}
                            disabled
                            helperText={!hasEnoughUsersSelected ? `Must select at least ${minNumberOfUsers}` : undefined}
                          />
                          {doNoiseRemoval && (
                            <TextField
                              // defaultValue={defaultNoiseRemovalMax}
                              labelId="phrase-disabled-label"
                              id="phrase-disabled"
                              label="Noise Removal Max Time"
                              type="number"
                              error={displayErrorForNoiseRemovalInput}
                              value={noiseRemovalMaxTimeValue}
                              onChange={(event) => {
                                setNoiseRemovalMaxTimeValue(event.target.value);
                              }}
                              helperText={displayErrorForNoiseRemovalInput ? `Must be between 0 and ${noiseRemovalMaxLimit}` : undefined}
                              fullWidth
                            />
                          )}
                        </Stack>

                        <Stack spacing={2} direction="row">
                          <TextField
                            defaultValue={defaultMinLinearPredictionDegrees}
                            labelId="phrase-disabled-label"
                            id="phrase-disabled"
                            label="Minimum LP Degrees"
                            type="number"
                            fullWidth
                            error={displayErrorForMinLPDegreesInput}
                            value={minLPDegreesValue}
                            onChange={(event) => {
                              setMinLPDegreesValue(event.target.value);
                            }}
                            helperText={displayErrorForMinLPDegreesInput ? `Must be between ${minLinearPredictionDegreesLimit} and ${maxLPDegreesValue}` : undefined}
                          />
                          <TextField
                            defaultValue={defaultMaxLinearPredictionDegrees}
                            labelId="phrase-disabled-label"
                            id="phrase-disabled"
                            label="Maximum LP Degrees"
                            type="number"
                            fullWidth
                            error={displayErrorForMaxLPDegreesInput}
                            value={maxLPDegreesValue}
                            onChange={(event) => {
                              setMaxLPDegreesValue(event.target.value);
                            }}
                            helperText={displayErrorForMaxLPDegreesInput ? `Must be between ${minLPDegreesValue} and ${maxLinearPredictionDegreesLimit}` : undefined}
                          />
                        </Stack>
                        <Stack spacing={2} direction="row">
                          <TextField
                            defaultValue={defaultMinFrameLength}
                            labelId="phrase-disabled-label"
                            id="phrase-disabled"
                            label="Minimum Frame Length"
                            type="number"
                            fullWidth
                            error={displayErrorForMinFrameLengthInput}
                            value={minFrameLengthValue}
                            onChange={(event) => {
                              setMinFrameLengthValue(event.target.value);
                            }}
                            helperText={displayErrorForMinFrameLengthInput ? `Must be between ${minFrameLengthLimit} and ${maxFrameLengthValue}` : undefined}
                          />
                          <TextField
                            defaultValue={defaultMaxFrameLength}
                            labelId="phrase-disabled-label"
                            id="phrase-disabled"
                            label="Maximum Frame Length"
                            type="number"
                            fullWidth
                            error={displayErrorForMaxFrameLengthInput}
                            value={maxFrameLengthValue}
                            onChange={(event) => {
                              setMaxFrameLengthValue(event.target.value);
                            }}
                            helperText={displayErrorForMaxFrameLengthInput ? `Must be between ${minFrameLengthValue} and ${maxFrameLengthLimit}` : undefined}
                          />
                        </Stack>
                        <Stack spacing={2} direction="row">
                          <TextField
                            defaultValue={defaultAccuracy}
                            labelId="phrase-disabled-label"
                            id="phrase-disabled"
                            label="Accuracy"
                            type="number"
                            fullWidth
                            value={accuracyValue}
                            onChange={(event) => {
                              setAccuracyValue(event.target.value);
                            }}
                          />
                        </Stack>
                      </Stack>

                      <Stack
                        spacing={2}
                        sx={{
                          p: 3,
                          display: 'flex',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => sendDataToFineTuning()}
                          type="submit"
                          disabled={preventFromRunningFineTuning}
                        >
                          Run
                        </Button>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid md={12} lg={6}>
                    <Card>
                      <CardHeader
                        title="Fine Tuning Users"
                        subheader="Users that were marked for fine tuning"
                      />
                      <Divider sx={{ borderStyle: 'dashed' }} />
                      <DataGrid
                        rows={usersForTuning}
                        columns={columns}
                        getRowId={(row) => row.supportId}
                        onRowClick={(param) => {
                          window.open(`/accounts/${get(param, 'row.apiKey', 'missing')}/${get(param, 'row.uniqueId', 'missing')}/${get(param, 'row.supportId', 'missing')}`, '_blank');
                        }}
                      />
                    </Card>
                  </Grid>
                </>
              </Fade>
            )
          }
        </Grid>
      </Container>
    </FormProvider>

  );
}
