import React, { useEffect, useState, useContext } from 'react';
import {
  Container, Card, Stack,
  ListItemText, Tabs, Tab, InputAdornment, TextField,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, isArray } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

import Grid from '@mui/material/Unstable_Grid2';
import moment from 'moment';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import { protectedFetch } from '../../utils/network';
import Label from '../Label/Label';
import Iconify from '../Iconify';
import CustomBreadcrumbs from '../CustomBreadCrumbs/CustomBreadCrumbs';
import { BLUR_KEYS_AND_TOKENS } from '../../utils/cssStyles';

const userColumns = [
  { field: 'userId', headerName: 'User ID', flex: 2 },
  {
    field: 'updatedAt',
    headerName: 'Last Activity',
    flex: 1,
    valueGetter: (params) => get(params, 'row.updatedAt', 0),
    renderCell: (param) => (
      <ListItemText
        primary={moment.unix(get(param, 'row.updatedAt', 0)).calendar()}
        secondary={moment.unix(get(param, 'row.updatedAt', 0)).format('HH:mm:ss')}
        primaryTypographyProps={{ typography: 'body2', noWrap: true }}
        secondaryTypographyProps={{
          mt: 0.5,
          component: 'span',
          typography: 'caption',
        }}
      />
    ),
  },
];

const groupColumns = [
  { field: 'groupId', headerName: 'Group ID', flex: 2 },
  {
    field: 'updatedAt',
    headerName: 'Last Activity',
    flex: 1,
    valueGetter: (params) => get(params, 'row.updatedAt', 0),
    renderCell: (param) => (
      <ListItemText
        primary={moment.unix(get(param, 'row.updatedAt', 0)).calendar()}
        secondary={moment.unix(get(param, 'row.updatedAt', 0)).format('HH:mm:ss')}
        primaryTypographyProps={{ typography: 'body2', noWrap: true }}
        secondaryTypographyProps={{
          mt: 0.5,
          component: 'span',
          typography: 'caption',
        }}
      />
    ),
  },
];

// TODO: add loading screen
export default function UserIdsAndGroups() {
  const { apiKey } = useParams();
  const navigate = useNavigate();
  const [filter, setFilter] = useState('');
  const [selectedTab, setSelectedTab] = useState('users');
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [filt, setFilt] = useState([]);

  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  useEffect(() => {
    const usersURL = (
      `/api/developers/${apiKey}/users/${40000}`
    );
    const groupsURL = (
      `/api/developers/${apiKey}/groups/${40000}`
    );
    const usersFetch = protectedFetch(usersURL, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    const groupsFetch = protectedFetch(groupsURL, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    usersFetch.then((accountsInfo) => {
      if (accountsInfo !== null) {
        if (!accountsInfo.success) {
          handleSetGenericAlertDialogFromGenericResponse(accountsInfo);
          return;
        }
        if (isArray(accountsInfo.users)) {
          setUsers(accountsInfo.users);
        }
        groupsFetch.then((groupsInfo) => {
          if (accountsInfo !== null) {
            if (!groupsInfo.success) {
              handleSetGenericAlertDialogFromGenericResponse(groupsInfo);
              return;
            }
            if (isArray(groupsInfo.groups)) {
              setGroups(groupsInfo.groups);
            }
          }
        });
      }
    });
  }, []);

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
      >
        <Grid md={12}>
          <CustomBreadcrumbs
            heading="Users and Groups"
            links={[
              {
                name: 'Accounts',
                href: '/accounts',
              },
              {
                name: `${apiKey}`,
                blur: BLUR_KEYS_AND_TOKENS,
              },
            ]}
            sx={{
              mb: { xs: 3, md: 3 },
            }}
          />
          <Card>
            <Tabs
              value={selectedTab}
              sx={{
                px: 3,
                pb: 0,
              }}
            >
              <Tab
                iconPosition="end"
                value="users"
                label="users"
                onClick={() => {
                  setSelectedTab('users');
                  setFilter('');
                  setFilt([]);
                }}
                icon={(
                  <Label
                    variant="filled"
                    sx={{
                      bgcolor: '#505050',
                    }}
                  >
                    {get(users, 'length', 0)}
                  </Label>
                )}
              />
              <Tab
                iconPosition="end"
                value="groups"
                onClick={() => {
                  setSelectedTab('groups');
                  setFilter('');
                  setFilt([]);
                }}
                label="groups"
                icon={(
                  <Label
                    variant="filled"
                    sx={{
                      bgcolor: '#505050',
                    }}
                  >
                    {get(groups, 'length', 0)}
                  </Label>
                )}
              />
            </Tabs>
            <Stack
              spacing={2}
              alignItems={{ xs: 'flex-end', md: 'center' }}
              direction={{
                xs: 'column',
                md: 'row',
              }}
              sx={{
                p: 2,
              }}
            >
              <TextField
                fullWidth
                value={filter}
                onChange={(event) => {
                  setFilter(event.target.value);
                  setFilt([
                    {
                      field: selectedTab === 'users' ? 'userId' : 'groupId',
                      operator: 'contains',
                      id: 95962,
                      value: event.target.value,
                      fromInput: ':r1j:',
                    },
                  ]);
                }}
                label="Search by ID"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  ),
                }}
              />

            </Stack>
            <DataGrid
              data-aos="fade-in"
              // slots={{ toolbar: GridToolbar }}
              rows={selectedTab === 'users' ? users : groups}
              columns={selectedTab === 'users' ? userColumns : groupColumns}
              getRowId={(row) => (selectedTab === 'users' ? row.userId : row.groupId)}
              onRowClick={(event) => navigate(`/accounts/${apiKey}/${event.id}`)}
              filterModel={{
                items: filt,
                logicOperator: 'and',
                quickFilterValues: [],
                quickFilterLogicOperator: 'and',
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>

  );
}
