/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from 'react';
import {
  Container, Card, CardHeader,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, isArray } from 'lodash';
import { useTheme, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import Grid from '@mui/material/Unstable_Grid2';
import Label from '../Label/Label';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import { protectedFetch } from '../../utils/network';
import formatBytes from '../../utils/math';

function LicenseState({ license }) {
  const expired = moment().isAfter(moment(license.activatedAt, 'X').add(license.expirationPeriodDays, 'days'));
  let backgroundCol = '#FCBC3D';
  let color = '#000000';
  let text = '';

  if (!license.activated) {
    text = 'Not Activated';
  } else if (license.disabled) {
    backgroundCol = '#DD403A';
    color = '#FFFFFF';
    text = 'Disabled';
  } else if (!expired) {
    backgroundCol = '#248232';
    color = '#FFFFFF';
    text = 'Active';
  } else {
    backgroundCol = '#DD403A';
    color = '#FFFFFF';
    text = 'Expired';
  }

  return (
    <Label
      variant="soft"
      sx={{
        backgroundColor: backgroundCol,
        color,
      }}
    >
      {text}
    </Label>
  );
}

export default function Licenses() {
  const [licenses, setLicenses] = useState([]);

  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  useEffect(() => {
    const url = (
      '/api/get_licenses'
    );
    const licenseFetch = protectedFetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    licenseFetch.then((licenseInfo) => {
      // console.log(licenseInfo);
      if (licenseInfo !== null) {
        if (!licenseInfo.success) {
          handleSetGenericAlertDialogFromGenericResponse(licenseInfo);
          return;
        }
        if (isArray(licenseInfo.licenses)) {
          setLicenses(licenseInfo.licenses);
        }
      }
    });
  }, []);

  const columns = [
    { field: 'name', headerName: 'License Name', flex: 2 },
    { field: 'description', headerName: 'Description', flex: 2 },
    {
      field: 'activated',
      headerName: 'Status',
      flex: 2,
      renderCell: (item) => <LicenseState license={item.row} />,
    },
  ];

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
      >
        <Grid md={12} sx={{}}>
          <Card
            data-aos="fade-right"
          >
            <CardHeader title="Licenses" subheader="Access credentials that were shared with you" sx={{ mb: 3 }} />

            <DataGrid
              rows={licenses}
              columns={columns}
              getRowId={(row) => row.licenseKey}
            />

          </Card>
        </Grid>
      </Grid>
    </Container>

  );
}
