import {
  LOGIN_SUCCESS,
  CLEAR_CACHE,
} from './types';

export const setLoginData = (data) => ({
  type: LOGIN_SUCCESS,
  payload: data,
});

export const clearCache = () => ({
  type: CLEAR_CACHE,
});

// export const logout = (data) => (dispatch, getState) => {
//   const state = getState();
//   const localeData = get(state, 'rootReducer.locale.locale', 'en');
//   if (get(state, 'rootReducer.login.user.token', false)) {
//     axios
//       .post('/logout', data, {
//         headers: {
//           'X-Tenant': 'EOG',
//           // prettier-ignore
//           Accept: 'application/json',
//           'X-Locale': localeData,
//         },
//       })
//       .then((response) => {
//         dispatch(clearCache());
//       })
//       .catch((error) => {
//         dispatch(clearCache());
//       });
//   } else {
//     dispatch(clearCache());
//   }
// };
