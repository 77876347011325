/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import {
  Box, IconButton, Stack, Typography,
} from '@mui/material';
import ReactDOM from 'react-dom';
import WaveSurfer from 'wavesurfer.js';
import Iconify from '../Iconify';
import { protectedFetch } from '../../utils/network';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';

export default function Wave({
  type,
  apiKey,
  supportId,
  playOnStart,
}) {
  const containerRef = useRef();
  const wavesurferRef = useRef({
    isPlaying: () => false,
  });
  const [isPlaying, toggleIsPlaying] = useState(false);

  const [data, setData] = useState(new Blob());
  const [error, setError] = useState(false);
  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  useEffect(() => {
    const url = (
      `/api/support_audio/${apiKey}/${supportId}/${type}`
    );
    const supportFileFetch = protectedFetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    supportFileFetch.then((supportFile) => {
      if (supportFile !== null) {
        if (!supportFile.success) {
          handleSetGenericAlertDialogFromGenericResponse(supportFile, 'supportFileFetch');
          return;
        }
        const byteCharacters = atob(supportFile.data);
        const byteArrays = [];
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArrays.push(byteCharacters.charCodeAt(i));
        }
        const byteArray = new Uint8Array(byteArrays);

        const wavesurfer = WaveSurfer.create({
          container: containerRef.current,
        });

        setData(byteArray);
        wavesurfer.loadBlob(new Blob([byteArray]));
        wavesurfer.on('ready', () => {
          wavesurferRef.current = wavesurfer;
        });
        wavesurfer.on('error', () => {
          wavesurfer.destroy();
          setError(true);
        });
      }
    });
  }, []);

  const saveData = (filename) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    const blob = new Blob([data], { type: 'octet/stream' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  if (error) {
    return (
      <Box>
        <Typography variant="h6" sx={{ mt: 3, color: '#000000' }}>
          {' '}
          Cannot Play
          {' '}
          {(type === 'processed_audio_data') ? 'Processed' : 'Original'}
          {' '}
          File
          {' '}
        </Typography>
      </Box>
    );
  }
  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6" sx={{ mt: 2, color: '#000000' }}>
          {
            (type === 'processed_audio_data')
              ? 'Processed' : 'Original'
          }
        </Typography>
        <IconButton
          variant="contained"
          onClick={() => { saveData(`${type}${apiKey}${supportId}.wav`); }}
        >
          <Iconify
            icon="carbon:download"
            sx={{ color: '#000000', height: 20, width: 20 }}
          />
        </IconButton>

      </Stack>

      <Box
        role="button"
        tabIndex="0"
        ref={containerRef}
        onClick={() => {
          wavesurferRef.current.playPause();
          toggleIsPlaying(wavesurferRef.current.isPlaying());
       }}
      />
    </Stack>
  );
}
