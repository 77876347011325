/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
// import PropTypes from 'prop-types';
// @mui
import {
  CircularProgress,
  CardHeader, Card, Box,
} from '@mui/material';
// components
import Chart, { useChart } from '../chart';

export default function TotalGraph({
  title, isLoading, subheader, series, categories, ...other
}) {
  const chartOptions = useChart({
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    xaxis: {
      categories,
    },
  });
  let boxStyles = {
    mt: 3,
    mx: 3,
  };
  if (isLoading) {
    boxStyles = {
      ...boxStyles,
      height: 500,
      display: 'flex',
      justifyContent: 'center',
    };
  }
  return (
    <Card
      data-aos="fade-right"
      {...other}
    >
      <CardHeader
        title={title}
        subheader={subheader}
      />

      <Box sx={boxStyles}>
        {isLoading
          ? (
            <CircularProgress
              size={100}
              sx={{ alignSelf: 'center' }}
            />
          )
          : <Chart dir="ltr" type="area" series={series} options={chartOptions} height={500} />}
      </Box>
    </Card>
  );
}

// ActivityGraph.propTypes = {
//   chart: PropTypes.object,
//   subheader: PropTypes.string,
//   title: PropTypes.string,
// };
