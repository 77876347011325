/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  ButtonBase,
  Card, CardActions, CardHeader,
} from '@mui/material';
import { get } from 'lodash';
import Grid from '@mui/material/Unstable_Grid2';
import { protectedFetch } from '../../utils/network';
import Wave from './Wave';
import Iconify from '../Iconify';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import Video from './Video';

export default function AudioVideoData({
  apiKey,
  supportId,
  hasProcessedAudioData,
  usingFullVideo,
  hasOriginalData,
  shouldDisplay,
  verificationConverted,
}) {
  if (!shouldDisplay) {
    return null;
  }
  const isSuperUser = useSelector((state) => state.loginReducer.isSuperUser);
  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  const handleConvertToVerificationClick = () => {
    const response = protectedFetch(`/api/convert_voice_verification_to_enrollment/${apiKey}/${supportId}`, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    response.then((res) => {
      if (res !== null) {
        handleSetGenericAlertDialogFromGenericResponse(res);
      }
    });
  };

  const canDisplayVerificationConversionButton = isSuperUser
    && (hasProcessedAudioData || hasOriginalData);
  return (
    <Grid md={6}>
      <Card sx={{ p: 3 }}>
        <CardHeader title={usingFullVideo ? 'Audio/Video Data' : 'Audio Data'} sx={{ p: 0, mb: 3 }} />
        {hasProcessedAudioData
          && <Wave playOnStart={false} type="processed_audio_data" apiKey={apiKey} supportId={supportId} />}
        {hasOriginalData && usingFullVideo
          && <Video playOnStart={false} type="original_data" apiKey={apiKey} supportId={supportId} />}
        {hasOriginalData && !usingFullVideo
          && <Wave playOnStart={false} type="original_data" apiKey={apiKey} supportId={supportId} />}
        {canDisplayVerificationConversionButton && (
          <CardActions sx={{ p: 0, justifyContent: 'flex-end' }}>
            <ButtonBase
              sx={{
                pl: 1,
                py: 0.5,
                pr: 0.5,
                borderRadius: 1,
                typography: 'subtitle2',
                color: '#EFEFEF',
                bgcolor: '#505050',
              }}
              onClick={handleConvertToVerificationClick}
            >
              Convert To Enrollment
              <Iconify
                icon="carbon:improve-relevance"
                sx={{ mx: 0.5, width: 20, height: 20 }}
              />
            </ButtonBase>
          </CardActions>
        )}
      </Card>
    </Grid>
  );
}
