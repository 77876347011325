import React from 'react';
import {
  Container,
} from '@mui/material';
// import { Icon as IconifyIcon } from '@iconify/react';
import Grid from '@mui/material/Unstable_Grid2';
import TotalGraphWithSelector from '../TotalGraph/TotalGraphWithSelector';
import ActivityGraphWithSelector from '../ActivityGraph/ActivityGraphWithSelector';

export default function Home() {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
      >
        <TotalGraphWithSelector />
        <ActivityGraphWithSelector />
      </Grid>
    </Container>
  );
}
