/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';

import {
  Box,
  Typography,
  Grid,
  Container,
  Paper,
  TextField,
  Stack,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { get } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import NET from 'vanta/dist/vanta.net.min';
import viewIcon from '@iconify/icons-carbon/view';
import viewOff from '@iconify/icons-carbon/view-off';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import Iconify from '../Iconify';
import WhatIsQuantaKeyCoverImage from '../../assets/images/QuantaKeyCoverImage.png';
import VoiceItLogo from '../../assets/images/VoiceItLogoYellow.png';
import FineTuningContext from '../../contexts/FineTuningContext';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';

import { responsiveFontSizes } from '../../utils/getFontValue'; // Import using relative path
import getWindowSize from '../../utils/getScreenSize';
import { setLoginData } from '../../reduxStore/actions/loginActions';

const HigherTextInput = styled(TextField)(() => ({
  '& .MuiFilledInput-input': {
    minHeight: '1.6em',
  },
}));

const regularTextFontSizes = {
  xs: 16, sm: 18, md: 18, lg: 18,
};

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const {
    handleSetShowGenericAlertDialog,
    handleSetGenericAlertDialogTitle,
    handleSetGenericAlertDialogContent,
  } = useContext(GenericAlertDialogContext);

  const {
    handleSetSelectedAccount,
    handleSetSelectedPhrase,
    handleSetAccounts,
    handleSetAccountsAlreadyRetrieved,
    handleSetStartDate,
    handleSetFindButtonHit,
  } = useContext(FineTuningContext);

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [vantaEffect, setVantaEffect] = useState(null);
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(NET({
        el: myRef.current,
        color: 0xe8c22c,
        backgroundColor: 0x0,
        points: 13.00,
        maxDistance: 24.00,
        spacing: 19.00,
        showDots: false,
        zoom: 0.93,
      }));
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  const {
    reset,
    control,
    setError,
    clearErrors,
    register,
    handleSubmit,
    formState: {
      isSubmitting,
      errors,
    },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (data) => {
    const response = await fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const loginData = await response.json();
      dispatch(setLoginData(loginData));

      handleSetShowGenericAlertDialog(false);
      handleSetGenericAlertDialogTitle('');
      handleSetGenericAlertDialogContent('');

      handleSetSelectedAccount('');
      handleSetSelectedPhrase({
        vsitCustomPhrasesId: 0,
        contentLanguage: '',
        vppText: '',
        enrollmentCount: 0,
      });
      handleSetAccounts([]);
      handleSetAccountsAlreadyRetrieved(false);
      handleSetStartDate(moment().subtract(1, 'month'));
      handleSetFindButtonHit(false);
      navigate('/home');
    } else if (response.status === 401) {
      setError('status', {
        type: 'custom',
        message: 'Unauthorized, wrong email or password',
      });
    } else if (response.status === 500) {
      setError('status', {
        type: 'custom',
        message: 'Server Error',
      });
    } else {
      setError('status', {
        type: 'custom',
        message: 'Something went wrong.',
      });
    }
  };
  return (
    <Box
      ref={myRef}
      sx={{
        backgroundImage: `url(${WhatIsQuantaKeyCoverImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%',
      }}
    >
      <Container sx={{
        width: '100vw',
        height: {
          xs: '100vh',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#EFEFEF',
      }}
      >
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
            <Paper
              sx={{
                backgroundColor: '#EFEFEF',
                borderRadius: '20px',
                p: '15% 72px 20% 72px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}

            >
              <Box
                sx={{
                  pb: 2,
                  my: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <img src={VoiceItLogo} alt="QuantaSafe" style={{ height: '150px' }} />
                <Typography
                  variant="h1"
                  sx={{
                    color: '#000000',
                    fontFamily: 'branding',
                    fontWeight: 'bold',
                    ...responsiveFontSizes({
                      xs: 46, sm: 46, md: 46, lg: 46,
                    }),
                  }}
                >
                  VoiceIt API 2.0
                </Typography>
                <Typography
                  variant="h1"
                  sx={{
                    color: '#000000',
                    fontFamily: 'branding',
                    fontWeight: 'bold',
                    ...responsiveFontSizes({
                      xs: 30, sm: 30, md: 30, lg: 30,
                    }),
                  }}
                >
                  Dashboard
                </Typography>
              </Box>
              <form
                onSubmit={handleSubmit(onSubmit)}
              >
                <Stack spacing={2.5} sx={{ alignItems: 'center' }}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <HigherTextInput
                        {...field}
                        onInput={() => { clearErrors(); }}
                        fullWidth
                        label="Email"
                        error={get(errors, 'status.message', undefined)}
                        helperText={get(error, 'message', undefined)}
                      />
                    )}
                  />

                  <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        onInput={() => { clearErrors(); }}
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPassword} edge="end">
                                <Iconify icon={showPassword ? viewIcon : viewOff} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={get(errors, 'status.message', undefined)}
                        helperText={get(errors, 'status.message', undefined)}
                      />
                    )}
                  />

                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                    sx={{
                      width: '100%',
                      mx: { xs: 'auto !important', md: 'unset !important' },
                      backgroundColor: '#000000',
                    }}
                  >
                    Login
                  </LoadingButton>
                </Stack>
              </form>
            </Paper>
          </Grid>

        </Grid>
      </Container>
    </Box>
  );
}
