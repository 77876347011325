/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { format } from 'date-fns';
import {
  Box, Card, CardHeader, Stack,
  ListItemText, Divider, Autocomplete, TextField, Button,
} from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FormProvider } from 'react-hook-form';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, isArray } from 'lodash';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { useTheme, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Unstable_Grid2';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import { protectedFetch } from '../../utils/network';
import TotalGraph from './TotalGraph';

export const colorMap = {
  addUserToGroup: '#2D7DD2',
  removeUserFromGroup: '#97CC04',
  createVoiceEnrollment: '#EEB902',
  createVoiceEnrollmentByURL: '#F45D01',
  createFaceEnrollment: '#84A59D',
  createFaceEnrollmentByURL: '#F5CAC3',
  createVideoEnrollment: '#F28482',
  createVideoEnrollmentByURL: '#FD5200',
  createGroup: '#00CFC1',
  createUser: '#00FFE7 ',
  deleteAllVoiceEnrollments: '#ACC18A',
  deleteAllFaceEnrollments: '#DAFEB7',
  deleteAllVideoEnrollments: '#EE7B30',
  deleteAllEnrollmentsForUser: '#885A89',
  deleteVoiceEnrollment: '#8AA8A1',
  deleteFaceEnrollment: '#02111B',
  deleteVideoEnrollment: '#1CFEBA',
  deleteGroup: '#CB793A',
  deleteUser: '#FF11FF',
  voiceVerification: '#C3D2D5',
  voiceVerificationByURL: '#294936',
  faceVerification: '#426A5A',
  faceVerificationByURL: '#3E6259',
  videoVerification: '#f47560',
  videoVerificationByURL: '#e8c1a0',
  getVoiceEnrollments: '#e8a838',
  getFaceEnrollments: '#61cdbb',
  getVideoEnrollments: '#97e3d5',
  userExists: '#f1e15b',
  getUsers: '#003459',
  getPhrases: '#91818A',
  getGroup: '#C2F9BB',
  getGroups: '#474B24',
  getGroupsForUser: '#A9A587',
  groupExists: '#0077B6',
  voiceIdentification: '#7DBA84',
  voiceIdentificationByURL: '#f7b54as',
  faceIdentification: '#9CFFFA',
  faceIdentificationByURL: '#3F403F',
  videoIdentification: '#F2D0A4',
  videoIdentificationByURL: '#FDE74C',
};

export default function TotalGraphWithSelector() {
  const defaultAPIKey = useSelector((state) => state.loginReducer.apiKey);
  const isSuperUser = useSelector((state) => state.loginReducer.isSuperUser);
  const [apiKeys, setAPIKeys] = useState([]);
  const [endDate, setEndDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(moment().startOf('day'));
  const [selectedAPIKey, setSelectedAPIKey] = useState('');
  // kind of useless for our purposes but keeps the alerts away
  const [selectedAPIKeyInputValue, setSelectedAPIKeyInputValue] = React.useState('');
  const [series, setSeries] = useState([]);
  const [xAxisTimes, setXAxisTimes] = useState([]);

  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  useEffect(() => {
    // TODO: check that all is not just for super users
    const url = (
      '/api/graphable_developers'
    );
    const apiKeysFetch = protectedFetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    apiKeysFetch.then((apiKeysInfo) => {
      if (apiKeysInfo !== null) {
        if (!apiKeysInfo.success) {
          handleSetGenericAlertDialogFromGenericResponse(apiKeysInfo);
          return;
        }
        if (isArray(apiKeysInfo.developers)) {
          setAPIKeys(apiKeysInfo.developers);
        }
      }
      const st = startDate.clone().utc().format('YYYY-MM-DD HH:mm:ss');
      const et = endDate.clone().utc().format('YYYY-MM-DD HH:mm:ss');
      const graphUrl = (
        // eslint-disable-next-line quotes
        `/api/two/${isSuperUser ? 'all' : defaultAPIKey}/graph_total`
      );
      const graphFetch = protectedFetch(graphUrl, {
        method: 'post',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ startTime: st, endTime: et, apiKey: 'all' }),
      });
      graphFetch.then((graphJson) => {
        if (graphJson !== null) {
          if (!graphJson.success) {
            handleSetGenericAlertDialogFromGenericResponse(graphJson);
            return;
          }
          if (graphJson.xAxisTimes !== null) {
            setXAxisTimes(get(graphJson, 'xAxisTimes', []));
          }
          if (graphJson.series !== null) {
            setSeries(get(graphJson, 'series', []));
          }
          setIsLoading(false);
        }
      });
    });
  }, []);

  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      const st = startDate.clone().utc().format('YYYY-MM-DD HH:mm:ss');
      const et = endDate.clone().utc().format('YYYY-MM-DD HH:mm:ss');
      const graphUrl = (
        // eslint-disable-next-line quotes
        `/api/two/${get(selectedAPIKey, 'value', '')}/graph_total`
      );
      // console.log(graphUrl);
      // console.log(st, et);
      const graphFetch = await protectedFetch(graphUrl, {
        method: 'post',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ startTime: st, endTime: et, apiKey: 'all' }),
      });
      graphFetch.then((graphJson) => {
        if (graphJson !== null) {
          if (!graphJson.success) {
            handleSetGenericAlertDialogFromGenericResponse(graphJson);
            return;
          }
          setXAxisTimes(get(graphJson, 'xAxisTimes', []));
          setSeries(get(graphJson, 'series', []));
        }
      });
      // handleSubmitPhrase({ header: 'Phrase Request Info', text: result });
    } catch (err) {
      // handleSubmitPhrase({ header: 'Phrase Request Info', text: err.message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Grid xs={12} md={6} lg={8}>
        <TotalGraph
          isLoading={isLoading}
          title={`API 2.0 Calls (${get(selectedAPIKey, 'value', '') || (isSuperUser ? 'All Accounts' : defaultAPIKey)})`}
          subheader="Sum of each call type vs time"
          series={series}
          categories={xAxisTimes}
        />
      </Grid>
      <Grid xs={12} md={6} lg={4}>
        <FormProvider>
          <Card
            data-aos="fade-left"
          >
            <CardHeader
              title="API 2.0 Calls Data Selection"
              subheader="Enter API Key and Date Ranges"
            // action={<CarouselArrows onNext={carousel.onNext} onPrev={carousel.onPrev} />}
            />
            <Divider sx={{ borderStyle: 'dashed' }} />

            <Stack direction="column" sx={{ mx: 3, mt: 2 }} spacing={2}>
              <Autocomplete
                fullWidth
                value={selectedAPIKey}
                onChange={(event, newValue) => {
                  setSelectedAPIKey(newValue);
                  // handleAPIKeySelection(get(newValue, 'value', ''));
                }}
                inputValue={selectedAPIKeyInputValue}
                onInputChange={(event, newInputValue) => {
                  setSelectedAPIKeyInputValue(newInputValue);
                }}
                options={apiKeys}
                autoHighlight
                getOptionLabel={(option) => (option ? `${option.key} ${option.email}` : '')}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ color: '#000000' }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                  >
                    {option.key}
                    <span style={{ marginLeft: '16px', color: '#505050' }}>
                      {` ${get(option, 'email', '')}`}
                    </span>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label="API Key"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <DesktopDateTimePicker
                sx={{ width: '100%' }}
                label="Start date"
                ampm={false}
                disableFuture
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
              />
              <DesktopDateTimePicker
                sx={{ width: '100%' }}
                label="End date"
                ampm={false}
                disableFuture
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              />
            </Stack>

            <Stack
              spacing={2}
              sx={{
                p: 3,
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={() => handleRefresh()}
              >
                Refresh
              </Button>
            </Stack>
          </Card>
        </FormProvider>
      </Grid>
    </>

  );
}
