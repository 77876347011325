/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Container, Card, CardHeader, Stack,
  Divider, Button,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, isArray } from 'lodash';

import Grid from '@mui/material/Unstable_Grid2';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import { protectedFetch } from '../../utils/network';

export const countries = [
  { code: '', label: '', phone: '' },
  { code: 'AD', label: 'Andorra', phone: '376' },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
  },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  {
    code: 'AG',
    label: 'Antigua and Barbuda',
    phone: '1-268',
  },
];

const schema = Yup.object().shape({
  vppText: Yup.string().email().required(),
  contentLanguage: Yup.string().required(),
});

export default function RequestNewPhrase() {
  const {
    register, handleSubmit, formState: { errors }, reset, control,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [contentLanguages, setContentLanguages] = useState([]);

  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  useEffect(() => {
    const url = (
      '/api/content_languages'
    );
    const contentLanguagesFetch = protectedFetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    contentLanguagesFetch.then((contentLanguagesInfo) => {
      if (contentLanguagesInfo !== null) {
        if (!contentLanguagesInfo.success) {
          handleSetGenericAlertDialogFromGenericResponse(contentLanguagesInfo);
          return;
        }
        if (isArray(contentLanguagesInfo.contentLanguages)) {
          setContentLanguages(contentLanguagesInfo.contentLanguages);
        }
      }
    });
  }, []);

  const [newText, setNewText] = useState('');
  const [selectedLanguage, setNewLanguage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleTextChange = (event) => {
    setNewText(event.target.value);
  };

  const handleLanguageChange = (event) => {
    setNewLanguage(event.target.value);
  };

  const handleRequestNewPhrase = async () => {
    setIsLoading(true);
    try {
      const response = await protectedFetch('/api/request_new_phrase', {
        method: 'post',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          vppText: newText,
          contentLanguage: selectedLanguage,
        }),
      });
      if (response !== null) {
        handleSetGenericAlertDialogFromGenericResponse(response);
      }
      // handleSubmitPhrase({ header: 'Phrase Request Info', text: result });
    } catch (err) {
      // handleSubmitPhrase({ header: 'Phrase Request Info', text: err.message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider>

      <Container maxWidth="xl">
        <Grid
          data-aos="fade-right"
          container
          spacing={3}
        >
          <Grid md={6}>
            <Card>
              <CardHeader
                title="Phrase Details"
                subheader="Enter text and content language"
              // action={<CarouselArrows onNext={carousel.onNext} onPrev={carousel.onPrev} />}
              />
              <Divider sx={{ borderStyle: 'dashed' }} />

              <Stack direction="column" sx={{ mx: 3, mt: 2 }} spacing={2}>
                <FormControl>
                  <TextField
                    labelId="phrase-disabled-label"
                    id="phrase-disabled"
                    label="Text"
                    value={newText}
                    onChange={handleTextChange}
                  />
                </FormControl>
                <FormControl>
                  <InputLabel id="content-language-label">Content Language</InputLabel>
                  <Select
                    label="Content Language"
                    labelId="content-language-disabled-label"
                    id="content-language-disabled"
                    onChange={handleLanguageChange}
                  >
                    {contentLanguages.map((language) => (
                      <MenuItem
                        key={`${language.description}${language.languageCode}`}
                        value={language.languageCode}
                      >
                        {language.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

              <Stack
                spacing={2}
                sx={{
                  p: 3,
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleRequestNewPhrase()}
                >
                  Request
                </Button>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </FormProvider>
  );
}
