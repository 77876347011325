/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import {
  Container, Card, CardHeader, Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, isArray } from 'lodash';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import Grid from '@mui/material/Unstable_Grid2';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import { protectedFetch } from '../../utils/network';
import Iconify from '../Iconify';
import ReportSummary from './ReportSummary';
import { BLUR_KEYS_AND_TOKENS } from '../../utils/cssStyles';

export default function GeneratedReport() {
  const {
    apiKey, startTime, endTime, useAudioData, useVisualData,
  } = useParams();
  // TODO: add restraints on allowed date ranges, also make these params encrypted
  const navigate = useNavigate();
  const location = useLocation();
  const { phrase, contentLanguage } = location.state;
  console.log('location.state');
  console.log(location.state);
  const [accountName, setAccountName] = useState('');
  const [users, setUsers] = useState([]);
  const [suspiciousUserStats, setSuspiciousUserStats] = useState({
    userCount: 0, rejectionRateSum: 0,
  });
  const [regularUserStats, setRegularUserStats] = useState({ userCount: 0, rejectionRateSum: 0 });

  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  useEffect(() => {
    const url = (
      '/api/rejection_rate_report_users'
    );
    const formData = new FormData();
    formData.append('startDate', startTime);
    formData.append('endDate', endTime);
    formData.append('apiKey', apiKey);
    formData.append('includeFace', useVisualData === 'true');
    formData.append('includeVoice', useAudioData === 'true');
    formData.append('contentLanguage', contentLanguage);
    formData.append('phrase', phrase);
    const reportFetch = protectedFetch(url, {
      method: 'post',
      headers: {
        accept: 'application/json',
      },
      body: formData,
    });
    reportFetch.then((reportInfo) => {
      if (reportInfo !== null) {
        if (!reportInfo.success) {
          handleSetGenericAlertDialogFromGenericResponse(reportInfo);
          return;
        }
        setAccountName(get(reportInfo, 'devName', 'NA'));
        setUsers(get(reportInfo, 'users', []));
        console.log(get(reportInfo, 'users', []));
        if (isArray(get(reportInfo, 'users', false))) {
          let susUserCount = 0;
          let susRrTotal = 0;
          let regUserCount = 0;
          let regRrTotal = 0;
          for (let user = 0; user < get(reportInfo, 'users.length', 0); user += 1) {
            if (reportInfo.users[user].isSuspicious === true) {
              susUserCount += 1;
              susRrTotal += (100 * parseFloat(reportInfo.users[user].failCount))
                / ((parseFloat(reportInfo.users[user].succCount)
                  + parseFloat(reportInfo.users[user].failCount)) || 1.0);
            } else {
              regUserCount += 1;
              regRrTotal += (100 * parseFloat(reportInfo.users[user].failCount))
                / ((parseFloat(reportInfo.users[user].succCount)
                  + parseFloat(reportInfo.users[user].failCount)) || 1.0);
            }
          }
          setSuspiciousUserStats({
            userCount: susUserCount,
            rejectionRateSum: susRrTotal,
          });
          setRegularUserStats({
            userCount: regUserCount,
            rejectionRateSum: regRrTotal,
          });
        }
      }
    });
  }, []);

  const downloadPDF = () => {
    const startDate = moment(startTime);
    const endDate = moment(endTime);
    const fileName = `${accountName.replace(/\s/g, '')}_${startDate.format('MM_DD_YYYY_HH_mm')}_to_${endDate.format('MM_DD_YYYY_HH_mm')}.pdf`;
    const title = `${accountName} from ${startDate.format('MM/DD/YYYY HH:mm')} to ${endDate.format('MM/DD/YYYY HH:mm')}`;
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    const formatDataForPDF = [];
    formatDataForPDF.push(['Regular Users', '', '', '']);
    users.forEach((item) => {
      if (item.isSuspicious === false) {
        const newEntry = [];
        newEntry.push(
          item.userId,
          item.succCount,
          item.failCount,
          item.isSuspicious,
        );
        formatDataForPDF.push(newEntry);
      }
    });
    formatDataForPDF.push([`Regular User Count: ${regularUserStats.userCount}`, '', '', '']);
    // formatDataForPDF.push(['', '', '', '']);
    formatDataForPDF.push(['Suspicious Users', '', '', '']);
    users.forEach((item) => {
      if (item.isSuspicious === true) {
        const newEntry = [];
        newEntry.push(
          item.userId,
          item.succCount,
          item.failCount,
          item.isSuspicious,
        );
        formatDataForPDF.push(newEntry);
      }
    });
    formatDataForPDF.push([`Suspicious User Count: ${suspiciousUserStats.userCount}`, '', '', '']);

    doc.setFontSize(12);
    doc.text(title, 14, 20);
    doc.text(`Phrase: ${phrase}, Content Language: ${contentLanguage}`, 14, 30);
    doc.setFontSize(14);

    // doc.setFontSize(14);
    doc.autoTable({
      startY: 40,
      head: [['UserID', 'SuccCount', 'FailCount', 'isSuspicious']],
      headStyles: {
        fillColor: [252, 188, 61],
      },
      theme: 'grid',
      body: formatDataForPDF,
    });

    doc.save(fileName);
  };

  const columns = [
    { field: 'userId', headerName: 'User ID', flex: 2 },
    { field: 'succCount', headerName: 'Success Count', flex: 1 },
    { field: 'failCount', headerName: 'Fail Count', flex: 1 },
    {
      field: 'rejectionRate',
      headerName: 'Rejection Rate',
      flex: 1,
      valueGetter: (params) => (100 * parseFloat(params.row.failCount))
        / ((parseFloat(params.row.succCount) + parseFloat(params.row.failCount)) || 1.0),
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toFixed(2)}%`;
      },
    },
    { field: 'isSuspicious', headerName: 'Is Suspicious', flex: 1 },
  ];

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
      >
        <Grid md={5}>
          <Card
            sx={{ p: 3 }}
            data-aos="fade-right"
          >
            <CardHeader title="Report Parameters" sx={{ p: 0, mb: 3 }} />
            <Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  API Key
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    filter: BLUR_KEYS_AND_TOKENS ? 'blur(3px)' : 'none',
                  }}
                >
                  {apiKey}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Phrase
                </Typography>
                <Typography
                  variant="body2"
                >
                  {phrase}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Content Language
                </Typography>
                <Typography
                  variant="body2"
                >
                  {contentLanguage}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Time Range Start Time
                </Typography>
                <Typography variant="body2">{moment(startTime).format('MMMM Do YYYY, h:mm:ss a')}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Time Range End Time
                </Typography>
                <Typography variant="body2">{moment(endTime).format('MMMM Do YYYY, h:mm:ss a')}</Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Use Audio Data
                </Typography>
                <Iconify
                  width={16}
                  icon={useAudioData === 'true' ? 'carbon:checkmark-filled' : 'carbon:error-filled'}
                  sx={{ color: useAudioData === 'true' ? '#75B09C' : '#F17300' }}
                />
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Use Visual Data
                </Typography>
                <Iconify
                  width={16}
                  icon={useVisualData === 'true' ? 'carbon:checkmark-filled' : 'carbon:error-filled'}
                  sx={{ color: useVisualData === 'true' ? '#75B09C' : '#F17300' }}
                />
              </Stack>
            </Stack>
          </Card>
        </Grid>
        <ReportSummary
          regularUserCount={get(regularUserStats, 'userCount', 0)}
          regularUserRejectionRateSum={get(regularUserStats, 'rejectionRateSum', 0)}
          susUserCount={get(suspiciousUserStats, 'userCount', 0)}
          susUserRejectionRateSum={get(suspiciousUserStats, 'rejectionRateSum', 0)}
          handlePdfDownload={downloadPDF}
        />
        <Grid md={12}>
          <Card
            data-aos="fade-up"
          >
            <CardHeader
              title="Rejection Rate Report"
              subheader={`Rejection Rate Report for ${accountName}`}
              sx={{ mb: 3 }}
            />
            <DataGrid
              rows={users}
              columns={columns}
              getRowId={(row) => row.userId}
              onRowClick={(event) => navigate(`/accounts/${apiKey}/${event.id}`, { state: { startTime, endTime } })}
            />

          </Card>
        </Grid>
      </Grid>
    </Container>

  );
}
