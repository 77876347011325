import { pxToRem, responsiveFontSizes, responsiveFontSizes2 } from '../utils/getFontValue';

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'helveticaNow';
const FONT_SECONDARY = 'branding';

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightSemiBold: 600,
  h1: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(70),
    ...responsiveFontSizes({ xs: 100, sm: 104, md: 116, lg: 128 }),
  },
  h2: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 700,
    lineHeight: 1,
    fontSize: pxToRem(28),
    ...responsiveFontSizes({ xs: 16, sm: 20, md: 24, lg: 36 }),
  },
  h3: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ xs: 22, sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 'bold',
    lineHeight: 64 / 48,
    fontSize: pxToRem(19),
    ...responsiveFontSizes({ xs: 16, sm: 20, md: 25, lg: 32 }),
  },
  h5: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ xs: 14, sm: 18, md: 18, lg: 18 }),
  },
  h6: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 700,
    lineHeight: 1,
    fontSize: pxToRem(28),
    ...responsiveFontSizes({ xs: 20, sm: 20, md: 20, lg: 20 }),
    // lineHeight: 28 / 18,
    // fontSize: pxToRem(17),
    // ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 28 / 16,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 26 / 14,
    fontSize: pxToRem(14),
  },
  subtitle3: {
    fontWeight: 600,
    lineHeight: 24 / 13,
    fontSize: pxToRem(13),
  },
  body1: {
    lineHeight: 28 / 16,
    fontSize: pxToRem(18),
  },
  body2: {
    lineHeight: 26 / 14,
    fontSize: pxToRem(14),
  },
  body3: {
    lineHeight: 24 / 13,
    fontSize: pxToRem(13),
  },
  caption: {
    lineHeight: 20 / 12,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 600,
    lineHeight: 20 / 12,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 600,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
  },
};

export default typography;
