/* eslint-disable react/prop-types */
import React from 'react';
import Label from '../Label/Label';

export default function ResponseCodeLabel({ responseCode }) {
  let backgroundCol = '#FCBC3D';
  let color = '#000000';
  if (responseCode === 'SUCC') {
    backgroundCol = '#248232';
    color = '#FFFFFF';
  } else if (responseCode === 'FAIL') {
    backgroundCol = '#DD403A';
    color = '#FFFFFF';
  }
  return (
    <Label
      variant="soft"
      sx={{
        backgroundColor: backgroundCol,
        color,
      }}
    >
      {responseCode}
    </Label>
  );
}
