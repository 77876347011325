/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { format } from 'date-fns';
import {
  Box, Container, Card, CardHeader, Stack, Table, TableBody, TableCell,
  TableHead, TableRow, Typography, TableContainer, ListItemText, Divider,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, isArray } from 'lodash';
import { useTheme, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Unstable_Grid2';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import { protectedFetch } from '../../utils/network';
import formatBytes from '../../utils/math';

const phraseListColumns = [
  {
    field: 'vppText',
    headerName: 'Phrase Text',
    flex: 5,
    minWidth: 100,
    editable: true,
  },
  {
    field: 'languageCode',
    headerName: 'Language Code',
    flex: 2,
    minWidth: 200,
    editable: true,
  },
  {
    field: 'approved',
    type: 'boolean',
    headerName: 'Approved',
    description: 'Phrases that have not been approved can be resubmitted again',
    sortable: false,
    flex: 1,
    minWidth: 100,
  },
];

export default function PhraseList() {
  const [phrases, setPhrases] = useState([]);
  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);
  useEffect(() => {
    const url = (
      '/api/developer_phrases_list'
    );
    const phrasesFetch = protectedFetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    phrasesFetch.then((phrasesInfo) => {
      if (phrasesInfo !== null) {
        if (!phrasesInfo.success) {
          handleSetGenericAlertDialogFromGenericResponse(phrasesInfo);
        } else if (phrasesInfo.phrases.length > 0) {
          setPhrases(phrasesInfo.phrases);
        }
      }
    });
  }, []);

  return (
    <Container maxWidth="xl">
      <Grid
        data-aos="fade-right"
        container
        spacing={3}
      >
        <Grid md={12} sx={{}}>
          <Card>
            <CardHeader title="Phrases" subheader="Phrases and content languages associated with your account" sx={{ mb: 3 }} />

            <DataGrid
              rows={phrases}
              columns={phraseListColumns}
              getRowId={(row) => `${row.vppText}${row.languageCode}`}
            />

          </Card>
        </Grid>
      </Grid>
    </Container>

  );
}
