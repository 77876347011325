/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { format } from 'date-fns';
import {
  Container, Card, Stack, ListItemText, TextField, InputAdornment, Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, isArray } from 'lodash';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Unstable_Grid2';
import { protectedFetch } from '../../utils/network';
import Iconify from '../Iconify';
import CustomBreadcrumbs from '../CustomBreadCrumbs/CustomBreadCrumbs';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import { BLUR_KEYS_AND_TOKENS, blurSettingsForTables } from '../../utils/cssStyles';

export default function APIKeys() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState('');
  const [filt, setFilt] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  useEffect(() => {
    const url = (
      '/api/developers'
    );
    const accountFetch = protectedFetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    accountFetch.then((accountsInfo) => {
      if (accountsInfo !== null) {
        if (!accountsInfo.success) {
          handleSetGenericAlertDialogFromGenericResponse(accountsInfo);
          return;
        }
        if (isArray(accountsInfo.developers)) {
          setAccounts(accountsInfo.developers);
        }
        setIsLoading(false);
      }
    });
  }, []);

  const columns = [
    {
      field: 'email',
      headerName: 'Email',
      flex: 2,
      cellClassName: BLUR_KEYS_AND_TOKENS ? 'blur-info' : undefined,
    },
    {
      field: 'apiKey',
      headerName: 'API Key',
      width: 400,
      cellClassName: BLUR_KEYS_AND_TOKENS ? 'blur-info' : undefined,
    },
    {
      field: 'updatedAt',
      headerName: 'Last Activity',
      flex: 1,
      renderCell: (param) => (
        <ListItemText
          primary={format(new Date(get(param, 'value', '')), 'dd MMM yyyy')}
          secondary={format(new Date(get(param, 'value', '')), 'HH:mm:ss')}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      ),
    },
  ];

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
      >
        <Grid
          data-aos="fade-in"
          md={12}
          sx={{}}
        >
          <CustomBreadcrumbs
            heading="Accounts"
            links={[
              {
                name: 'API Keys Associated with your account',
              },
            ]}
            sx={{
              mb: { xs: 3, md: 3 },
            }}
          />
          <Card>
            <Stack
              spacing={2}
              alignItems={{ xs: 'flex-end', md: 'center' }}
              direction={{
                xs: 'column',
                md: 'row',
              }}
              sx={{
                p: 2,
              }}
            >
              <TextField
                fullWidth
                value={filter}
                onChange={(event) => {
                  setFilter(event.target.value);
                  setFilt([
                    // {
                    //   field: 'apiKey',
                    //   operator: 'contains',
                    //   id: 95962,
                    //   value: event.target.value,
                    //   fromInput: ':r1j:',
                    // },
                    {
                      field: 'email',
                      operator: 'contains',
                      id: 95962,
                      value: event.target.value,
                      fromInput: ':r1j:',
                    },
                  ]);
                }}
                label="Search by Email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  ),
                }}
              />

            </Stack>
            <DataGrid
              data-aos="fade-in"
              rows={accounts}
              columns={columns}
              autoHeight
              loading={isLoading}
              getRowId={(row) => row.apiKey}
              onRowClick={(event) => navigate(`/accounts/${event.id}`)}
              filterModel={{
                items: filt,
                logicOperator: 'and',
                quickFilterValues: [],
                quickFilterLogicOperator: 'and',
              }}
              sx={blurSettingsForTables}
            />

          </Card>
        </Grid>
      </Grid>
    </Container>

  );
}
