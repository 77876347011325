import { get } from 'lodash';
import store from '../reduxStore/index';
import { setExpiredToken } from '../reduxStore/actions/errorActions';

export async function getCSRFToken() {
  try {
    const response = await fetch('/api/csrf_token');
    if (!response.ok) {
      if (response.status === 401) {
        console.log('response code is 401');
        store.dispatch(setExpiredToken(true));
      }
      throw new Error(`HTTP error: ${response.status}`, { responseStatus: response.status });
    }
    const incomingData = await response.json();
    // console.log(incomingData);
    return (get(incomingData, 'csrfToken', ''));
  } catch (error) {
    console.log(error);
    return null;
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function protectedFetch(endpoint, argOptions) {
  const options = { ...argOptions };
  try {
    const csrfToken = await getCSRFToken();
    if (!csrfToken) {
      throw new Error('Did not get token');
    }
    if (options.headers === undefined) {
      options.headers = {
        'X-CSRF-TOKEN': csrfToken,
      };
    } else {
      options.headers['X-CSRF-TOKEN'] = csrfToken;
    }

    const response = await fetch(endpoint, options);

    // Also handle http.StatusUnauthorized here as well
    // as in GetUserToken in case CSRF token works, but not JWT cookie
    if (!response.ok) {
      if (response.status === 401) {
        console.log('response code is 401');
        store.dispatch(setExpiredToken(true));
      }
      console.log(`HTTP error: ${response.status}`);
      // throw new Error(`HTTP error: ${response.status}`, { responseStatus: response.status });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return null;
  }
}
