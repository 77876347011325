/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  Container, Divider, Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
// import { Icon as IconifyIcon } from '@iconify/react';
import Player from '../../Player/Player';

import accessMenu1 from './AccessMenu1.png';
import accessMenu2 from './AccessMenu2.png';
import chooseDataType from './ChooseDataType.png';
import chooseDateRange from './ChooseDateRange.png';
import clickGenerate from './ClickGenerate.png';
import downloadPdf from './DownloadPdf.png';
import selectApiKey from './SelectApiKey.png';
import selectPhrase from './SelectPhrase.png';
import table from './Table.png';

import videoTutorial from './RejectionRateReport.mov';
import CustomBreadcrumbs from '../../CustomBreadCrumbs/CustomBreadCrumbs';
import TutorialStep from '../TutorialStep';

const steps = [
  {
    title: 'Access the Menu',
    stepImg: accessMenu1,
    stepInfo: 'To Access the Rejection Rate Report Menu, click the corresponding section in the left hand menu to expand the dropdown',
  },
  {
    title: 'Access the Menu2',
    stepImg: accessMenu2,
    stepInfo: 'In the dropdown, select new session',
  },
  {
    title: 'Select API Key',
    stepImg: selectApiKey,
    stepInfo: 'Select the API Key you would like to use',
  },
  {
    title: 'Select Phrase and Content Language',
    stepImg: selectPhrase,
    stepInfo: 'Select phrase and content language you would like to use. Note that this can only be selected after API Key. This is also only relevant for audio data such as voice and video calls.',
  },
  {
    title: 'Select Date ranges',
    stepImg: chooseDateRange,
    stepInfo: 'Choose the date ranges for the user activity. If the account has a lot of activity, it works better to choose a smaller date range because the server will timeout if there is too much data to go through in the database',
  },
  {
    title: 'Select Data Type',
    stepImg: chooseDataType,
    stepInfo: 'By default, the report will only consider audio recordings; however, you can also choose to include visual recordings, such as face identification calls',
  },
  {
    title: 'Click Generate',
    stepImg: clickGenerate,
    stepInfo: 'When ready, click on \'Generate\' to begin',
  },
  {
    title: 'Generated Table',
    stepImg: table,
    stepInfo: 'When the report is generated, you will be presented with the list of users that were active in the selected date range. Each entry contains how many successful or failed verifications a user has, and the ratio of the two. If the user has disproportionate failed attempts, they will be marked as suspicious. There are a lot of reasons why this might happen, but often it comes down to bad enrollments.',
  },
  {
    title: 'Download PDf',
    stepImg: downloadPdf,
    stepInfo: 'If you wish to save the report for later, click the "Download PDF" button on the top right section of the screen',
  },
];

export default function RejectionRateReportTutorial() {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
      >
        <Grid md={12} sx={{}}>
          <CustomBreadcrumbs
            heading="Rejection Rate Report Tutorial"
            links={[
              {
                name: 'Tutorials',
                href: '/tutorials',
              },
              {
                name: 'Rejection Rate Report',
              },
            ]}
            sx={{
              mb: { xs: 3, md: 3 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Player
            url={videoTutorial}
            width="100%"
            height="100%"
            controls
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 6 },
          pb: { xs: 6, md: 6, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={3}
            justifyContent={{ md: 'center' }}
          >

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                How to generate a report on successful vs
                unsuccessful verification attempts of your users
              </Typography>
            </Grid>

            {steps.map((step, index) => (
              <Grid
                item
                xs={12}
                md={8}
                key={step.title}
              >
                <TutorialStep
                  step={step}
                  stepNumber={index + 1}
                />
                <Divider sx={{ pt: 2.5, borderStyle: 'dashed' }} />

              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Container>
  );
}
