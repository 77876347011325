import './App.css';
import React from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';

import {
  createTheme,
} from '@mui/material';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ThemeProvider from './theme';
import Home from './components/Home/Home';
import Login from './components/Login/Login';
import Accounts from './components/Accounts/APIKeys';
import UserIdsAndGroups from './components/Accounts/UserIdsAndGroups';
import RequestNewPhrase from './components/PhraseManagement/RequestNewPhrase';
import NewSession from './components/RejectionReport/NewSession';
import Files from './components/Files/Files';
import Licenses from './components/Licenses/Licenses';
import PhraseList from './components/PhraseManagement/PhraseList';
import ApproveRejectPhrases from './components/PhraseManagement/ApproveRejectPhrases';
import NewFineTuningSession from './components/FineTuning/NewFineTuningSession';
import CurrentlyRunning from './components/FineTuning/CurrentlyRunning';
import PreviousResults from './components/FineTuning/PreviousResults';
import Calls from './components/Accounts/Calls';
import SupportDetail from './components/Accounts/SupportDetail';
import LayoutWithNavigation from './components/LayoutWithNavigation/LayoutWithNavigation';
import store from './reduxStore/index';
import GeneratedReport from './components/RejectionReport/GeneratedReport';
import DeveloperSettings from './components/DeveloperSettings/DeveloperSettings';
import { GenericAlertDialogProvider } from './contexts/GenericAlertDialogContext';
import { FineTuningProvider } from './contexts/FineTuningContext';
import LogDetails from './components/LogDetails/LogDetails';
import Tutorials from './components/Tutorials/Tutorials';
import DeveloperSettingsTutorial from './components/Tutorials/DeveloperSettings/DeveloperSettingsTutorial';
import AccountsTutorial from './components/Tutorials/Accounts/AccountsTutorial';
import RejectionRateReportTutorial from './components/Tutorials/RejectionRateReport/RejectionRateReportTutorial';
import PhraseManagerTutorial from './components/Tutorials/PhraseManager/PhraseManagerTutorial';
import FineTuningTutorial from './components/Tutorials/FineTuning/FineTuningTutorial';
import SupportAccess from './components/SupportAccess/SupportAccess';

const persistor = persistStore(store);

const appTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#7F5CAD',
    },
    culturedWhite: {
      main: '#EFEFEF',
    },
    carolinaBlue: {
      main: '#75B09C',
    },
    safetyOrange: {
      main: '#F17300',
    },
  },
});

function App() {
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    AOS.init({
      once: true,
      delay: 0,
      duration: 800,
      offset: 0,
      easing: 'ease-in-out',
    });
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={appTheme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <GenericAlertDialogProvider>
              <FineTuningProvider>
                <Routes>
                  <Route>
                    <Route path="/login" element={<Login />} />
                  </Route>
                  <Route element={<LayoutWithNavigation />}>
                    <Route path="/home" element={<Home />} />

                    <Route path="/tutorials" element={<Tutorials />} />
                    <Route path="/support_access" element={<SupportAccess />} />
                    <Route path="/developer_settings_tutorial" element={<DeveloperSettingsTutorial />} />
                    <Route path="/accounts_tutorial" element={<AccountsTutorial />} />
                    <Route path="/rejection_rate_report_tutorial" element={<RejectionRateReportTutorial />} />
                    <Route path="/phrase_manager_tutorial" element={<PhraseManagerTutorial />} />
                    <Route path="/fine_tuning_tutorial" element={<FineTuningTutorial />} />

                    <Route path="/log_details/:apiKey/:userId/:supportId/:logType" element={<LogDetails />} />
                    <Route path="/accounts/:apiKey/:userId/:supportId" element={<SupportDetail />} />
                    <Route path="/accounts/:apiKey/:userId" element={<Calls />} />
                    <Route path="/accounts/:apiKey" element={<UserIdsAndGroups />} />
                    <Route path="/accounts" element={<Accounts />} />

                    <Route path="/phrase_manager/request_new_phrase" element={<RequestNewPhrase />} />
                    <Route path="/phrase_manager/phrase_list" element={<PhraseList />} />
                    <Route path="/phrase_manager/approve_reject_phrases" element={<ApproveRejectPhrases />} />

                    <Route path="/rejection_report/report/:apiKey/:startTime/:endTime/:useAudioData/:useVisualData" element={<GeneratedReport />} />
                    <Route path="/rejection_report/new_session" element={<NewSession />} />
                    <Route path="/fine_tuning/new_session" element={<NewFineTuningSession />} />
                    <Route path="/fine_tuning/currently_running" element={<CurrentlyRunning />} />
                    <Route path="/fine_tuning/previous_results" element={<PreviousResults />} />

                    <Route path="/files" element={<Files />} />

                    <Route path="/licenses" element={<Licenses />} />
                    <Route path="/settings" element={<DeveloperSettings />} />
                    <Route path="*" element={<Home />} />
                  </Route>

                </Routes>
              </FineTuningProvider>
            </GenericAlertDialogProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
