export const identificationAndVerificationCallTypesWithVoice = [
  'voiceVerification', 'voiceVerificationByURL', 'voiceIdentification', 'voiceIdentificationByURL',
  'videoVerification', 'videoIdentification', 'videoVerificationByURL', 'videoIdentificationByURL',
];

export const verificationCallTypesWithFace = [
  'faceVerification', 'faceIdentification', 'faceVerificationByURL', 'faceIdentificationByURL',
  'videoVerification', 'videoIdentification', 'videoVerificationByURL', 'videoIdentificationByURL',
];

export const identificationCallTypes = [
  'voiceIdentification', 'voiceIdentificationByURL',
  'faceIdentification', 'faceIdentificationByURL',
  'videoIdentification', 'videoIdentificationByURL',
];

export const videoFileCalls = [
  'createVideoEnrollment',
  'createVideoEnrollmentByURL',
  'createFaceEnrollment',
  'videoVerification',
  'videoVerificationByURL',
  'faceVerification',
  'faceVerificationByURL',
  'faceIdentification',
  'faceIdentificationByURL',
];

export const faceCalls = [
  'createFaceEnrollment',
  'faceVerification',
  'faceVerificationByURL',
  'faceIdentification',
  'faceIdentificationByURL',
  'faceVerificationConvertedToEnrollment',
];

export const responseCodes = {
  SUCC: 'Successful API call',
  MISP: 'Missing Parameters',
  MISU: 'Missing users',
  FBDN: 'Forbidden, please make sure to only access the API via HTTPS',
  DDNE: 'Data does not exist',
  INCP: 'Incorrect contentLanguage parameter',
  INPP: 'Incorrect phrase parameter',
  UNAC: 'Unauthorized access (make sure you are using the right API Key and Token)',
  NPFC: 'No phrases for contentLanguage',
  GNFD: 'Group not found',
  UNFD: 'User not found',
  ENFD: 'Enrollment not found',
  UDNM: 'User does not match',
  NFEF: 'No face enrollments found',
  FTMF: 'Found too many faces in video',
  IFAD: 'Incorrect formatted audio data',
  IFVD: 'Incorrect formatted video data',
  FNFD: 'Face not found in video',
  SRNR: 'Sound recording does not meet requirements',
  SSTQ: 'Speaker is speaking too quiet',
  SSTL: 'Speaker is speaking too loud',
  NEHSD: 'Not enough human speech detected',
  STTF: 'Speech to text failed',
  RWPU: 'Recording was previously used',
  GERR: 'A general error occurred',
  FAIL: 'The API call completed but failed. Example: Verification Unsuccessful',
  PNTE: 'Phrase needs a minimum of three enrollments',
  PDNM: 'Phrase does not match',
  TVER: 'Three video enrollments required',
  IEID: 'Invalid enrollmentId',
  DAID: 'Developer account is disabled ( most likely due to insufficient funds)',
  CLNE: 'Content language not enabled for free tier, only no-STT is available on the VoiceIt free tier plan',
  ACLR: 'API call limit reached',
  ACTO: 'API call timed out',
  NSPE: 'Enrollment not similar to previous enrollments',
  EMNV: 'Email not verified',
  SANF: 'Sub-account not found',
  EAEX: 'Email already exists in our system',
  EMPF: 'An empty file was passed when a non-empty file was expected',
  MLOCK: 'Your sandbox account has been locked from creating new data to prevent data drift during migration',
  INVT: 'Not a valid integer to use as a value for user token expiration time out',
};
