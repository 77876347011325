import { get } from 'lodash';
import {
  LOGIN_SUCCESS,
  CLEAR_CACHE,
} from '../actions/types';

const INITIAL_STATE = {
  apiKey: '',
  isSuperUser: false,
  hasFineTuningAccess: false,
  passwordResetNeeded: false,
  pcmEnabled: true,
  isPCMSupportAccount: false,
};

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        apiKey: get(action, 'payload.apiKey', INITIAL_STATE.apiKey),
        isSuperUser: get(action, 'payload.isSuperUser', INITIAL_STATE.isSuperUser),
        hasFineTuningAccess: get(action, 'payload.hasFineTuningAccess', INITIAL_STATE.hasFineTuningAccess),
        passwordResetNeeded: get(action, 'payload.passwordResetNeeded', INITIAL_STATE.passwordResetNeeded),
        pcmEnabled: get(action, 'payload.pcmEnabled', INITIAL_STATE.pcmEnabled),
        isPCMSupportAccount: get(action, 'payload.isPCMSupportAccount', INITIAL_STATE.isPCMSupportAccount),
      };
    case CLEAR_CACHE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
