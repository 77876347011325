import React, { useState } from 'react';
import { m } from 'framer-motion';
// @mui
import { useSelector } from 'react-redux';
import {
  Popover, Box, IconButton, MenuItem, Stack,
} from '@mui/material';
import Badge, { badgeClasses } from '@mui/material/Badge';
import { useNavigate } from 'react-router-dom';
import Iconify from '../Iconify';
import { responsiveFontSizes } from '../../utils/getFontValue';
// components
// import { varHover } from 'src/components/animate';

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function SettingsButton({ sx }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [
    pcmEnabled,
  ] = useState(useSelector((state) => state.loginReducer.pcmEnabled));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Badge
      color="error"
      variant="dot"
      invisible
      sx={{
        [`& .${badgeClasses.badge}`]: {
          top: 8,
          right: 8,
        },
        ...sx,
      }}
    >
      <Box
        component={m.div}
      // animate={{
      //   rotate: [0, settings.open ? 0 : 360],
      // }}
      // transition={{
      //   duration: 12,
      //   ease: 'linear',
      //   repeat: Infinity,
      // }}
      >
        <IconButton
          component={m.button}
          whileTap="tap"
          whileHover="hover"
          // variants={varHover(1.05)}
          // aria-label="settings"
          aria-describedby={id}
          onClick={handleClick}
          // onClick={() => {
          //   // navigate('/settings');
          //   setOpenPopOver(!openPopOver);
          // }}
          sx={{
            width: 40,
            height: 40,
          }}
        >
          <Iconify icon="carbon:settings" width={24} sx={{ color: '#000000' }} />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{ p: 0 }}
        >
          {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}
          <Stack sx={{ p: 0.5, backgroundColor: 'white' }}>
            <MenuItem
              sx={{
                fontFamily: 'branding',
                ...responsiveFontSizes({
                  xs: 16,
                }),
              }}
              onClick={() => { navigate('/settings'); }}
            >
              Account Info
            </MenuItem>
            {pcmEnabled && (
              <MenuItem
                sx={{
                  fontFamily: 'branding',
                  ...responsiveFontSizes({
                    xs: 16,
                  }),
                }}
                onClick={() => { navigate('/support_access'); }}
              >
                VoiceIt Support Remote Access
              </MenuItem>
            )}
            <MenuItem
              sx={{
                fontFamily: 'branding',
                ...responsiveFontSizes({
                  xs: 16,
                }),
              }}
              onClick={() => {
                fetch('/api/logout', {
                  method: 'post',
                  mode: 'cors',
                  credentials: 'include',
                });
                window.localStorage.clear();
                navigate('login');
              }}
            >
              Log Out
            </MenuItem>
          </Stack>
        </Popover>
      </Box>
    </Badge>
  );
}
