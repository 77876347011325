/* eslint-disable indent */
/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
// import { Icon as IconifyIcon } from '@iconify/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get, isString } from 'lodash';
import { responsiveFontSizes } from '../../utils/getFontValue';
import { StyledItem } from './NavStyles';
import VoiceItLogo from '../../assets/images/VoiceItLogo.png';
import Iconify from '../Iconify';
import NavItem from './NavItem';

const drawerWidth = 280;

export default function NavBar() {
  const navItems = [
    {
      title: 'Home',
      icon: 'carbon:home',
      path: 'home',
    },
    {
      title: 'Accounts',
      icon: 'carbon:share-knowledge',
      path: 'accounts',
    },
  ];

  const [
    hasFineTuningAccess,
  ] = useState(useSelector((state) => state.loginReducer.hasFineTuningAccess));
  const [
    isSuperUser,
  ] = useState(useSelector((state) => state.loginReducer.isSuperUser));
  const [
    pcmEnabled,
  ] = useState(useSelector((state) => state.loginReducer.pcmEnabled));

  const [
    isPCMSupportAccount,
  ] = useState(useSelector((state) => state.loginReducer.isPCMSupportAccount));

  if (isSuperUser || (pcmEnabled && isPCMSupportAccount)) {
    navItems.push(
      {
        title: 'Rejection Rate Report',
        icon: 'carbon:license-draft',
        path: 'rejection_report',
        children: [
          { title: 'New Session', path: 'rejection_report/new_session' },
          { title: 'Generated Report', path: 'rejection_report/report', disabled: true },
        ],
      },
    );
  }

  if ((!pcmEnabled && hasFineTuningAccess) || (pcmEnabled && isSuperUser) || (pcmEnabled && isPCMSupportAccount)) {
    navItems.push(
      {
        title: 'Fine Tuning',
        icon: 'carbon:tool-kit',
        path: 'fine_tuning',
        children: [
          { title: 'New Session', path: 'fine_tuning/new_session' },
          { title: 'Currently Running', path: 'fine_tuning/currently_running' },
          { title: 'Previous Results', path: 'fine_tuning/previous_results' },
        ],
      },
    );
  }

  navItems.push(
    {
      title: 'Phrase Manager',
      icon: 'carbon:spell-check',
      path: 'phrase_manager',
      children: [
        { title: 'Request New Phrase', path: 'phrase_manager/request_new_phrase' },
        { title: 'Phrase List', path: 'phrase_manager/phrase_list' },
      ],
    },
  );

  if ((!pcmEnabled && isSuperUser) || (pcmEnabled && isPCMSupportAccount)) {
    navItems[navItems.length - 1].children.push({ title: 'Approve/Reject Phrases', path: 'phrase_manager/approve_reject_phrases' });
  }

  if (!pcmEnabled) {
    navItems.push(
      {
        title: 'Licenses',
        icon: 'carbon:document-horizontal',
        path: 'licenses',
      },
      {
        title: 'Files',
        icon: 'carbon:folder',
        path: 'files',
      },
    );
  }

  const navigate = useNavigate();
  const location = useLocation();
  let locationKeywords = [];
  let primaryLocation = '';
  let secondaryLocation = '';
  if (isString(location.pathname)) {
    locationKeywords = location.pathname.split('/');
    primaryLocation = get(locationKeywords, '[1]', '');
    secondaryLocation = get(locationKeywords, '[2]', '');
  }

  return (
    <Drawer
      variant="permanent"
      ModalProps={{ keepMounted: true }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#505050',
        },
      }}
    // PaperProps={{
    //   sx: { width: drawerWidth },
    // }}
    >
      <Box sx={{ overflowX: 'auto', color: '#FFFFFF' }}>

        <Box
          sx={{
            px: 2.5,
            py: 3,
            lineHeight: 0,
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Button
            variant="text"
            onClick={() => {
              navigate('/home');
            }}
          >
            <img src={VoiceItLogo} style={{ width: '100px' }} alt="Hexagon logo" />
          </Button>
          <Typography
            variant="h5"
            sx={{
              color: '#FFFFFF',
              ...responsiveFontSizes({
                xs: 28, sm: 28, md: 28, lg: 28,
              }),
            }}
          >
            VoiceIt API 2.0
          </Typography>
        </Box>

        <List sx={{ px: 2.5 }}>
          {
            navItems.map((navItem) => (
              <NavItem
                navItemInfo={navItem}
                key={navItem.title}
                primaryLocation={primaryLocation}
                secondaryLocation={secondaryLocation}
              />
            ))
          }
        </List>
        <List sx={{ px: 2.5 }}>
          <ListItem disablePadding>
            <StyledItem onClick={() => {
              fetch('/api/logout', {
                method: 'post',
                mode: 'cors',
                credentials: 'include',
              });
              window.localStorage.clear();
              navigate('login');
            }}
            >
              <ListItemIcon>
                <Iconify sx={{ color: '#FFFFFF', fontSize: '30px' }} icon="carbon:logout" />
              </ListItemIcon>
              <ListItemText>
                <Typography sx={{ color: '#FFFFFF', fontFamily: 'branding', fontWeight: 'bold' }}>
                  Logout
                </Typography>
              </ListItemText>
            </StyledItem>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
}
