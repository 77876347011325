/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useContext } from 'react';
import {
  Paper, Box, IconButton,
} from '@mui/material';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get } from 'lodash';

import moment from 'moment';
import { protectedFetch } from '../../utils/network';
import Iconify from '../Iconify';
import FineTuningContext from '../../contexts/FineTuningContext';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';

function Item(props) {
  const {
    sx,
    // highlightFineTuningRow,
    ...other
  } = props;

  // const handleMouseOver = () => {
  // highlightFineTuningRow(fineTuningObjectId);
  // };

  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        margin: '5px',
        borderRadius: 1,
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  );
}

export default function EngineParametersHistory(props) {
  const {
    highlightFineTuningRow,
    fineTuningRowObjectIdToHighlight,
    fineTuningEngineParametersHistory,
    fineTuningRowObjectIdToExpand,
    setFineTuningRowObjectIdToExpand,
  } = props;

  const {
    selectedAccount,
    selectedPhrase,
  } = useContext(FineTuningContext);

  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  const resetEngineSettings = () => {
    try {
      const formData = new FormData();
      formData.append('apiKey', selectedAccount.key);
      formData.append('vsitCustomPhrasesId', selectedPhrase.vsitCustomPhrasesId);
      const url = (
        '/api/reset_custom_engine_settings'
      );
      const fetch = protectedFetch(url, {
        method: 'post',
        headers: {
          accept: 'application/json',
        },
        body: formData,
      });
      fetch.then((response) => {
        handleSetGenericAlertDialogFromGenericResponse(response);
      });
    } catch (err) {
      console.log('handleFindClick catch Exception: ', err);
      // handleFindClick({ header: 'Phrase Request Info', text: err.message });
    }
  };

  // Roo make flexGrow work. I can't figure it out
  return (
    <Box sx={{ flex: 1, display: 'flex', overflow: 'auto' }}>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: 2,
        }}
      >
        {fineTuningEngineParametersHistory.engineParametersHistory
          && (fineTuningEngineParametersHistory.engineParametersHistory.map(
            (fineTuningEngineParameter, index) => (
              <Item
                highlightFineTuningRow={highlightFineTuningRow}
                onMouseOver={
                  () => highlightFineTuningRow(fineTuningEngineParameter.fineTuningObjectId)
                }
                onClick={
                  () => {
                    if (
                      fineTuningRowObjectIdToExpand === fineTuningEngineParameter.fineTuningObjectId
                    ) {
                      setFineTuningRowObjectIdToExpand('');
                    } else {
                      setFineTuningRowObjectIdToExpand(fineTuningEngineParameter.fineTuningObjectId);
                    }
                  }
                }
                sx={{ border: fineTuningEngineParameter.fineTuningObjectId === fineTuningRowObjectIdToHighlight ? '4px solid black' : '2px solid grey', flexGrow: fineTuningEngineParameter.fineTuningObjectId === fineTuningRowObjectIdToHighlight ? 3 : 1 }}
              >
                {`${moment(get(fineTuningEngineParameter, 'setAt', 'NA'), 'YYYY-MM-DDTkk:mm:SSSSSZ').format('MM-DD-YYYY')}${fineTuningEngineParameter.fineTuningObjectId === '' ? ' (Default)' : ''}`}
                {index === fineTuningEngineParametersHistory.engineParametersHistory.length - 1 && (
                  <IconButton onClick={() => resetEngineSettings()}><Iconify sx={{ fontSize: '18px', color: 'danger' }} icon="carbon:reset" /></IconButton>
                )}
              </Item>
            ),
          ))}
      </Paper>
    </Box>
  );
}
