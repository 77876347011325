/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { format } from 'date-fns';
import {
  Box, Container, Card, CardHeader, Stack, Table, TableBody, TableCell,
  TableHead, TableRow, Typography, TableContainer, ListItemText, Divider,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, isArray } from 'lodash';
import { useTheme, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Unstable_Grid2';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import { protectedFetch } from '../../utils/network';
import formatBytes from '../../utils/math';
import Iconify from '../Iconify';

export default function ApproveRejectPhrases() {
  const [phrases, setPhrases] = useState([]);

  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);

  useEffect(() => {
    const url = (
      '/api/pending_phrases'
    );
    const phrasesFetch = protectedFetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    phrasesFetch.then((phrasesInfo) => {
      if (phrasesInfo !== null) {
        if (!phrasesInfo.success) {
          handleSetGenericAlertDialogFromGenericResponse(phrasesInfo);
          return;
        }
        const retrievedPhrases = get(phrasesInfo, 'phrases', []);
        if (retrievedPhrases.length > 0) {
          setPhrases(retrievedPhrases);
        }
      }
    });
  }, []);

  const handleDenyPhrase = (params) => {
    const vppId = get(params, 'row.id', -1);
    try {
      const denyResponse = protectedFetch(`/api/deny_phrase/${vppId}`, {
        method: 'get',
        headers: {
          accept: 'application/json',
        },
      });
      denyResponse.then((response) => {
        if (response !== null) {
          if (response.success) {
            setPhrases(phrases.filter((phrase) => phrase.id.toString() !== vppId.toString()));
          }
          handleSetGenericAlertDialogFromGenericResponse(response);
        }
      });
    } catch (err) {
      console.log('handleDenyPhrase Exception: ', err);
    }
  };

  const handleApprovePhrase = (params) => {
    const vppId = get(params, 'row.id', -1);
    try {
      const approveFetch = protectedFetch(`/api/approve_phrase/${vppId}`, {
        method: 'get',
        headers: {
          accept: 'application/json',
        },
      });
      approveFetch.then((response) => {
        if (response.success) {
          setPhrases(phrases.filter((phrase) => phrase.id.toString() !== vppId.toString()));
        }
        handleSetGenericAlertDialogFromGenericResponse(response);
      });
    } catch (err) {
      console.log('handleApprovePhrase Exception: ', err);
    }
  };

  const approveRejectColumns = [
    {
      field: 'vppText',
      headerName: 'VPP Text',
      flex: 5,
      minWidth: 100,
      editable: true,
    },
    {
      field: 'languageCode',
      headerName: 'Language Code',
      flex: 2,
      minWidth: 200,
      editable: true,
    },
    {
      field: 'actions',
      headerName: 'Approve/Reject',
      type: 'actions',
      flex: 2,
      minWidth: 100,
      // eslint-disable-next-line no-unused-vars
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Iconify sx={{ fontSize: '20px', color: '#75B09C' }} icon="carbon:thumbs-up-filled" />}
          onClick={() => {
            handleApprovePhrase(params);
          }}
          label="Approve"
        />,
        <GridActionsCellItem
          icon={<Iconify sx={{ fontSize: '20px', color: '#bc4749' }} icon="carbon:thumbs-down-filled" />}
          onClick={() => {
            handleDenyPhrase(params);
          }}
          label="Deny"
        />,
      ],
    },
  ];

  return (
    <Container maxWidth="xl">
      <Grid
        data-aos="fade-right"
        container
        spacing={3}
      >
        <Grid md={12} sx={{}}>
          <Card>
            <CardHeader title="Phrases to approve or deny" subheader="The requested phrases below will be deleted if denied or added to the account if approved" sx={{ mb: 3 }} />

            <DataGrid
              rows={phrases}
              columns={approveRejectColumns}
              getRowId={(row) => `${row.id}${row.vppText}${row.languageCode}`}
            />

          </Card>
        </Grid>
      </Grid>
    </Container>

  );
}
