/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import {
  Container, Card, CardHeader, Stack,
  Divider, Button,
  TextField,
  Box,
  ButtonBase,
  Paper,
  Typography,
  Autocomplete,
  CircularProgress,
  Fade,
  ListItemText,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { format } from 'date-fns';
import moment from 'moment';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';

// import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { get, isArray, size } from 'lodash';

import Grid from '@mui/material/Unstable_Grid2';
import FineTuningContext from '../../contexts/FineTuningContext';
import GenericAlertDialogContext from '../../contexts/GenericAlertDialogContext';
import { protectedFetch } from '../../utils/network';
import Iconify from '../Iconify';
import { responsiveFontSizes } from '../../utils/getFontValue';
import Label from '../Label/Label';
import { BLUR_KEYS_AND_TOKENS } from '../../utils/cssStyles';

export default function FineTuningDetails(detailsProps) {
  const {
    includeStartDate,
    activateButtonText,
    activateButtonFunction,
    detailsSubHeader,
  } = detailsProps;
  const { handleSetGenericAlertDialogFromGenericResponse } = useContext(GenericAlertDialogContext);
  const {
    accounts,
    accountsAlreadyRetrieved,
    selectedAccount,
    handleSetSelectedAccount,
    selectedPhrase,
    startDate,
    handleSetSelectedPhrase,
    handleSetAccounts,
    handleSetAccountsAlreadyRetrieved,
    handleSetStartDate,
  } = useContext(FineTuningContext);

  // const [accounts, setAccounts] = useState([]);
  // const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedAccountInputValue, setSelectedAccountInputValue] = useState('');
  const [selectedPhraseInputValue, setSelectedPhraseInputValue] = useState('');
  const phraseDropDownLabelText = selectedAccount ? '' : 'Please select API Key first to be able to select phrase';
  const [phrasesAndLanguages, setPhrasesAndLanguages] = useState([]);
  // const [startDate, setStartDate] = useState(moment().subtract(1, 'month'));
  // const [selectedPhrase, setSelectedPhrase] = useState('');

  useEffect(() => {
    if (!accountsAlreadyRetrieved) {
      const url = (
        '/api/graphable_developers'
      );
      const accountsFetch = protectedFetch(url, {
        method: 'get',
        headers: {
          accept: 'application/json',
        },
      });
      accountsFetch.then((accountsInfo) => {
        if (isArray(get(accountsInfo, 'developers', false))) {
          handleSetAccountsAlreadyRetrieved(true);
          handleSetAccounts(get(accountsInfo, 'developers', []));
        }
      });
    }
  }, []);

  const handleAPIKeySelection = async (apiKey) => {
    try {
      const phrasesFetch = await protectedFetch(`/api/get_phrases_for_fine_tuning/${apiKey}`, {
        method: 'get',
        headers: {
          accept: 'application/json',
        },
      });
      if (!phrasesFetch.success) {
        handleSetGenericAlertDialogFromGenericResponse(phrasesFetch);
      } else {
        setPhrasesAndLanguages(phrasesFetch.availableContentLanguageAndPhrases);
      }
      // handleSubmitPhrase({ header: 'Phrase Request Info', text: result });
    } catch (err) {
      console.log('handleAPIKeySelection catch Exception: ', err);
      // handleSubmitPhrase({ header: 'Phrase Request Info', text: err.message });
    }
  };

  return (
    <Grid md={12} lg={8}>
      <Card
        data-aos="fade-right"
      >
        <CardHeader
          title="Fine Tuning Details"
          subheader={detailsSubHeader}
        />
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack direction="column" sx={{ mx: 3, mt: 2 }} spacing={2}>
          <Autocomplete
            fullWidth
            value={selectedAccount}
            onChange={(event, newValue) => {
              handleSetSelectedAccount(newValue);
              handleAPIKeySelection(get(newValue, 'value', ''));
            }}
            inputValue={selectedAccountInputValue}
            onInputChange={(event, newInputValue) => {
              setSelectedAccountInputValue(newInputValue);
            }}
            options={accounts}
            autoHighlight
            sx={
              { filter: BLUR_KEYS_AND_TOKENS ? 'blur(3px)' : 'none' }
            }
            getOptionLabel={(option) => (option ? `${option.key} ${option.email}` : '')}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{
                  color: '#000000',
                  filter: BLUR_KEYS_AND_TOKENS ? 'blur(3px)' : 'none',

                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              >
                {option.key}
                <span style={{ marginLeft: '16px', color: '#505050' }}>
                  {` ${get(option, 'email', '')}`}
                </span>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="API Key"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
          <Autocomplete
            fullWidth
            disabled={!selectedAccount}
            value={selectedPhrase}
            onChange={(event, newValue) => {
              handleSetSelectedPhrase(newValue);
            }}
            inputValue={selectedPhraseInputValue}
            onInputChange={(event, newInputValue) => {
              setSelectedPhraseInputValue(newInputValue);
            }}
            options={phrasesAndLanguages}
            autoHighlight
            getOptionLabel={(option) => (option ? `${option.vppText} ${option.contentLanguage}` : phraseDropDownLabelText)}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ color: '#000000' }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              >
                {option.vppText}
                <span style={{ marginLeft: '16px', color: '#505050' }}>
                  {` ${get(option, 'contentLanguage', '')}`}
                </span>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="Phrase and Content Language"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
          {includeStartDate
            ? (
              <DesktopDateTimePicker
                sx={{ width: '100%' }}
                label="Start date"
                ampm={false}
                disableFuture
                value={startDate}
                onChange={(newValue) => handleSetStartDate(newValue)}
              />
            ) : ''}
        </Stack>

        <Stack
          spacing={2}
          sx={{
            p: 3,
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => activateButtonFunction()}
          >
            {`${activateButtonText}`}
          </Button>
        </Stack>
      </Card>
    </Grid>
  );
}
